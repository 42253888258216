import React, { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'

import { Theme } from '../styles/settings'

const PasswordInputContainer = styled.div`
  p {
    margin: 0;
  }
`

const styles = () => ({
  root: {
    display: 'block',
    marginBottom: 0,
    marginTop: 0,
    padding: '0.5rem 0 0.5rem 1rem',
    height: 'calc(1.8em + 1rem + 4px)',
    zIndex: 2,
    background: Theme.colors.lightBlue,
  },
  textField: {
    background: Theme.colors.lightBlue,
    width: '100%',
    borderRadius: '0px',
  },
  textFieldLight: {
    background: Theme.colors.white,
    width: '100%',
    borderRadius: '0px',
  },
})

const StyledTextField = withStyles({
  root: {
    marginBottom: '2rem',
    width: '100%',
    '& label.Mui-focused': {
      color: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:after': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:before': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.brandTertiary,
    },
    '& .MuiInputBase-input': {
      color: '#363B3D',
      padding: '0.5rem 0.5rem 0.5rem 1rem',
      fontSize: '18px',
      fontFamily: 'Campton',
      height: 'calc(1.8em + 1rem + 2px)',
      boxSizing: 'border-box',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: Theme.colors.brandPrimary,
    },
  },
})(TextField)

class PhoneNumber extends Component {
  state = {
    password: '',
    showPassword: false,
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }))
  }

  handleChange = (val) => {
    this.props.handleChange({
      target: {
        name: this.props.name,
        value: val.value,
      },
    })
  }

  render() {
    const { classes } = this.props

    return (
      <PasswordInputContainer>
        <NumberFormat
          showMask
          customInput={StyledTextField}
          onValueChange={(e) => {
            this.handleChange(e)
          }}
          className={this.props.light ? classes.textFieldLight : classes.textField}
          value={this.props.value}
          name={this.props.name}
          type={this.props.type}
          inputProps={{ 'aria-label': this.props.description }}
          disabled={this.props.disabled === 'true' ? true : null}
          format="+1 (###) ###-####"
          allowEmptyFormatting
          mask="  "
        />
        {this.props.formFieldError ? (
          <FormHelperText className="form-field__error" id="component-error-text">
            {this.props.formFieldError}
          </FormHelperText>
        ) : null}
      </PasswordInputContainer>
    )
  }
}

PhoneNumber.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PhoneNumber)
