import { decorate, observable, action, runInAction } from 'mobx'
import history from './history'
import dataService from './dataService'

class DataStore {
  user
  localUser
  token
  errors = []
  prescriptions = []
  success
  isLoading = false

  clearErrors = () => {
    this.errors = []
    this.success = null
  }

  setErrors = (data) => {
    this.errors = data.errors
  }

  signIn = async (data) => {
    this.errors = []
    dataService('post', 'login', { email: data.email, password: data.password })
      .then((res) => {
        localStorage.setItem('token', res.data.token)
        history.push('prescriptions')
        //this.success = ['Success']
        runInAction(() => {
          this.user = res.data.user
          this.token = res.data.token
        })
      })
      .catch((err) => {
        if (err && err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }

  createLocalUser = async (data) => {
    console.log('creating local user')
    console.log(data.user)
    this.localUser = data.user
  }

  createUser = async (data) => {
    this.isLoading = true
    const { subscriptionStore, subscriptionData } = data
    dataService('post', `users`, { user: data.user })
      .then((res) => {
        this.isLoading = false
        localStorage.setItem('token', res.data.token)
        this.success = ['User created successfully']
        runInAction(() => {
          this.user = res.data.user
          this.token = res.data.token
          subscriptionData['customerId'] = res.data.user.stripe_id
          subscriptionStore.createSubscription(subscriptionData)
        })
      })
      .catch((err) => {
        this.isLoading = false
        console.log('err', err)
        if (err && err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }

  updateUser = async (data) => {
    this.success = []
    dataService('put', `users/me`, { user: data.user })
      .then((res) => {
        this.success = ['User updated successfully']
        runInAction(() => {
          this.user = res.data
        })
      })
      .catch((err) => {
        console.log('err', err)
        if (err && err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }

  logOut = () => {
    this.token = null
    this.user = null
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }

  getCurrentUser = async (data) => {
    dataService('get', 'users/me')
      .then((res) => {
        runInAction(() => {
          this.user = res.data
        })
      })
  }

  getPrescriptions = () => {
    dataService('get', `users/${this.user.id}/prescriptions`)
      .then((res) => {
        runInAction(() => {
          this.prescriptions = res.data
        })
      })
      .catch((err) => {
        if (err && err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }

  createPrescription = (data) => {
    this.isLoading = true
    dataService('post', `users/${this.user.id}/prescriptions`, {
      prescription: {
        title: data.title,
        dosage: data.dosage,
        email_notification: data.emailNotification,
        phone_notification: data.phoneNotification,
        sms_notification: data.smsNotification,
        prescription_times_attributes: data.prescriptionTimes,
      },
    })
      .then((res) => {
        this.isLoading = false
        data.uiStateStore.setShowPrescriptionForm(false)
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        runInAction(() => {
          this.prescriptions = [...this.prescriptions, res.data]
        })
      })
      .catch((err) => {
        this.isLoading = false
        if (err && err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }

  updatePrescription = (data) => {
    dataService('patch', `users/${this.user.id}/prescriptions/${data.id}`, {
      prescription: {
        title: data.title,
        dosage: data.dosage,
        email_notification: data.emailNotification,
        phone_notification: data.phoneNotification,
        sms_notification: data.smsNotification,
        prescription_times_attributes: data.prescriptionTimes,
      },
    })
      .then((res) => {
        console.log('success', data.uiStateStore)
        data.uiStateStore.setShowModal(false)
        runInAction(() => {
          this.prescriptions = this.prescriptions.reduce((acc, curr) => {
            return res.data.id === curr.id ? [...acc, res.data] : [...acc, curr]
          }, [])
        })
      })
      .catch((err) => {
        console.log('err', err)
        if (err && err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }

  deletePrescription = (data) => {
    dataService('delete', `users/${this.user.id}/prescriptions/${data.prescription.id}`)
      .then((res) => {
        runInAction(() => {
          data.uiStateStore.setShowModal(false)
          this.prescriptions = this.prescriptions.reduce((acc, curr) => {
            return res.data.id === curr.id ? [...acc] : [...acc, curr]
          }, [])
        })
      })
      .catch((err) => {
        console.log('err', err)
        if (err && err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }

  requestPasswordReset = (data) => {
    const { user } = data
    dataService('post', `forgot_password`, { user })
      .then((res) => {
        runInAction(() => {
          this.success = [
            'You should recieve an email shortly with instructions to reset your password.',
          ]
        })
      })
      .catch((err) => {
        console.log('err', err)
        if (err && err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }

  resetPassword = (data) => {
    const { password, reset_token } = data
    dataService('post', `reset_password`, { password, reset_token })
      .then((res) => {
        runInAction(() => {
          this.success = [
            'Password updated succesfully. Please sign in with your new password to continue.',
          ]
        })
      })
      .catch((err) => {
        console.log('err', err)
        if (err && err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }
}

decorate(DataStore, {
  user: observable,
  localUser: observable,
  token: observable,
  errors: observable,
  prescriptions: observable,
  success: observable,
  isLoading: observable,
  signIn: action.bound,
  createUser: action.bound,
  updateUser: action.bound,
  getPrescriptions: action.bound,
  updatePrescription: action.bound,
  deletePrescription: action.bound,
  requestResetPassword: action.bound,
})

export default new DataStore()
