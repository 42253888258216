const Xs = '500px'
const Sm = '740px'
const Md = '960px'
const Lg = '1080px'
const Xl = '1200px'
const Xxl = '1540px'

export const Theme = {
  brandFont: 'Campton',
  boldFont: 'Campton Semibold',
  breakpoints: [Sm, Md, Lg, Xl, Xxl],
  colors: {
    brandPrimary: '#3488F8',
    brandSecondary: '#084C87',
    brandTertiary: '#B5C9DB',
    lightBlue: '#F3F6F9',
    lightGrey: '#F2F2F2',
    middleGrey: '#98A6B8',
    grey: '#3E3E3E',
    darkGrey: '#212121',
    blueGrey: '#3F474B',
    black: '#363B3D',
    white: '#FFFFFF',
    error: '#FF4040',
    warning: '#FFC700',
    success: '#4CD99E',
  },
  fontweight: {
    light: 'normal',
    bold: 'bold',
    black: '900',
  },
  fontSize: {
    normal: '1rem',
    large: '20px',
  },
}

const breakpoints = [{ xs: Xs }, { sm: Sm }, { md: Md }, { lg: Lg }, { xl: Xl }, { xxl: Xxl }]

export const Mq = breakpoints.reduce((acc, breakpoint) => {
  const entry = Object.entries(breakpoint)[0]
  acc = { ...acc, [entry[0]]: `@media (min-width: ${entry[1]})` }
  return acc
}, {})
