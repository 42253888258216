import 'intersection-observer';
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.jsx'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'mobx-react'
import dataStore from './stores/dataStore'
import subscriptionStore from './stores/subscriptionStore'
import uiStateStore from './stores/uiStateStore'

const stores = {
  dataStore,
  subscriptionStore,
  uiStateStore
};


ReactDOM.render(
  <React.StrictMode>
    <Provider {...stores}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
