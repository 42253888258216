import React from 'react'
import MetaTags from 'react-meta-tags'

import metaImage from '../assets/images/my-rx-call__meta-share.png'

import { SmallHero } from '../organisms/smallHero'
import { CtaSectionSmall } from '../molecules/ctaSection'
import SupportForm from '../molecules/supportForm'

const SupportRequest = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Support</title>
        <meta
          name="description"
          content="Introducing MyRxCall - the most reliable way to manage medications. Receieve personalized medication reminders via phone call, SMS, or e-mail."
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription reminders for you - Safe, easy, & low cost."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallHero
        title="Submit a request"
        supportHero="true"
        subTitle={
          <span>
            "I need to update my prescription dosage."
            <br />
            "Can I remove a prescription from my reminders?"
            <br />
            "How much wood does a wood chuck chuck?”
          </span>
        }
      />
      <SupportForm />
      <CtaSectionSmall headline="The most reliable way to manage medications" cta="About pricing" />
    </main>
  )
}

export default SupportRequest
