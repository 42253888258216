import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box, Link as ExternalLink } from '@rebass/emotion'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { FormGroup } from '@material-ui/core'
import { Dropdown } from '../atoms/dropdown'
import { CheckCircle } from 'react-feather'

import { Theme, Mq } from '../styles/settings'
import PhoneNumber from '../atoms/phoneNumber'
import { TextInput } from '../atoms/input'
import { Animation } from '../atoms/animation'
import { H5, H6, Paragraph } from '../atoms/text'
import { SubmitButton } from '../atoms/button'
import { timeZoneOptions } from '../constants'

const AccountDetailsFormContainer = styled.div`
  margin-bottom: 0rem;
  width: 100%;

  ${Mq.md} {
    margin-bottom: 4rem;
  }
`

const CustomHeader = styled.div`
  margin-bottom: 0rem;
  ${Mq.md} {
    margin-bottom: 0.75rem;
  }

  H5 {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 1rem;
    ${Mq.md} {
      padding: 0;
    }
  }

  p {
    padding: 0 1rem 1rem;
    ${Mq.md} {
      padding: 0;
    }
  }
`

const FormContainer = styled.div`
  align-items: center;
  background: #fff;
  padding: 40px 1rem;

  H6 {
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    font-weight: 600;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  p a {
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
  ${Mq.md} {
    padding: 40px 32px;
  }
`

const SubmitBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 1.5rem;
    margin-top: 8px;
  }
  ${Mq.md} {
    margin-top: 4rem;
  }
`

const SuccessAlert = styled.div``

const StyledQuestionBox = styled.div`
  max-width: 240px;
  justify-content: center;
  text-align: center;
  margin: 0 auto 24px;

  ${Mq.md} {
    max-width: 100%;
    justify-content: inherit;
    text-align: inherit;
    margin: 4rem auto 0 0;
  }
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

class AccountDetailsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: 'one', edited: false, success: false, formError: false }

    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  handleFormChange(e) {
    this.setState({ edited: true })
    const { name, value } = e.target
    this.props.setUser({ ...this.props.user, [name]: value })
  }

  handleChange(e) {
    this.setState({ edited: false })
  }

  handleReset(e) {
    this.setState({ edited: false })
  }

  render() {
    const { handleFormChange } = this
    const { user, handleSubmit } = this.props

    return (
      <AccountDetailsFormContainer>
        <CustomHeader>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
          >
            <H5>Account details</H5>
            <Paragraph>Update your personal login & user information below.</Paragraph>
          </Animation>
        </CustomHeader>
        <form onSubmit={this.handleReset}>
          <FormContainer>
            <FormGroup>
              <Animation
                initial="fadeIn"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
              >
                <Flex width="auto" ml={-2} mr={-2} flexDirection={['column', 'column', 'row']}>
                  <Box width={1} pl={2} pr={2}>
                    <H5 className="dark">Your first name:</H5>
                    <TextInput
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                      value={user.first_name}
                      dark={true}
                      name="first_name"
                    />
                  </Box>
                  <Box width={1} pl={2} pr={2}>
                    <H5 className="dark">Your last name:</H5>
                    <TextInput
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                      dark={true}
                      value={user.last_name}
                      name="last_name"
                    />
                  </Box>
                </Flex>
              </Animation>
              <Animation
                initial="fadeIn"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
              >
                <Flex width="auto" ml={-2} mr={-2} flexDirection={['column', 'column', 'row']}>
                  <Box width={1} pl={2} pr={2}>
                    <Flex justifyContent="space-between">
                      <H5 className="dark">Your email address:</H5>
                      {/* RESET EMAIL
                      <a href="mailto:hello@myrxcall.com?subject=Resetting my email address&body=Could somebody please assist me in resetting my email address?">
                        <H6>Reset email</H6>
                      </a> */}
                    </Flex>
                    <TextInput dark={true} disabled="true" email={true} value={user.email} />
                  </Box>
                  <Box width={1} pl={2} pr={2}>
                    <Flex justifyContent="space-between">
                      <H5 className="dark">Your password:</H5>
                      <Link to="/forgot-password">
                        <H6>Reset password</H6>
                      </Link>
                    </Flex>
                    <TextInput
                      placeholder="To edit click reset above"
                      dark={true}
                      name="password"
                      disabled="true"
                      password={true}
                      value="*************"
                    />
                  </Box>
                </Flex>
              </Animation>
              <Animation
                initial="fadeIn"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
              >
                <Flex
                  width="auto"
                  ml={-2}
                  mr={-2}
                  mb={3}
                  flexDirection={['column', 'column', 'row']}
                >
                  <Box width={1} pl={2} pr={2} mb={[4, 4, 0, 0]}>
                    <Flex justifyContent="space-between">
                      <H5>Your timezone:</H5>
                    </Flex>
                    <Dropdown
                      disabled
                      dark={true}
                      options={timeZoneOptions}
                      placeholder="Select timezone"
                      description="Subscriber time zone"
                      value={timeZoneOptions.find((tz) => tz.value === user.timezone)}
                      onChange={(e) => {
                        //this.handleFormChange({ target: { name: 'timezone', value: e.value } })
                      }}
                    />
                  </Box>
                  <Box width={1} pl={2} pr={2}>
                    <Flex justifyContent="space-between">
                      <H5 className="dark">Your phone number:</H5>
                      <ExternalLink href="mailto:support@myrxcall.com?subject=I need to reset my phone number.&body=Could somebody please assist me in updating my phone number?">
                        <H6>Reset phone</H6>
                      </ExternalLink>
                    </Flex>
                    {/* WIP  --- ICEBOX –– Make this actually update the number and reminders.*/}
                    <PhoneNumber
                      handleChange={(e) => {
                        this.handleChange(e)
                      }}
                      disabled="true"
                      name="phone_number"
                      value={user.phone_number && user.phone_number.substr(2)}
                    />
                  </Box>
                </Flex>
              </Animation>
              <Animation
                initial="fadeIn"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
              >
                <Flex
                  mt={['24px', '24px', '-64px']}
                  width="100%"
                  alignItems="center"
                  justifyContent="flex-end"
                  flexDirection={['column', 'column', 'row']}
                  position="relative"
                >
                  <StyledQuestionBox>
                    <Paragraph noMargin={true}>
                      Questions?{' '}
                      <a href="mailto:hello@myrxcall.com?subject=Hello, I have a question.">
                        Contact us{' '}
                      </a>
                      or <Link to="/support-request">submit a ticket</Link>.
                    </Paragraph>
                  </StyledQuestionBox>
                  <SubmitBox
                    display={['flex', 'flex', 'inherit']}
                    justifyContent={['center', 'center', 'flex-end']}
                    alignItems="center"
                  >
                    <SuccessAlert className={this.state.edited}>
                      {this.props.dataStore.success ? (
                        <Animation
                          initial="fadeIn"
                          animate="visible"
                          exit={{ opacity: 0 }}
                          variants={motionVariants}
                          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
                        >
                          <CheckCircle color={Theme.colors.success} size={32} />
                        </Animation>
                      ) : null}
                    </SuccessAlert>
                    <SubmitButton
                      title="Update"
                      type="submit"
                      onClick={(e) => {
                        handleSubmit(e)
                      }}
                      variant={this.state.edited === true ? null : 'disabled'}
                      disabled={!this.state.edited}
                    />
                  </SubmitBox>
                </Flex>
              </Animation>
            </FormGroup>
          </FormContainer>
        </form>
      </AccountDetailsFormContainer>
    )
  }
}

export default inject('dataStore')(observer(AccountDetailsForm))
