import React from 'react'
import styled from '@emotion/styled'
import { Edit2, X, CreditCard } from 'react-feather'
import ReactModal from 'react-modal'

import { Theme, Mq } from '../styles/settings'
import { UpdateMembershipForm } from '../molecules/updateMembershipForm'
import { PaymentHistory } from '../molecules/paymentHistory'
import { Paragraph, H5 } from '../atoms/text'

const MembershipRowContainer = styled.div`
  padding: 16px 24px;
  width: calc(100% - 1rem);
  background: ${(props) => props.theme.colors.lightBlue};
  border-radius: 40px;
  display: flex;
  margin: 8px auto;
  align-items: center;
  position: relative;
  ${Mq.md} {
    width: calc(100% - 4rem);
  }
`

const CustomHeader = styled.div`
  width: 100%;
  display: block;
  H5 {
    color: ${(props) => props.theme.colors.black};
    margin: 4px 0 0;
  }
  p {
    margin: 0;
    padding-bottom: 0px;
    padding-right: 40px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 24px;
    }
  }
  p.no-padding {
    padding-right: 0px;
  }

  ${Mq.md} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const EditIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 36px;
  ${Mq.md} {
    top: 20px;
  }
  H5 {
    color: ${(props) => props.theme.colors.brandPrimary};
    margin: 0;
    padding: 0;

    &.link {
      cursor: pointer;
      transition: 0.2s ease-in-out;
      color: ${(props) => props.theme.colors.brandSecondary};
      margin-top: -2px;

      :hover {
        color: ${(props) => props.theme.colors.brandPrimary};
      }
    }
  }

  svg {
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      stroke: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`

const CloseModal = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    stroke: ${(props) => props.theme.colors.brandPrimary};
  }
`

// const mobileModal = { width: '970px' }

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Theme.colors.lightBlue,
    padding: '80px 1rem',
    border: '0px',
    borderRadius: '0px',
    overflowY: 'auto',
    width: '90%',
    maxWidth: '970px',
  },
  overlay: {
    backgroundColor: 'RGBA(54,59,61,0.8)',
    backdropFilter: 'blur(4px)',
    width: '100vw',
    zIndex: '9999',
  },
}

class MembershipRow extends React.Component {
  constructor() {
    super()
    this.state = {
      showModalEdit: false,
      showModalHistory: false,
    }

    this.handleOpenModalEdit = this.handleOpenModalEdit.bind(this)
    this.handleOpenModalHistory = this.handleOpenModalHistory.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleOpenModalEdit() {
    this.setState({ showModalEdit: true })
  }

  handleOpenModalHistory() {
    this.setState({ showModalHistory: true })
  }

  handleCloseModal() {
    this.setState({ showModalEdit: false, showModalHistory: false })
  }
  render(props) {
    return (
      <>
        <MembershipRowContainer>
          <CustomHeader>
            <H5>{this.props.title}</H5>

            {this.props.paddingRight ? (
              <Paragraph className="no-padding">{this.props.detail}</Paragraph>
            ) : (
                <Paragraph>
                  {this.props.paymentIcon ? <CreditCard /> : null}
                  {this.props.detail}
                </Paragraph>
              )}
          </CustomHeader>
          {this.props.noEdit ? null : (
            <EditIcon>
              {this.props.view ? (
                <H5 className="link" onClick={this.handleOpenModalHistory}>
                  View
                </H5>
              ) : (
                  <Edit2
                    color={Theme.colors.brandSecondary}
                    size={20}
                    onClick={this.handleOpenModalEdit}
                  />
                )}
            </EditIcon>
          )}
        </MembershipRowContainer>
        <ReactModal
          style={modalStyles}
          isOpen={this.state.showModalEdit || this.state.showModalHistory}
          contentLabel="Edit Prescription Modal"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModal>
            <X onClick={this.handleCloseModal} />
          </CloseModal>
          {this.state.showModalEdit ? (
            <UpdateMembershipForm title="Update Membership" updatePrescription="true" />
          ) : (
              <PaymentHistory title="Payment History" />
            )}
        </ReactModal>
      </>
    )
  }
}

export default MembershipRow
