import React, { useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import './CardSectionStyles.css'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { inject, observer } from 'mobx-react'
import { Hidden } from '@material-ui/core'
import moment from 'moment'
import Loader from 'react-loader-spinner'

import { Theme, Mq } from '../styles/settings'
import { Animation } from '../atoms/animation'
import { H3, H4, H5, H6, Paragraph } from '../atoms/text'
import { AccentButton } from '../atoms/button'
import { CustomCheckbox } from '../atoms/customCheckbox'
import { Lock } from 'react-feather'

const CreateBillingFormContainer = styled.div`
  width: 100%;

  &.StripeElement--webkit-autofill {
    background-color: #f3f6f9;
  }
`

const CustomHeader = styled(Flex)`
  justify-content: spcae-between;
  width: 100%;

  H3.price {
    text-align: right;
  }
`

const FormContainer = styled.div`
  align-items: center;
  background: #fff;
  padding: 40px 1rem;

  &.reactivate {
    padding: 0px;

    & H6.small {
      display: none;
    }
  }

  ${Mq.md} {
    padding: 40px 32px;
  }
`

const ReviewContainer = styled.div`
  width: 100%;
  margin: 3rem 0 0;
  p {
    font-size: 14px;
  }

  ${Mq.md} {
    p {
      font-size: 1rem;
    }
  }
`

const ReviewHeader = styled.div`
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.brandPrimary};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  H3 {
    font-size: 1.5rem;
  }
  H4 {
    font-size: 1rem;
  }

  ${Mq.md} {
    H3 {
      font-size: 2rem;
    }
    H4 {
      font-size: 1rem;
    }
  }
`

const BillingHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  H4 {
    margin-bottom: 0px;
  }
  H6.small {
    padding-left: 0.5rem;
    font-family: ${(props) => props.theme.boldFont};
    color: ${(props) => props.theme.colors.success};
    margin-bottom: 4px;
    display: flex;
    width: max-content;
    align-items: center;
  }

  svg {
    margin-top: -6px;
    margin-right: 0.5rem;
  }
`

const ReviewContent = styled.div`
  display: block;
  width: 100%;
  margin-top: 24px;

  p {
    margin: -4px 0 8px 0;
    max-width: 156px;
    overflow: hidden;
    text-overflow: ellipsis;
    ${Mq.md} {
      margin: 0 0 8px auto;
      max-width: max-content;
    }
  }

  H5 {
    margin: 0 0 8px 0;
    font-size: 18px;
  }

  ${Mq.md} {
    display: flex;
  }
`

const MembershipConfirmation = styled.div`
  display: block;
  width: 100%;
  padding-left: 0px;
  position: relative;

  ${Mq.md} {
    padding-left: 0px;
    width: 60%;
    align-items: space-between;

    &.reactivate {
      width: 70%;
      margin: 0 auto 0 -0.5rem;

      & H6 {
        text-align: left;
        padding-left: 1rem;
        padding-top: 0.5rem;
      }

      & button {
        float: left;
      }
    }
  }
`

const AlertContainer = styled.div`
  display: block;
  text-align: right;
  margin-top: 24px;

  p {
    color: ${(props) => props.theme.colors.error};
    margin: 0;
  }
`

const LoadingContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  p {
    color: ${(props) => props.theme.colors.error};
    margin: 0;
  }
`

const ValuePropRow = styled.div`
  background: ${(props) => props.theme.colors.lightBlue};
  border-radius: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 3rem;

  p {
    margin: 0;
    padding: 0 2rem;
  }

  H4 {
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 0px;
    padding: 0 2rem;
    line-height: 30px;
    font-size: 30px;
  }
`

const StyledBox = styled(Box)`
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;

  ${Mq.md} {
    width: 100%;
    display: block;
    justify-content: flex-end;

    &.due-today {
      width: 40%;
    }
  }
`

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: Theme.colors.black,
      fontFamily: '"Campton", "Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: Theme.colors.black,
      },
    },
    invalid: {
      color: Theme.colors.error,
      iconColor: Theme.colors.error,
    },
  },
}

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const CreateBillingForm = (props) => {
  const { dataStore, subscriptionStore } = props
  const stripe = useStripe()
  const elements = useElements()

  const [userAgrees, setUserAgrees] = useState(false)

  const createSubscription = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    const cardElement = elements.getElement(CardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      console.log('[createPaymentMethod error]', error)
    } else {
      //console.log('[PaymentMethod]', paymentMethod)
      const paymentMethodId = paymentMethod.id
      // Create the subscription – Hard-coded, remove if adding membership options
      const priceId = 'price_1HNBE3ALKlU2o7TqUj8wMTLb'

      const subscriptionData = {
        paymentMethodId,
        priceId,
        newAccount: props.newAccount,
        dataStore: props.dataStore,
      }
      if (props.newAccount) {
        props.dataStore.createUser({
          user: props.dataStore.localUser,
          subscriptionStore: props.subscriptionStore,
          subscriptionData,
        })
      } else {
        subscriptionData['customerId'] = props.dataStore.user && props.dataStore.user.stripe_id
        props.subscriptionStore.createSubscription(subscriptionData)
      }
    }
  }

  console.log(props.subscription)

  // Hard-coded, remove if adding membership options
  const FullPrice = 995 / 100
  const BillingDate = moment(Date.now() + 7 * 24 * 3600 * 1000).format('MM-DD-YYYY')

  return (
    <CreateBillingFormContainer>
      {props.reactivateBilling ? null : (
        <CustomHeader>
          <Box width={1}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
            >
              <H3>Billing</H3>
            </Animation>
          </Box>

          <Box width={1}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
            >
              <H3 className="price">9.95 / Month</H3>
            </Animation>
          </Box>
        </CustomHeader>
      )}
      <FormContainer className={props.reactivateBilling ? 'reactivate' : null}>
        <Hidden xsDown>
          {props.reactivateBilling ? null : (
            <Flex width="auto" alignItems="center" justify-content="space-between">
              <StyledBox className="value-props">
                <Animation
                  initial="fadeIn"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.5 }}
                >
                  <ValuePropRow>
                    <Paragraph>Safe & secure</Paragraph>
                    <H4>|</H4>
                    <Paragraph>Easy to use</Paragraph>
                    <H4>|</H4>
                    <Paragraph>Low cost</Paragraph>
                  </ValuePropRow>
                </Animation>
              </StyledBox>
            </Flex>
          )}
        </Hidden>
        <Animation
          initial="fadeIn"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
        >
          <BillingHeader>
            <H4>Add your billing information</H4>
            <Hidden smDown>
              <H6 className="small">
                <Lock size={16} /> SSL secure checkout
              </H6>
            </Hidden>
          </BillingHeader>
        </Animation>
        <Animation
          initial="fadeIn"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
        >
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </Animation>

        <Animation
          initial="fadeIn"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
        >
          <ReviewContainer>
            <ReviewHeader>
              <H3>Review</H3>
              {FullPrice ? (
                <H4>
                  <Hidden smDown>First week free!</Hidden> Due in 7 days: ${FullPrice.toFixed(2)}
                </H4>
              ) : null}
            </ReviewHeader>
            <ReviewContent>
              {props.reactivateBilling ? null : (
                <Flex width={['s', 's', '60%']} pr={[0, 0, 4]} justifyContent="space-between">
                  <Box width={1}>
                    <H5>Username:</H5>
                    <H5>Membership:</H5>
                    <H5>Billing total:</H5>
                    <H5>Billing date:</H5>
                  </Box>
                  <Box width={1} textAlign="right">
                    <Paragraph>{dataStore.localUser.email}</Paragraph>
                    {/* // Hard-coded, remove if adding membership options */}
                    <Paragraph>Billed Monthly</Paragraph>
                    <Paragraph>${FullPrice.toFixed(2)}</Paragraph>
                    <Paragraph>{BillingDate}</Paragraph>
                  </Box>
                </Flex>
              )}

              <MembershipConfirmation className={props.reactivateBilling ? 'reactivate' : null}>
                <Flex width="100%" alignItems="flex-start" mb={4}>
                  <Box>
                    <CustomCheckbox
                      checked={userAgrees}
                      onChange={(e) => {
                        setUserAgrees(!userAgrees)
                      }}
                      name="userAgrees"
                      variant="dark"
                      required={true}
                      inputProps={{ 'aria-label': 'Agree to terms of service' }}
                    />
                  </Box>
                  <Box>
                    <H6>
                      Please check the box to confirm the membership listed to the left, and that
                      you agree to our{' '}
                      <Link to="/terms-of-service" target="_blank">
                        Terms of service.
                      </Link>
                    </H6>
                  </Box>
                </Flex>
                {props.reactivateBilling ? (
                  <AccentButton
                    onClick={(e) => {
                      createSubscription(e)
                    }}
                    title="Reactivate membership"
                    disabled={!userAgrees}
                    type="submit"
                  />
                ) : (
                  <AccentButton
                    onClick={(e) => {
                      createSubscription(e)
                    }}
                    title="Begin membership"
                    disabled={!userAgrees}
                    type="submit"
                  />
                )}
                {subscriptionStore.isLoading || dataStore.isLoading ? (
                  <LoadingContainer>
                    <Loader
                      type="BallTriangle"
                      color={Theme.colors.brandPrimary}
                      height={50}
                      width={50}
                    ></Loader>
                  </LoadingContainer>
                ) : null}
              </MembershipConfirmation>
            </ReviewContent>
          </ReviewContainer>
        </Animation>
        {subscriptionStore.errors && subscriptionStore.errors.length ? (
          <AlertContainer>
            <Paragraph style={{ color: Theme.colors.error }}>
              The following error(s) have occurred:
              {subscriptionStore.errors &&
                subscriptionStore.errors.map((err, key) => <Paragraph>{err}</Paragraph>)}
              {dataStore.errors && dataStore.errors.map((err, key) => <Paragraph>{err}</Paragraph>)}
            </Paragraph>
          </AlertContainer>
        ) : null}
      </FormContainer>
    </CreateBillingFormContainer>
  )
}

export default inject('subscriptionStore', 'dataStore')(observer(CreateBillingForm))
