import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'
import { Flex, Box } from '@rebass/emotion'
import { PlusCircle, XOctagon } from 'react-feather'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { Theme, Mq } from '../styles/settings'
import { H2, H3, H4, H5, Paragraph } from '../atoms/text'
import { Button } from '../atoms/button'
import { Animation } from '../atoms/animation'
import AccountNavigation from '../molecules/accountNavigation'
import Prescription from '../atoms/prescription'
import PrescriptionForm from '../molecules/prescriptionForm'

export const PrescriptionDetailsPage = styled.div`
  position: relative;
  margin-top: 2rem;

  ${Mq.md} {
    margin-top: 0px;
  }
`

export const PrescriptionDetailsContainer = styled.div`
  padding: 3rem 1rem 3rem;
  margin-top: 2rem;
  background-color: ${(props) => props.theme.colors.lightBlue};

  ${Mq.md} {
    padding: 80px;
  }
`

const CustomHeader = styled.div`
  display: block;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;

  H5 {
    display: flex;
    align-items: center;
    transition: 0.2s ease-in-out;
    margin: 0 0 1rem 0;

    &.link {
      color: ${(props) => props.theme.colors.brandSecondary};
      cursor: pointer;

      :hover {
        color: ${(props) => props.theme.colors.brandPrimary};
      }
    }
    svg {
      margin: -6px 8px 0 0;
    }
  }

  ${Mq.md} {
    display: flex;
    margin: 0;

    H5 {
      margin: 0;
    }
  }
`

const PrescriptionHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  align-items: center;

  H2 {
    padding-right: 32px;
    min-width: max-content;
  }

  H4 {
    text-align: right;
    padding-left: 32px;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  svg {
    margin-left: 32px;
  }
`

const DividerLine = styled.div`
  height: 2px;
  margin-bottom: 7px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.brandTertiary};
`

export const AddPrescription = styled.div`
  padding: 40px 0;
`

const PrescriptionCta = styled.div`
  background: #fff;
  padding: 80px 0;
  display: flex;
  width: 100%;
  text-align: center;
  margin: auto;
  flex-direction: column;
  margin-top: 2rem;

  p {
    max-width: 380px;
    font-size: 20px;
    margin: auto;
    padding: 0 0 40px;
  }

  a {
    max-width: max-content;
    margin: auto;
  }

  Button {
    margin-bottom: 32px;
    width: max-content;
    margin: auto;
  }

  ${Mq.md} {
    margin-top: 4rem;

    Button {
      margin: 1rem auto 0;
    }
  }
`

const ZeroState = styled.div`
  background: #fff;
  padding: 80px 32px;
  text-align: center;

  H3.alert {
    color: ${(props) => props.theme.colors.error};
  }

  p {
    margin: 1rem auto 3rem;
    font-size: ${(props) => props.theme.fontSize.large};
    max-width: 600px;
  }

  svg {
    margin-bottom: 1rem;
  }

  H5 {
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 0px;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

function PrescriptionDetails(props) {
  const { dataStore, uiStateStore } = props
  const { showPrescriptionForm, setShowPrescriptionForm } = uiStateStore

  useEffect(() => {
    dataStore.getPrescriptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [false])

  return (
    <PrescriptionDetailsPage>
      <AccountNavigation />
      <PrescriptionDetailsContainer>
        <Animation
          initial="hidden"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
        >
          <CustomHeader>
            <H5 className="dark">Your prescriptions</H5>

            <AnchorLink href="#newprescription">
              <H5
                className="link"
                onClick={() => {
                  setShowPrescriptionForm(true)
                }}
              >
                <PlusCircle />
                Add a prescription
              </H5>
            </AnchorLink>
          </CustomHeader>
        </Animation>
        {dataStore.prescriptions.length ? (
          <>
            <Flex ml={-2} mr={-2} flexWrap="wrap">
              {dataStore.prescriptions.map((prescription, index) => {
                return (
                  <Box width={[1, 1 / 2, 1 / 2]} pl={2} pr={2}>
                    <Prescription prescription={prescription} />
                  </Box>
                )
              })}
            </Flex>
            <section id="newprescription"></section>
          </>
        ) : showPrescriptionForm ? null : (
          <>
            <section id="newprescription"></section>
            <ZeroState>
              <Animation
                initial="fadeIn"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
              >
                <XOctagon size={30} color={Theme.colors.error} />
                <H3 className="alert">No prescriptions activated</H3>
                <Paragraph>
                  It looks like you don't currently have any prescriptions in your account. Add a
                  prescription to begin receiving your reminders.
                </Paragraph>
                <AnchorLink href="#newprescription">
                  <Button
                    title="Add a prescription"
                    onClick={() => {
                      setShowPrescriptionForm(true)
                    }}
                  />
                </AnchorLink>
              </Animation>
            </ZeroState>
          </>
        )}
        {showPrescriptionForm === true ? (
          <>
            <AddPrescription>
              <PrescriptionHeader>
                <H2>New prescription</H2>

                <DividerLine />
                <H4
                  onClick={() => {
                    setShowPrescriptionForm(false)
                  }}
                >
                  Cancel
                </H4>
              </PrescriptionHeader>

              <PrescriptionForm newPrescription={true} />
            </AddPrescription>
          </>
        ) : null}
        <PrescriptionCta>
          <Animation
            initial="fadeIn"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
          >
            <Paragraph>
              Looking for some help? Check out our help portal to create a support ticket.
            </Paragraph>
            <Link to="/help">
              <Button title="Visit help portal" />
            </Link>
          </Animation>
        </PrescriptionCta>
      </PrescriptionDetailsContainer>
    </PrescriptionDetailsPage>
  )
}

export default inject('dataStore', 'uiStateStore')(observer(PrescriptionDetails))
