import React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

import { Theme } from './styles/settings/theme'
import { GlobalStyles } from './styles/global'
import { inject, observer } from 'mobx-react'
import Routes from './routes'

import history from './stores/history'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

ReactGA.initialize('UA-174988730-1')
ReactGA.pageview(window.location.pathname + window.location.search)

const tagManagerArgs = {
  gtmId: 'GTM-PX4LQ4N',
}

const App = (props) => {
  history.listen((location) => {
    window.scrollTo(0, 0)
    props.dataStore.clearErrors()
  })
  TagManager.dataLayer(tagManagerArgs)

  const token = localStorage.getItem('token')
  if (token) {
    props.dataStore.token = token
  }

  return (
    <ThemeProvider theme={Theme}>
      <Elements stripe={stripePromise}>
        <Global styles={GlobalStyles} />
        <Routes />
      </Elements>
    </ThemeProvider>
  )
}

export default inject('dataStore')(observer(App))
