import React from 'react'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'

import { Mq } from '../styles/settings'
import AccountNavigation from '../molecules/accountNavigation'
import AccountMembershipDetails from '../molecules/accountMembershipDetails'
import UpdateMembership from '../molecules/updateMembership'

export const MembershipDetailsPage = styled.div`
  position: relative;
  margin-top: 2rem;

  ${Mq.md} {
    margin-top: 0px;
  }
`

export const MembershipDetailsContainer = styled.div`
  padding: 3rem 1rem 3rem;
  margin-top: 2rem;
  background-color: ${(props) => props.theme.colors.lightBlue};

  ${Mq.md} {
    padding: 80px;
  }
`

const MembershipDetails = (props) => {
  const user = props.dataStore.user

  return (
    <MembershipDetailsPage>
      <AccountNavigation />
      <MembershipDetailsContainer>
        <AccountMembershipDetails />
        {user.subscription.status === 'active' ? <UpdateMembership /> : null}
      </MembershipDetailsContainer>
    </MembershipDetailsPage>
  )
}
export default inject('dataStore')(observer(MembershipDetails))
