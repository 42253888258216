import React from 'react'
import clsx from 'clsx'
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'

const theme = createMuiTheme({
  root: {},
  checkbox: {},
  typography: {
    fontFamily: 'Campton',
    fontSize: '18px',
  },
})

const useStyles = makeStyles({
  root: {
    padding: '0 9px 9px 8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    typography: {
      color: '#000',
    },
    '& .MuiFormControlLabel-root': {
      color: '#000',
    },
  },
  icon: {
    borderRadius: 0,
    width: 48,
    height: 48,
    borderBottom: '2px solid #B5C9DB',
    backgroundColor: '#fff',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      borderBottom: '2px solid #3488F8',
      background: '#fff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkedIcon: {
    backgroundColor: '#fff',
    '&:before': {
      display: 'block',
      width: 36,
      height: 36,
      position: 'absolute',
      top: '4px',
      left: '15px',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%233488F8'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
  },

  // ~~~~~~~~~~~~~~ DARK STYLES
  iconDark: {
    borderRadius: 0,
    width: 48,
    height: 48,
    borderBottom: '2px solid #B5C9DB',
    backgroundColor: '#F3F6F9',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      borderBottom: '2px solid #3488F8',
      background: '#F3F6F9',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkedIconDark: {
    backgroundColor: '#F3F6F9',
    '&:before': {
      display: 'block',
      width: 36,
      height: 36,
      position: 'absolute',
      top: '4px',
      left: '15px',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%233488F8'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#F3F6F9',
    },
  },
})

export function CustomCheckbox(props) {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <FormControl className={clsx(classes.checkbox)}>
        <Checkbox
          className={classes.root}
          disableRipple
          required={true}
          checkedIcon={
            props.variant ? (
              <span className={clsx(classes.icon, classes.checkedIconDark)} />
            ) : (
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            )
          }
          icon={
            props.variant ? (
              <span className={classes.iconDark} />
            ) : (
              <span className={classes.icon} />
            )
          }
          inputProps={{ 'aria-label': 'alert-type' }}
          {...props}
        />
      </FormControl>
    </ThemeProvider>
  )
}

export default CustomCheckbox
