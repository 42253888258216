import { decorate, observable, action, runInAction } from 'mobx'
import history from './history'
import dataService from './dataService'

class SubscriptionStore {
  errors = []
  messages = []
  subscription
  prices = []
  isLoading = false

  createSubscription = (data) => {
    this.isLoading = true
    const { customerId, paymentMethodId, priceId } = data
    dataService('post', 'users/me/subscriptions', {
      customer_id: customerId,
      payment_method_id: paymentMethodId,
      price_id: priceId,
    })
      .then((res) => {
        this.isLoading = false
        data.dataStore.getCurrentUser()
        if (data.newAccount) {
          history.push('patient-intake')
        }
        runInAction(() => {
          this.subscription = res.data
        })
      })
      .catch((err) => {
        this.isLoading = false
        console.log('err', err)
        if (err && err.errors) {
          this.errors = err.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }

  getSubscription = (data) => {
    console.log(data)
  }

  updateSubscription = (data) => {
    console.log(data)
  }

  cancelSubscription = (data) => {
    dataService('delete', `users/me/subscriptions/${data.subscription.id}`)
      .then((res) => {
        data.uiStateStore.setShowMembershipModal(false)
        runInAction(() => {
          this.subscription = res.data
          data.dataStore.getCurrentUser()
          this.messages = [
            'Your subscription has been canceled. You will continue to receive notifications until the end of the current billing period.',
          ]
        })
      })
      .catch((err) => {
        data.uiStateStore.setShowMembershipModal(false)
        if (err && err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors
        } else {
          this.errors = ['An unknown error has occurred.']
        }
      })
  }

  getPrices = () => {
    dataService('get', `prices`)
      .then((res) => {
        runInAction(() => {
          this.prices = res.data
        })
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
}

decorate(SubscriptionStore, {
  errors: observable,
  messages: observable,
  subscription: observable,
  prices: observable,
  isLoading: observable,
  createSubscription: action.bound,
  getSubscription: action.bound,
  updateSubscription: action.bound,
  cancelSubscription: action.bound,
  getPrices: action.bound,
})

export default new SubscriptionStore()
