import React from 'react'
import styled from '@emotion/styled'
import { H5 } from '../atoms/text'
import { NavLink } from 'react-router-dom'
import { Hidden } from '@material-ui/core'

import { Mq } from '../styles/settings'

const AccountNavigationContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  ${Mq.md} {
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    right: 0;
    top: -80px;
    width: auto;
  }
`

const AccountNavigationLink = styled.div`
  display: flex;
  padding: 24px 0;
  background: ${(props) => props.theme.colors.lightBlue};
  align-items: center;
  justify-content: center;
  width: 100%;

  & a.navbar__link.navbar__link--active H5 {
    color: ${(props) => props.theme.colors.brandPrimary};
  }

  H5 {
    font-size: 14px;
    transition: 0.2s ease-in-out;
    color: ${(props) => props.theme.colors.brandSecondary};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
    ${Mq.md} {
      font-size: 1rem;
      margin: 0 0 -4px 0;
    }
  }

  ${Mq.md} {
    padding: 16px 24px;
    margin-left: 1rem;
    width: auto;
    justify-content: flex-end;
  }
`

class AccountNavigation extends React.Component {
  render() {
    return (
      <AccountNavigationContainer>
        <AccountNavigationLink>
          <NavLink
            exact
            activeClassName="navbar__link--active"
            className="navbar__link"
            to="/prescriptions"
          >
            <H5>Prescriptions</H5>
          </NavLink>
        </AccountNavigationLink>

        <AccountNavigationLink>
          <NavLink
            exact
            activeClassName="navbar__link--active"
            className="navbar__link"
            to="/membership"
          >
            <H5>Membership</H5>
          </NavLink>
        </AccountNavigationLink>

        <AccountNavigationLink>
          <NavLink
            exact
            activeClassName="navbar__link--active"
            className="navbar__link"
            to="/account"
          >
            <H5>
              Account<Hidden smDown> details</Hidden>
            </H5>
          </NavLink>
        </AccountNavigationLink>
      </AccountNavigationContainer>
    )
  }
}

export default AccountNavigation
