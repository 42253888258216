import React from 'react'
import { Box, Flex } from '@rebass/emotion'
import styled from '@emotion/styled'

import { Mq } from '../styles/settings'
import { H1, H2, Paragraph } from '../atoms/text'
import { ArrowButton } from '../atoms/button'
import { Animation } from '../atoms/animation'
import { StepOne } from './stepOne'
import { StepTwo } from './stepTwo'
import { StepThree } from './stepThree'

const StyledH1 = styled(H1)`
  margin-top: 3rem;
  color: ${(props) => props.theme.colors.brandTertiary};

  ${Mq.md} {
    margin-top: 0px;
  }
`
export function HowItWorksOne(props) {
  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <Flex
      mt={(0, 0, 5)}
      width="auto"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={['column', 'column', 'row']}
    >
      <Box width={1}>
        <StepOne />
      </Box>
      <Box width={1} pl={[3, 3, 5]} pr={(3, 3, 0)}>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
          <StyledH1>1.</StyledH1>
        </Animation>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
        >
          <H2>Enter your dosage details</H2>
        </Animation>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
        >
          <Paragraph>
            Tell us what prescriptions and vitamins you take, how much, and when you want to take them  — or have a loved one do it for you. It’s simple, secure and only takes a couple of minutes.
          </Paragraph>
        </Animation>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
        >
          <ArrowButton title="See how it works" variant="dark" link="/how-it-works" />
        </Animation>
      </Box>
    </Flex>
  )
}

export function HowItWorksTwo(props) {
  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <Flex
      mt={5}
      width="auto"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={['column', 'column', 'row-reverse']}
    >
      <Box width={1}>
        <StepTwo />
      </Box>
      <Box width={1} pr={[3, 3, 5]} pl={[3, 3, 0]}>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
          <StyledH1>2.</StyledH1>
        </Animation>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
        >
          <H2>Set your personalized reminders</H2>
        </Animation>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
        >
          <Paragraph>
            Do you prefer a phone call? A text? Or is email more of your thing? Choose how — and how often — you want to be reminded to take your medication or vitamins.
          </Paragraph>
        </Animation>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
        >
          <ArrowButton title="Read our reviews" variant="dark" link="/reviews" />
        </Animation>
      </Box>
    </Flex>
  )
}

export function HowItWorksThree(props) {
  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <Flex
      mt={5}
      width="auto"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={['column', 'column', 'row']}
    >
      <Box width={1}>
        <StepThree />
      </Box>
      <Box width={1} pl={[3, 3, 5]} pr={(3, 3, 0)}>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
          <StyledH1>3.</StyledH1>
        </Animation>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
        >
          <H2>Let us do the rest</H2>
        </Animation>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
        >
          <Paragraph>
            Never again worry about missing a dose. We’ll give you a friendly reminder with the details, so you can focus on the other things that really matter.
          </Paragraph>
        </Animation>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
        >
          <ArrowButton title="Get started" variant="dark" link="/get-started" />
        </Animation>
      </Box>
    </Flex>
  )
}
