import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'
import { FormGroup } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { Hidden } from '@material-ui/core'
import { CheckCircle } from 'react-feather'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { Theme, Mq } from '../styles/settings'
import { TextInput } from '../atoms/input'
import { Animation } from '../atoms/animation'
import PhoneNumber from '../atoms/phoneNumber'
import Password from '../atoms/password'
import { H3, H4, H5, H6, Paragraph } from '../atoms/text'
import { Dropdown } from '../atoms/dropdown'
import { AccentButton, JumboButton } from '../atoms/button'

import { timeZoneOptions } from '../constants'

const CreateAccountFormContainer = styled.div`
  padding-bottom: 0px;
  width: 100%;

  ${Mq.md} {
    padding-bottom: 64px;
  }
`

const CustomHeader = styled.div`
  H3 {
    padding-bottom: 0.5rem;
  }
  H4 {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
      cursor: pointer;
    }

    svg {
      margin-right: 10px;
    }
  }
`

const ErrorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${(props) => props.theme.colors.error};
    margin-top: -8px;
    font-size: 12px;

    ${Mq.md} {
      font-size: 16px;
    }
  }
`

const AlertContainer = styled.div`
  display: block;
  text-align: right;
  margin-top: 24px;

  p {
    color: ${(props) => props.theme.colors.error};
    margin: 0;
  }
`

const FormContainer = styled.div`
  align-items: center;
  background: #fff;
  padding: 40px 16px;

  H6.error {
    color: ${(props) => props.theme.colors.error};
  }

  Button {
    margin: 0;
  }

  ${Mq.md} {
    padding: 40px 30px;
  }
`
const SuccessAlert = styled.div``

const SubmitBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 1.5rem;
    margin-top: 8px;
  }

  ${Mq.md} {
    margin-top: 0rem;
    justify-content: space-between;
  }
`

const MemberForm = styled.div`
  padding: 40px 0px 0;

  H4.dark {
    color: ${(props) => props.theme.colors.brandSecondary};
    margin-bottom: 0px;
  }
`
const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
)

class CreateAccountForm extends Component {
  state = {
    memberForm: true,
    otherForm: false,
    hasPatient: false,
    first_name: '',
    last_name: '',
    email: '',
    errors: {
      email: '',
      patient_email: '',
    },
    phone_number: '',
    password: '',
    password_confirmation: '',
    timezone: '',
    formSuccess: '',
    patient_first_name: '',
    patient_last_name: '',
    patient_email: '',
    patient_phone_number: '',
    patient_password: '',
    patient_timezone: '',
    rules: {
      isValidLength: false,
      hasNumber: false,
      hasLetter: false,
      noSpecialChar: true,
    },
  }

  handleChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value, password_error: false })
    this.props.dataStore.errors.length = 0
  }

  toggleForm = (val) => {
    if (
      (val === 'other' && this.state.hasPatient) ||
      (val === 'member' && !this.state.hasPatient)
    ) {
      return
    }
    this.setState({ hasPatient: !this.state.hasPatient })
  }

  handleUserInput(e) {
    e.preventDefault()
    this.setState({ ...this.state, [e.target.name]: e.target.value, password_error: false })
    this.props.dataStore.errors.length = 0

    const { name, value } = e.target
    let errors = this.state.errors

    switch (name) {
      case 'email':
        errors.email = validEmailRegex.test(value) ? '' : 'Email is not valid!'
        break
      case 'patient_email':
        errors.patient_email = validEmailRegex.test(value) ? '' : 'Email is not valid!'
        break
      default:
        break
    }

    this.setState({ errors, [name]: value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      phone_number,
      timezone,
    } = this.state

    const user = {
      first_name,
      last_name,
      email,
      password,
      phone_number: '+1' + phone_number,
      timezone,
    }
    if (password !== password_confirmation) {
      this.setState({ password_error: 'Passwords do not match' })
    } else {
      if (this.state.hasPatient) {
        const patient = {
          first_name: this.state.patient_first_name,
          last_name: this.state.patient_last_name,
          email: this.state.patient_email,
          phone_number: '+1' + this.state.patient_phone_number,
          timezone: this.state.patient_timezone,
        }
        user['patient_attributes'] = patient
      }
      this.props.dataStore.createLocalUser({ user })
      this.setState({ form__success: 'User Created' })
    }
  }

  render() {
    const { dataStore, uiStateStore } = this.props

    const {
      first_name,
      last_name,
      email,
      phone_number,
      password,
      timezone,
      password_confirmation,
      password_error,
      type,
      meterTitle,
      errors,
    } = this.state

    const isEnabled =
      first_name.length > 0 &&
      last_name.length > 0 &&
      email.length > 0 &&
      errors.email.length <= 0 &&
      phone_number.length > 0 &&
      password.length > 0 &&
      password_confirmation &&
      timezone.length > 0 &&
      !password_error &&
      uiStateStore.passwordStrength &&
      dataStore.errors.length <= 0

    const {
      patient_first_name,
      patient_last_name,
      patient_email,
      patient_phone_number,
      patient_timezone,
    } = this.state

    const isEnabledPatient =
      patient_first_name.length > 0 &&
      patient_last_name.length > 0 &&
      patient_email.length > 0 &&
      errors.patient_email.length <= 0 &&
      patient_phone_number.length > 0 &&
      patient_timezone.length > 0 &&
      first_name.length > 0 &&
      last_name.length > 0 &&
      email.length > 0 &&
      errors.email.length <= 0 &&
      phone_number.length > 0 &&
      password.length > 0 &&
      password_confirmation &&
      !password_error &&
      timezone.length > 0 &&
      dataStore.errors.length <= 0

    return (
      <CreateAccountFormContainer>
        <p>{dataStore.data}</p>
        <CustomHeader>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
          >
            <H3>Account details</H3>
          </Animation>
        </CustomHeader>
        <FormContainer>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
          >
            <H4>Who are the reminders for?</H4>
          </Animation>
          <FormGroup>
            <Flex width="auto" mt={3} mb={3} mx={-2}>
              <Box width={1} pl={2} pr={2}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
                >
                  <AnchorLink href="#account" offset="40">
                    <JumboButton
                      type="button"
                      title="Myself"
                      active={!this.state.hasPatient}
                      onClick={() => {
                        this.toggleForm('member')
                      }}
                    />
                  </AnchorLink>
                </Animation>
              </Box>
              <Box width={1} pl={2} pr={2}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
                >
                  <AnchorLink href="#account" offset="40">
                    <JumboButton
                      type="button"
                      title="Someone else"
                      active={this.state.hasPatient}
                      onClick={() => {
                        this.toggleForm('other')
                      }}
                    />
                  </AnchorLink>
                </Animation>
              </Box>
            </Flex>

            <section id="account"></section>
            <Animation
              initial="fadeIn"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 1 }}
            >
              {this.state.hasPatient ? (
                <MemberForm>
                  <H4>Add the member's details:</H4>
                  <Flex width="auto" mt={4} mx={-2} flexDirection={['column', 'column', 'row']}>
                    <Box width={1} pl={2} pr={2}>
                      <H5 className="dark">Their first name:</H5>
                      <TextInput
                        dark={true}
                        placeholder=""
                        description="Member First Name"
                        name="patient_first_name"
                        value={this.state.patient_first_name}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </Box>
                    <Box width={1} pl={2} pr={2}>
                      <H5 className="dark">Their last name:</H5>
                      <TextInput
                        dark={true}
                        placeholder=""
                        description="Member Last Name"
                        type="phone"
                        name="patient_last_name"
                        value={this.state.patient_last_name}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </Box>
                  </Flex>
                  <Flex width="auto" mx={-2} flexDirection={['column', 'column', 'row']}>
                    <Box width={1} pl={2} pr={2}>
                      <Flex justifyContent="space-between">
                        <H5 className="dark">Their Email:</H5>
                        {this.state.errors.patient_email.length > 0 && (
                          <H6 className="error">{this.state.errors.patient_email}</H6>
                        )}
                      </Flex>
                      <TextInput
                        dark={true}
                        placeholder="For email notifications"
                        description="Email input"
                        name="patient_email"
                        type="patient_email"
                        email={true}
                        autocapitalize="off"
                        inputProps={{ autocapitalize: 'off' }}
                        value={this.state.patient_email}
                        onChange={(e) => this.handleUserInput(e)}
                      />
                    </Box>
                    <Box width={1} pl={2} pr={2}>
                      <H5 className="dark">Their phone number:</H5>
                      <PhoneNumber
                        handleChange={(e) => {
                          this.handleChange(e)
                        }}
                        name="patient_phone_number"
                        value={this.state.patient_phone_number}
                      />
                    </Box>
                  </Flex>
                  <Flex width="auto" mb={4} mx={-2}>
                    <Box width={1} pl={2} pr={2}>
                      <H5 className="dark">Subscriber time zone:</H5>
                      <Dropdown
                        dark={true}
                        options={timeZoneOptions}
                        placeholder="Select timezone"
                        description="Subscriber time zone"
                        onChange={(e) => {
                          this.setState({ patient_timezone: e.value })
                        }}
                      />
                    </Box>
                    <Hidden smDown>
                      <Box width={1} mb={1} pl={2} pr={2}></Box>
                    </Hidden>
                  </Flex>
                </MemberForm>
              ) : null}

              <MemberForm>
                <H4>Add your account details</H4>
                <Flex width="auto" mt={4} mx={-2} flexDirection={['column', 'column', 'row']}>
                  <Box width={1} pl={2} pr={2}>
                    <H5 className="dark">Your first name:</H5>
                    <TextInput
                      dark={true}
                      placeholder=""
                      description="Your First Name"
                      type="text"
                      name="first_name"
                      value={this.state.first_name}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Box>
                  <Box width={1} pl={2} pr={2}>
                    <H5 className="dark">Your last name:</H5>
                    <TextInput
                      dark={true}
                      placeholder=""
                      description="Your Last Name"
                      type="text"
                      name="last_name"
                      value={this.state.last_name}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Box>
                </Flex>
                <Flex width="auto" mx={-2} flexDirection={['column', 'column', 'row']}>
                  <Box width={1} pl={2} pr={2}>
                    <Flex justifyContent="space-between">
                      <H5 className="dark">Email:</H5>
                      {this.state.errors.email.length > 0 && (
                        <H6 className="error">{this.state.errors.email}</H6>
                      )}
                    </Flex>
                    <TextInput
                      dark={true}
                      placeholder="This will be used as your login"
                      description="Email input"
                      name="email"
                      type="email"
                      email={true}
                      value={this.state.email}
                      onChange={(e) => this.handleUserInput(e)}
                    />
                  </Box>
                  <Box width={1} pl={2} pr={2}>
                    <H5 className="dark">Phone number:</H5>
                    <PhoneNumber
                      handleChange={(e) => {
                        this.handleChange(e)
                      }}
                      name="phone_number"
                      value={this.state.phone_number}
                    />
                  </Box>
                </Flex>
                <Flex width={1}>
                  <Box width={1}>
                    <ErrorHeader>
                      <H5 className="dark">Password:</H5>
                      <Paragraph>{meterTitle}</Paragraph>
                    </ErrorHeader>
                    <Password
                      dark={true}
                      placeholder="At least 4 characters"
                      description="Password input"
                      type={type}
                      name="password"
                      error={this.state.password_error ? true : null}
                      value={this.state.password}
                      onChange={(e) => this.handleChange(e)}
                    />

                    <ErrorHeader>
                      <H5 className="dark">Verify password:</H5>
                      <Paragraph>{this.state.password_error}</Paragraph>
                    </ErrorHeader>
                    <Password
                      dark={true}
                      placeholder=""
                      description="Verify password input"
                      type="password"
                      name="password_confirmation"
                      error={this.state.password_error ? true : null}
                      value={this.state.password_confirmation}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Box>
                </Flex>
                <Flex width="auto" mb={4} mx={-2}>
                  <Box width="100%" pl={2} pr={2}>
                    <H5 className="dark">Your time zone:</H5>
                    <Dropdown
                      dark={true}
                      options={timeZoneOptions}
                      placeholder="Select timezone"
                      description="Subscriber time zone"
                      onChange={(e) => {
                        this.setState({ timezone: e.value })
                      }}
                    />
                  </Box>
                  <Hidden smDown>
                    <Box width={1} mb={1} pl={2} pr={2}></Box>
                  </Hidden>
                </Flex>
                <Flex
                  mt={this.state.hasPatient ? ['0px', '0px', '-80px'] : ['40px', '40px', '-80px']}
                  width={1}
                  mb={3}
                  justifyContent="flex-end"
                >
                  <SubmitBox width={1} textAlign="right">
                    <SuccessAlert>
                      {this.props.dataStore.success ? (
                        <Animation
                          initial="fadeIn"
                          animate="visible"
                          variants={motionVariants}
                          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
                        >
                          <CheckCircle color={Theme.colors.success} size={32} />
                        </Animation>
                      ) : null}
                    </SuccessAlert>

                    <AnchorLink href="#billing" offset="60">
                      <AccentButton
                        title="Continue"
                        type="submit"
                        onClick={(e) => {
                          this.handleSubmit(e)
                        }}
                        disabled={!isEnabled && !isEnabledPatient}
                      />
                    </AnchorLink>
                  </SubmitBox>
                </Flex>

                {dataStore.errors && dataStore.errors.length ? (
                  <AlertContainer>
                    <Paragraph style={{ color: Theme.colors.error }}>
                      The following error(s) have occurred:
                      {dataStore.errors &&
                        dataStore.errors.map((err, key) => <Paragraph>{err}</Paragraph>)}
                    </Paragraph>
                  </AlertContainer>
                ) : null}
              </MemberForm>
            </Animation>
          </FormGroup>
        </FormContainer>
      </CreateAccountFormContainer>
    )
  }
}

export default inject('dataStore', 'uiStateStore')(observer(CreateAccountForm))
