import React from 'react'
import styled from '@emotion/styled'
import Mailchimp from 'react-mailchimp-form'

import { Mq } from '../styles/settings'

const CustomForm = styled.div`
  display: flex;

  form {
    display: inline-block;
    width: 100%;
    padding-top: 30px;
    position: relative;
  }

  input {
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 50px;
    padding: 15px 20px;
    color: ${(props) => props.theme.colors.white};
    transition: 0.2s ease-in-out;
    background: transparent;
    border: 3px solid ${(props) => props.theme.colors.white};
    width: 100%;
    position: absolute;
    outline: none;

    &.input--email {
      height: calc(1.8em + 1rem + 2px);
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &::placeholder {
      color: ${(props) => props.theme.colors.white};
    }
    &:hover,
    &:focus,
    &:active {
      border-color: ${(props) => props.theme.colors.brandTertiary};
    }
  }

  .msg-alert {
    width: 100%;

    p {
      position: absolute;
      bottom: -104px;
      right: 0px;
      font-family: ${(props) => props.theme.boldFont};
    }
  }

  button {
    padding: 15px 20px;
    font-size: 20px;
    line-height: 1.5rem;
    position: absolute;
    font-weight: bold;
    right: 0;
    color: ${(props) => props.theme.colors.white};
    transition: 0.2s ease-in-out;
    border-top-left-radius: 0px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 50px;
    background-color: ${(props) => props.theme.colors.blueGrey};
    border: 3px solid ${(props) => props.theme.colors.white};
    outline: none;
    cursor: pointer;
    width: 28%;

    &:hover,
    &:focus,
    &:active {
      color: ${(props) => props.theme.colors.blueGrey};
      background-color: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.white};
    }

    ${Mq.md} {
      width: 25%;
    }
  }
`

export function EmailCapture(props) {
  return (
    <CustomForm>
      <Mailchimp
        action="https://myrxcall.us19.list-manage.com/subscribe/post?u=757a3c98383d7c974db565baa&amp;id=c0cd4a1ffa"
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Enter your email',
            type: 'email',
            required: true,
          },
        ]}
        messages={{
          sending: 'Sending...',
          success: 'Thank you for subscribing!',
          error: 'Error! Refresh and try again.',
          empty: 'Please enter an e-mail.',
          duplicate: 'This email is already subscribed.',
          button: 'Join',
        }}
        className="mailchimp__email-form"
      />
    </CustomForm>
  )
}
