import React from 'react'
import styled from '@emotion/styled'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import MetaTags from 'react-meta-tags'

import AddPrescription from '../organisms/addPrescription'
import { SmallContainer } from '../atoms/container'
import { H1 } from '../atoms/text'

export const CustomContainer = styled.main`
  padding: 100px 0;
  margin-top: 4rem;
`
export const CustomHeader = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-between;

  H1 span:nth-child(2) {
    margin-left: 1.5rem;
  }
`

const PatientIntake = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Patient intake</title>
        <meta
          name="description"
          content="Introducing MyRxCall - the most reliable way to manage medications. Receieve personalized medication reminders via phone call, SMS, or e-mail."
        />
        <meta property="og:title" content="MyRxCall – Your patient intake" />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallContainer>
        <CustomContainer>
          <CustomHeader>
            <H1>
              Welcome to my<span style={{ color: '#3488F8' }}>rx</span>call
            </H1>
          </CustomHeader>
          <AddPrescription />
        </CustomContainer>
      </SmallContainer>
    </main>
  )
}

export default PatientIntake
