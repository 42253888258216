import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { inject, observer } from 'mobx-react'

import { Mq } from '../styles/settings'
import { H2, Paragraph } from '../atoms/text'
import { Animation } from '../atoms/animation'
import { Button } from '../atoms/button'

const StyledContainer = styled.div`
  padding: 0 1rem;

  p {
    max-width: 640px;
    margin: auto;
    font-size: 1.2rem;

    &.link {
      color: ${(props) => props.theme.colors.brandSecondary};
      transition: 0.2s ease-in-out;
      font-size: 1rem;
      margin-top: 1;
      cursor: pointer;

      :hover {
        color: ${(props) => props.theme.colors.brandPrimary};
      }
    }
  }

  H2 {
    margin: 0;
  }

  Button {
    margin-top: 3rem;
    margin-bottom: 1rem;
    z-index: 3;
  }

  ${Mq.md} {
    padding: 0;
  }
`

const StyledHeader = styled.div`
  padding-bottom: 2rem;

  H2 {
    text-align: center;
  }
`
const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

class PauseMembership extends React.Component {
  render(props) {
    const { subscriptionStore, uiStateStore, dataStore } = this.props
    return (
      <StyledContainer>
        <Animation
          initial="hidden"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
        >
          <StyledHeader>
            <H2>Pause membership</H2>
          </StyledHeader>
        </Animation>
        <Animation
          initial="fadeIn"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
        >
          <Flex justifyContent="center" flexDirection="column">
            <Box width={1} textAlign="center">
              <Paragraph>
                Are you sure you want to pause your membership? You will receive alerts through the
                end of your billing cycle. If you decide to contine, we hope to see you back soon!
              </Paragraph>
            </Box>
            <Box width={1} mt={3} textAlign="center">
              <Button
                title="Pause my membership"
                onClick={() =>
                  subscriptionStore.cancelSubscription({
                    subscription: this.props.dataStore.user.subscription,
                    uiStateStore,
                    dataStore,
                  })
                }
              />
              <Paragraph
                className="link"
                onClick={() => uiStateStore.setShowMembershipModal(false)}
              >
                Nevermind
              </Paragraph>
            </Box>
          </Flex>
        </Animation>
      </StyledContainer>
    )
  }
}

export default inject('dataStore', 'subscriptionStore', 'uiStateStore')(observer(PauseMembership))
