import React from 'react'
import { useRef, useState, useLayoutEffect } from 'react'
import styled from '@emotion/styled'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { Mq } from '../styles/settings'
import Image from '../assets/images/step1.png'

const StepContainer = styled.div`
  width:100%:
  height: auto;
  background: transparent;
  position: relative;
  border: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  ${Mq.md} {
    justify-content: flex-end;
    margin-right: 5rem;
  }
`

const ImageBackgroundBox = styled.div`
  width: 527px;
  height: 260px;
  border: 0px;
  border-radius: 0px;
  background: ${(props) => props.theme.colors.brandTertiary};
  background-size: cover;
  border-radius: 8px;
  margin-right: ${(props) => props.margin};
  z-index: 1;

  ${Mq.md} {
    width: 527px;
    height: 383px;
    margin-top: 0px;
    margin-left: 56px;
  }
`

const Blob = styled.div`
  display: none;

  ${Mq.md} {
    display: block;
    top: -60px;
    left: 20px;
    z-index: 0;
    position: absolute;
  }
`

const LayeredSvg = styled.div`
  position: relative;
  z-index: 1;
`

const SvgContainer = styled.div`
  position: absolute;
  bottom: -8px;
  padding: 0;
  left: 0px;
  border: 0px;
  z-index: 2;


  img {
    padding: 0 6rem;
    margin-left: -4px;
  }
  ${Mq.md} {
    margin-left: -5rem;
    img {
      padding: 0 0;
    }
`

const ParallaxImage = ({ src, ...style }) => {
  const [elementTop, setElementTop] = useState(0)
  const ref = useRef(null)
  const { scrollY } = useViewportScroll()

  const y = useTransform(scrollY, [elementTop, elementTop - 10], [0, -1], {
    clamp: false,
  })

  useLayoutEffect(() => {
    const element = ref.current
    setElementTop(element.offsetTop)
  }, [ref])

  return (
    <div ref={ref} className="image-container">
      <motion.div className="overlay" style={{ ...style, y }}>
        <svg width="252" height="214" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.969 192.07C-39.7 129.194 39.863 43.455 119.955 10.904c80.092-32.55 162.517 11.262 120.869 85.177-41.648 73.916-157.188 158.864-218.855 95.989z"
            fill="#F2F6FF"
          />
        </svg>
      </motion.div>
    </div>
  )
}

export function StepOne({ src, ...style }) {
  const variants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    slideInStart: {
      opacity: 0,
      x: '-80px',
    },
    slideInFinish: {
      opacity: 1,
      x: '0',
    },
  }
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px',
  })
  return (
    <motion.div
      ref={ref}
      initial="slideInStart"
      animate={inView ? 'slideInFinish' : 'hidden'}
      variants={variants}
      transition={{ ease: 'easeInOut', duration: 1.2 }}
    >
      <StepContainer>
        <LayeredSvg>
          <SvgContainer>
            <img src={Image} alt="Step1" />
          </SvgContainer>
          <ImageBackgroundBox />
        </LayeredSvg>
        <Blob>
          <ParallaxImage />
        </Blob>
      </StepContainer>
    </motion.div>
  )
}
