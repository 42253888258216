import React from 'react'
import styled from '@emotion/styled'
import { Settings, X } from 'react-feather'
import ReactModal from 'react-modal'
import { inject, observer } from 'mobx-react'
import { Flex } from '@rebass/emotion'

import { Mq } from '../styles/settings'
import { Animation } from '../atoms/animation'
import { H3, H4, H5 } from '../atoms/text'
import PrescriptionForm from '../molecules/prescriptionForm'

var moment = require('moment-timezone')

const PrescriptionContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1rem;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;

  ${Mq.md} {
    padding: 1rem;
  }
`

const PrescriptionRender = styled.div`
  width: auto;
  border: 4px solid ${(props) => props.theme.colors.black};
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 25px 20px;
  flex-direction: column;

  H3 {
    padding-top: 10px;
    margin-bottom: 0px;
    margin-bottom: 0px;
  }
  H4 {
    margin: -10px 0 0 0;
    padding: 0;
  }
  svg {
    margin-left: -3px;
  }
`

const PrescriptionDetails = styled.div`
  width: 70%;
  padding-left: 1rem;

  H3 {
    padding-bottom: 0px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    ${Mq.md} {
      padding-bottom: 10px;
      max-width: 200px;
    }
    ${Mq.xs} {
      padding-bottom: 10px;
      max-width: 160px;
    }
  }

  H5 {
    line-height: 24px;
    display: flex;
    margin: 0px;
    align-items: baseline;
    font-family: ${(props) => props.theme.brandFont};
    flex-wrap: wrap;
    & .dataPoint {
      padding-right: 4px;
      font-family: ${(props) => props.theme.boldFont};
    }
  }
`

const RxIcon = styled.div`
  border-bottom: 4px solid ${(props) => props.theme.colors.black};
  padding: 0 15px 15px;
`

const SettingsIcon = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;

  svg {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    stroke: ${(props) => props.theme.colors.brandPrimary};
  }

  ${Mq.md} {
    top: 20px;
    right: 20px;
  }
`

const CloseModal = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    stroke: ${(props) => props.theme.colors.brandPrimary};
  }
`

const ModalWrapper = styled.div`
  padding: 0 1rem;

  ${Mq.md} {
    padding: 0 4rem;
  }
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#F3F6F9',
    padding: '80px 1rem',
    border: '0px',
    borderRadius: '0px',
    width: '90%',
    maxWidth: '970px',
    maxHeight: '100vh',
  },
  overlay: {
    backgroundColor: 'RGBA(54,59,61,0.8)',
    backdropFilter: 'blur(4px)',
    width: '100vw',
    zIndex: '9999',
  },
}

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

class Prescription extends React.Component {
  returnOrdinal(val) {
    const hash = {
      1: 'once',
      2: 'twice',
      3: 'three times',
      4: 'four times',
      5: 'five times',
    }
    return hash[val]
  }

  render() {
    const { title, dosage, prescription_times } = this.props.prescription
    const { user } = this.props.dataStore
    const isCaregiverAccount = user && user.patient ? true : false

    return (
      <>
        <PrescriptionContainer>
          <Animation
            initial="fadeIn"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 1 }}
          >
            <PrescriptionRender>
              <RxIcon>
                <svg width="40" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.297 21.568c2.162 0 3.326-1.258 4.657-3.055l3.326-4.134-4.324-7.01c-.998-1.438-1.996-2.157-3.492-2.336V3.954h12.971v1.079c-.831.18-1.164.539-1.164.898 0 .36.166.72.499 1.258l1.663 2.696c1.33-1.437 1.83-2.696 1.83-3.594 0-.72-.5-1.079-1.498-1.258V3.954h6.819v1.079c-1.663.18-2.827 1.078-3.992 2.696l-2.494 3.415 5.155 8.088c.998 1.617 1.996 2.516 3.326 2.516v1.078H26.11v-1.078c1.33 0 1.83-.18 1.83-1.258 0-.18-.167-.54-.333-.899l-2.329-3.954-1.496 2.157c-.5.898-.832 1.617-.832 2.157 0 1.078.666 1.617 1.996 1.617v1.258h-8.481v-1.258h-.167z"
                    fill="#363B3D"
                  />
                  <path
                    d="M3.659 23.725v-2.876h.831c.998 0 1.497-.18 1.497-1.797v-7.19c0-.899 0-1.078-.665-1.438L1.829 7.728l1.497-.539c2.328-.899 5.155-2.337 9.479-4.673l1.164-.72v2.158c.998-1.079 2.162-1.438 3.326-1.438 2.162 0 3.659 1.617 3.659 3.954 0 2.157-1.33 3.595-3.326 3.595-1.33 0-2.162-.899-2.661-1.438l-.333-.36s-.166.54-.166 2.876v7.909c0 1.617.333 1.797 1.497 1.797h.831l.166.899v1.977H3.66z"
                    fill="#363B3D"
                  />
                  <path
                    d="M13.138 3.056V6.47c1.164-2.157 2.494-3.236 4.157-3.236 1.663 0 2.827 1.258 2.827 3.056 0 1.617-.997 2.696-2.494 2.696-1.663 0-2.328-1.798-2.994-1.798-.831 0-1.164 1.259-1.164 3.775v7.908c0 1.977.665 2.696 2.329 2.696h.166v1.258H4.49v-1.078h.166c1.663 0 2.162-.899 2.162-2.696v-7.19c0-1.078-.166-1.617-.998-2.156L3.66 8.088a223.222 223.222 0 009.479-5.032zM14.8 0l-2.495 1.438C7.982 3.774 5.322 5.212 2.993 6.11L0 7.189l2.66 1.978 2.163 1.617c.166.18.332.18.332.18v7.729c0 .539 0 .898-.166.898H2.827V24.624h14.967V19.95H15.3s-.167-.18-.167-.899v-7.908-1.079c.666.36 1.33.899 2.495.899 2.328 0 4.157-1.797 4.157-4.493 0-2.876-1.995-4.853-4.49-4.853-.831 0-1.663.18-2.494.719V0z"
                    fill="#fff"
                  />
                </svg>
              </RxIcon>
              <H3 semiBold="true">{dosage}</H3>
              <H4>Mg</H4>
            </PrescriptionRender>
          </Animation>
          <PrescriptionDetails>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
            >
              <H3 semiBold="true">{title}</H3>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
            >
              <H5 light="true" className="times-taken">
                Taken&nbsp;
                <H5 semiBold="true" className="dataPoint">
                  {prescription_times && this.returnOrdinal(prescription_times.length)} daily
                </H5>
              </H5>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
            >
              <Flex mt={2}>
                <H5 light="true">
                  at:&nbsp;
                  {prescription_times &&
                    prescription_times.map((time, i) => {
                      const timezone = isCaregiverAccount ? user.patient.timezone : user.timezone
                      return (
                        <H5 key={i} semiBold="true" className="dataPoint">
                          {moment.utc(time.time).tz(timezone).format('hh:mmA')}
                        </H5>
                      )
                    })}
                  {/*this.props.timeZone*/}
                </H5>
              </Flex>
            </Animation>
          </PrescriptionDetails>

          <SettingsIcon>
            <Animation
              initial="fadeIn"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 1 }}
            >
              <Settings
                color="#B5C9DB"
                onClick={() => {
                  this.props.uiStateStore.setShowModal(this.props.prescription.id)
                }}
              />
            </Animation>
          </SettingsIcon>
        </PrescriptionContainer>
        <ReactModal
          style={modalStyles}
          isOpen={this.props.uiStateStore.showModal === this.props.prescription.id}
          contentLabel="Edit Prescription Modal"
          onRequestClose={() => {
            this.props.uiStateStore.setShowModal(false)
          }}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModal>
            <X onClick={() => this.props.uiStateStore.setShowModal(false)} />
          </CloseModal>
          <ModalWrapper>
            <PrescriptionForm prescription={this.props.prescription} updatePrescription="true" />
          </ModalWrapper>
        </ReactModal>
      </>
    )
  }
}

export default inject('dataStore', 'uiStateStore')(observer(Prescription))
