import React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

import { Theme } from '../styles/settings'

const selectStyles = {
  option: (provided) => ({
    ...provided,
    color: Theme.colors.black,
    padding: '16px 24px',
    backgroundColor: Theme.colors.white,
    fontSize: '18px',
    marginTop: '0px',
    lineHeight: '1.5',
    boxShadow: '0px',
    zIndex: '9',
    '&:hover': {
      backgroundColor: Theme.colors.lightGrey,
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '0px',
    boxShadow: '0px',
    maxHeight: '400px',
    overflowY: 'auto',
  }),
  container: () => ({
    marginBottom: '24px',
    position: 'relative',
    boxSizing: 'border-box',
  }),
  control: () => ({
    background: Theme.colors.white,
    display: 'flex',
    borderBottom: '2px solid',
    borderColor: Theme.colors.brandTertiary,
    padding: '0.25rem 0 0.25rem 0.5rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '0px',
    fontSize: '18px',
    lineHeight: '1.5',
    transition: '0.2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '2px solid',
      borderColor: Theme.colors.brandPrimary,
    },
  }),
  placeholder: () => ({
    lineHeight: '1.5',
    fontSize: '18px',
    color: Theme.colors.black,
    opacity: '0.6',
  }),
  dropdownIndicator: () => ({
    color: Theme.colors.brandTertiary,
    padding: '10px 10px 4px',
    cursor: 'pointer',
  }),
  menuList: () => ({
    borderBottom: '2px solid',
    borderColor: Theme.colors.brandTertiary,
    boxShadow: '0px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition }
  },
}

const selectStylesDark = {
  option: (provided) => ({
    ...provided,
    color: '#030C07',
    padding: '16px 24px',
    backgroundColor: Theme.colors.lightBlue,
    fontSize: '18px',
    marginTop: '0px',
    boxShadow: '0px',
    '&:hover': {
      backgroundColor: Theme.colors.lightGrey,
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '0px',
    boxShadow: '0px',
  }),
  container: () => ({
    position: 'relative',
    boxSizing: 'border-box',
  }),
  control: () => ({
    background: Theme.colors.lightBlue,
    display: 'flex',
    borderBottom: '2px solid',
    borderColor: Theme.colors.brandTertiary,
    padding: '0.25rem 0 0.25rem 0.5rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '0px',
    fontSize: '18px',
    lineHeight: '1.5',
    transition: '0.2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '2px solid',
      borderColor: Theme.colors.brandPrimary,
    },
  }),
  placeholder: () => ({
    lineHeight: '1.5',
    fontSize: '18px',
    color: Theme.colors.black,
    opacity: '0.6',
  }),
  dropdownIndicator: () => ({
    color: Theme.colors.brandTertiary,
    padding: '10px 10px 4px',
    cursor: 'pointer',
  }),
  menuList: () => ({
    borderBottom: '2px solid',
    brandColor: Theme.colors.brandTertiary,
    boxShadow: '0px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition }
  },
}

export function Dropdown(props) {
  return props.dark ? (
    <Select
      placeholder={props.placeholder}
      options={props.options}
      styles={selectStylesDark}
      {...props}
    />
  ) : (
      <Select
        placeholder={props.placeholder}
        options={props.options}
        styles={selectStyles}
        {...props}
      />
    )
}

export default Dropdown

export function AsyncDropdown(props) {
  return props.dark ? (
    <AsyncSelect
      placeholder={props.placeholder}
      options={props.options}
      // loadOptions={props.loadOptions}
      // defaultOptions={props.defaultOptions}
      // cacheOptions={props.cacheOptions}
      onInputChange={props.onInputChange}
      onChange={props.onChange}
      styles={selectStylesDark}
      {...props}
    />
  ) : (
      <AsyncSelect
        placeholder={props.placeholder}
        options={props.options}
        // loadOptions={props.loadOptions}
        // defaultOptions={props.defaultOptions}
        // cacheOptions={props.cacheOptions}
        onInputChange={props.onInputChange}
        onChange={props.onChange}
        styles={selectStyles}
        {...props}
      />
    )
}
