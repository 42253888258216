import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { Link } from 'react-router-dom'
import { DollarSign, Star } from 'react-feather'
import { Hidden } from '@material-ui/core'

import { Mq } from '../styles/settings'
import { Animation } from '../atoms/animation'
import { Paragraph, H1, H2, H3, H4, H5 } from '../atoms/text'
import { Button, AccentButton } from '../atoms/button'

const RightContainer = styled.div`
  display: block;
  width: 100%;
  margin-top: 3rem;

  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  p {
    display: flex;
    justify-content: flex-start;
    color: ${(props) => props.theme.colors.brandSecondary};
  }
  H2 {
    line-height: 32px;
  }
  H3 {
    line-height: 20px;
  }
  H5 {
    margin-top: -5px;
    padding-bottom: 3px;
  }
  svg {
    margin: 3px 4px 0 0;
    color: ${(props) => props.theme.colors.brandTertiary};
  }
  button {
    margin: 0 0 0 auto;
    text-align: right;
  }

  ${Mq.md} {
    display: flex;
    align-items: baseline;
    margin-top: 0;
    justify-content: flex-end;

    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    p {
      justify-content: flex-end;
    }
  }
`

export function HowItWorksHeroSubContent(props) {
  const variants = {
    slideInStart: {
      opacity: 0,
      x: '64px',
    },
    slideInFinish: {
      opacity: 1,
      x: '0',
    },
  }

  return (
    <RightContainer>
      <Animation
        animate="slideInFinish"
        initial="slideInStart"
        variants={variants}
        transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.4 }}
      >
        <Flex width={1} flexWrap="wrap" flexDirection={['column', 'column', 'row']}>
          <Box width={1} textAlign="right">
            <H2>1. Sign up for myrxcall</H2>
            <H2>2. Submit your prescriptions</H2>
            <H2>3. Receive automated alerts</H2>
          </Box>
        </Flex>
      </Animation>
    </RightContainer>
  )
}

export function PricingHeroSubContent(props) {
  const variants = {
    slideInStart: {
      opacity: 0,
      x: '64px',
    },
    slideInFinish: {
      opacity: 1,
      x: '0',
    },
  }

  return (
    <Hidden smDown>
      <RightContainer>
        <Animation
          animate="slideInFinish"
          initial="slideInStart"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.4 }}
        >
          <Flex width={1} flexWrap="wrap" flexDirection={['column', 'column', 'row']}>
            <Box width={1} textAlign="right">
              <H1>
                <DollarSign />
                9.95
              </H1>
              <H3 className="h3__split-right">Per month</H3>
              <Paragraph>that's it...</Paragraph>
            </Box>
          </Flex>
        </Animation>
      </RightContainer>
    </Hidden>
  )
}

export function ReviewsHeroSubContent(props) {
  const variants = {
    slideInStart: {
      opacity: 0,
      x: '64px',
    },
    slideInFinish: {
      opacity: 1,
      x: '0',
    },
  }

  return (
    <RightContainer>
      <Animation
        animate="slideInFinish"
        initial="slideInStart"
        variants={variants}
        transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.4 }}
      >
        <Flex width={1} flexWrap="wrap" flexDirection={['column', 'column', 'row']}>
          <Box width={1} textAlign={['left', 'left', 'right']}>
            <H2>"This thing saved my life""</H2>
            <H4>Fredrick LaCroix - myrxcall subscriber</H4>
            <Star fill="#084C87" color="#084C87" />
            <Star fill="#084C87" color="#084C87" />
            <Star fill="#084C87" color="#084C87" />
            <Star fill="#084C87" color="#084C87" />
            <Star fill="#084C87" color="#084C87" />
          </Box>
        </Flex>
      </Animation>
    </RightContainer>
  )
}

export function HelpHeroSubContent(props) {
  const variants = {
    slideInStart: {
      opacity: 0,
      x: '64px',
    },
    slideInFinish: {
      opacity: 1,
      x: '0',
    },
  }

  return (
    <RightContainer>
      <Animation
        animate="slideInFinish"
        initial="slideInStart"
        variants={variants}
        transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.4 }}
      >
        <Flex width={1} flexWrap="wrap" flexDirection={['column', 'column', 'row']}>
          <Box width={1} display="flex" justifyContent={['flex-start', 'flex-start', 'flex-end']}>
            <Link to="/support-request">
              <AccentButton title="Submit a request" />
            </Link>
          </Box>
        </Flex>
      </Animation>
    </RightContainer>
  )
}

export function GetStartedHeroSubContent(props) {
  const variants = {
    slideInStart: {
      opacity: 0,
      x: '64px',
    },
    slideInFinish: {
      opacity: 1,
      x: '0',
    },
  }

  return (
    <RightContainer>
      <Animation
        animate="slideInFinish"
        initial="slideInStart"
        variants={variants}
        transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.4 }}
      >
        <Flex width={1} flexWrap="nowrap" justifyContent="flex-end" textAlign="right">
          <Box width={1}>
            <H2>
              <DollarSign />
              9.95 / month
            </H2>
            <H5>No hidden fees. No initiation. No contracts.</H5>
            <Button title="Subscribe" />
          </Box>
        </Flex>
      </Animation>
    </RightContainer>
  )
}

export function SupportHeroSubContent(props) {
  const variants = {
    slideInStart: {
      opacity: 0,
      x: '64px',
    },
    slideInFinish: {
      opacity: 1,
      x: '0',
    },
  }

  return (
    <RightContainer>
      <Animation
        animate="slideInFinish"
        initial="slideInStart"
        variants={variants}
        transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.4 }}
      >
        <Flex width={1} flexWrap="wrap" flexDirection={['column', 'column', 'row']}>
          <Box width={1} display="flex" justifyContent={['flex-start', 'flex-start', 'flex-end']}>
            <Link to="/help">
              <AccentButton title="Back to help" />
            </Link>
          </Box>
        </Flex>
      </Animation>
    </RightContainer>
  )
}

export function AboutHeroSubContent(props) {
  return (
    <Hidden smDown>
      <RightContainer></RightContainer>
    </Hidden>
  )
}

export function NoMatchHeroSubContent(props) {
  const variants = {
    slideInStart: {
      opacity: 0,
      x: '64px',
    },
    slideInFinish: {
      opacity: 1,
      x: '0',
    },
  }

  return (
    <RightContainer>
      <Animation
        animate="slideInFinish"
        initial="slideInStart"
        variants={variants}
        transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.4 }}
      >
        <Flex width={1} flexWrap="noWrap" flexDirection={['column', 'column', 'row']}>
          <Box width={1} justifyContent={['flex-start', 'flex-start', 'flex-end']}>
            <H5>Need some help?</H5>
            <Link to="/contact-us">
              <AccentButton title="Contact us" />
            </Link>
          </Box>
        </Flex>
      </Animation>
    </RightContainer>
  )
}
