import React from 'react'
import styled from '@emotion/styled'
import { FormGroup } from '@material-ui/core'
import { inject, observer } from 'mobx-react'

import { Theme, Mq } from '../styles/settings'
import { Button } from '../atoms/button'
import { TextInput } from '../atoms/input'
import { Animation } from '../atoms/animation'
import { Paragraph, H5 } from '../atoms/text'
import Password from '../atoms/password'
import ForgotPassword from './forgotPasswordForm'

export const CustomContainer = styled.main`
  H3 {
    padding-bottom: 1rem;
    margin-top: -10px;
  }

  p {
    max-width: 520px;
  }

  H5 {
    &.link {
      color: ${(props) => props.theme.colors.brandSecondary};
      transition: 0.2s ease-in-out;
      cursor: pointer;

      :hover {
        color: ${(props) => props.theme.colors.brandPrimary};
      }
    }
  }
`

export const CustomInput = styled.main`
  margin-bottom: 2rem;
`

export const CustomBox = styled.main`
  padding: 80px 1rem;

  ${Mq.md} {
    padding: 80px;
  }
`

export const CustomDiv = styled.main`
  display: block;
  padding: 32px 0 0 0;
  width: max-content;
  margin: auto;
  text-align: center;
`

const ErrorContainer = styled.div`
  display: block;
  text-align: center;
  margin-top: 24px;
  max-width: 700px;
  margin: auto;

  p {
    color: ${(props) => props.theme.colors.error};
    margin: 0 auto;
  }
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

class SignInForm extends React.Component {
  constructor() {
    super()
    this.state = {
      signIn: true,
      email: '',
      password: '',
    }
    this.showForgotPassword = this.showForgotPassword.bind(this)
    this.showSignIn = this.showSignIn.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  showForgotPassword() {
    this.setState({
      signIn: false,
    })
  }

  showSignIn() {
    this.setState({
      signIn: true,
    })
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  handleSignIn(e) {
    e.preventDefault()
    this.props.dataStore.signIn({ email: this.state.email, password: this.state.password })
  }

  render() {
    const { dataStore } = this.props

    return (
      <CustomContainer>
        <p>{dataStore.data}</p>
        {this.state.signIn === true ? (
          <FormGroup>
            <form
              onSubmit={(e) => {
                this.handleSignIn(e)
              }}
            >
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
              >
                <H5>Enter your email:</H5>
                <TextInput
                  placeholder=""
                  light={true}
                  value={this.state.email}
                  onChange={(e) => this.handleChange(e)}
                  email={true}
                  name="email"
                  type="email"
                  description="Enter your email address"
                />
              </Animation>

              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
              >
                <H5>Enter your password:</H5>
                <Password
                  placeholder=""
                  light={true}
                  value={this.state.password}
                  onChange={(e) => this.handleChange(e)}
                  name="password"
                  type="password"
                  description="Enter your email password"
                  signIn
                />
              </Animation>
              {dataStore.errors && dataStore.errors.length ? (
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
                >
                  <ErrorContainer>
                    <Paragraph style={{ color: Theme.colors.error }}>
                      The following error(s) have occurred:
                      {dataStore.errors &&
                        dataStore.errors.map((err, key) => <Paragraph>{err}</Paragraph>)}
                    </Paragraph>
                  </ErrorContainer>
                </Animation>
              ) : null}
              <CustomDiv>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
                >
                  <Button title="Sign in" className="button" type="submit" />
                </Animation>
              </CustomDiv>
            </form>
          </FormGroup>
        ) : (
          <ForgotPassword />
        )}

        <CustomDiv>
          {this.state.signIn === true ? (
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
            >
              <H5 className="link" onClick={this.showForgotPassword}>
                Forgot password?
              </H5>
            </Animation>
          ) : (
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
            >
              <H5 className="link" onClick={this.showSignIn}>
                Back to sign in
              </H5>
            </Animation>
          )}
        </CustomDiv>
      </CustomContainer>
    )
  }
}

export default inject('dataStore')(observer(SignInForm))
