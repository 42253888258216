import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

const Authenticated = (props) => {
  const { user } = props.dataStore
  const token = localStorage.getItem('token')

  useEffect(() => {
    props.dataStore.getCurrentUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [false])

  if (!token) {
    return <Redirect to="/sign-in" />
  }

  if (user) {
    return <>{props.children}</>
  }
  return <></>
}

export default inject('dataStore')(observer(Authenticated))
