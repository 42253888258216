import axios from 'axios'

export default async function myrxcallApi(method, path, data) {
  const token = localStorage.getItem('token')

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  }

  //   if (token) {
  //     headers['Authorization'] = `Token ${token}`
  //   }

  const noAuthHeader = [
    { method: 'post', path: 'users' },
    { method: 'post', path: 'login' },
  ]

  noAuthHeader.forEach((route) => {
    if (
      route.method.toLowerCase() === method.toLowerCase() &&
      route.path.toLowerCase() === path.toLowerCase()
    ) {
      delete headers['Authorization']
    }
  })

  return axios({
    method,
    url: process.env.REACT_APP_API_BASE_URL + path,
    data,
    headers,
  })
}
