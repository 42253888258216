import React from 'react'
import MetaTags from 'react-meta-tags'

import metaImage from '../assets/images/my-rx-call__meta-share.png'

import { SmallHero } from '../organisms/smallHero'
import { CtaSectionSmall } from '../molecules/ctaSection'
import LargeReview from '../molecules/largeReview'
import { SmallReviews } from '../organisms/smallReviews'

const Reviews = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Reviews</title>
        <meta
          name="description"
          content="MyRxCall - Unlimited personalized reminders via phone, text and email -- try us for 7 days free."
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription reminders for you - Safe, easy, & low cost."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallHero
        title="Reviews"
        reviewsHero="true"
        subTitle="MyRxCall is helping people all over the world solve their medication management problems –
        See for yourself what our customers have to say."
      />
      <LargeReview />
      <SmallReviews />
      <CtaSectionSmall headline="The most reliable way to manage medications" cta="About pricing" />
    </main>
  )
}

export default Reviews
