import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { FormGroup } from '@material-ui/core'
import { XOctagon } from 'react-feather'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { Theme, Mq } from '../styles/settings'
import { H3, H5, Paragraph } from '../atoms/text'
import { Animation } from '../atoms/animation'
import MembershipRow from '../atoms/membershipRow'
// import CreateMembershipForm from '../molecules/createMembershipForm'
import CreateBillingForm from '../molecules/createBillingForm'

const AccountMembershipDetailsContainer = styled.div``

const CustomHeader = styled.div`
  H5 {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }
  }
`

const FormContainer = styled.div`
  background: #fff;
  padding: 24px 0px 0px;
  ${Mq.md} {
    padding: 40px 0px;
  }
`

const StyledFormGroup = styled(FormGroup)`
  display: block !important;
`

const Alerts = styled.div`
  text-align: center;
  position: relative;
  max-width: 500px;
  margin-top: 20px;
`

const MembershipRows = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const ZeroState = styled.div`
  background: #fff;
  padding: 40px 32px;
  text-align: center;

  H3.alert {
    color: ${(props) => props.theme.colors.error};
  }

  p {
    margin: 1rem auto 3rem;
    font-size: ${(props) => props.theme.fontSize.large};
    max-width: 600px;
  }
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const AccountMembershipDetails = (props) => {
  const { dataStore, subscriptionStore } = props
  const { user } = dataStore
  const [subscription] = useState({})
  const BillingDate = moment
    .unix(dataStore.user.subscription && dataStore.user.subscription.current_period_end)
    .format('MM-DD-YYYY')

  useEffect(() => {
    return clearErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [false])

  const clearErrors = () => {
    subscriptionStore.errors = []
    subscriptionStore.messages = []
  }

  const subscriptionType = () => {
    const { user } = dataStore
    const subMap = {
      active: 'Active',
      canceled: 'Canceled',
    }
    return (user && user.subscription && subMap[user.subscription.status]) || 'Inactive'
  }

  return (
    <AccountMembershipDetailsContainer>
      <CustomHeader>
        <Animation
          initial="hidden"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
        >
          <H5>Membership details</H5>
        </Animation>
      </CustomHeader>
      <FormContainer>
        <StyledFormGroup>
          {user.subscription.status === 'canceled' ? (
            <ZeroState>
              <Animation
                initial="fadeIn"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
              >
                <XOctagon size={30} color={Theme.colors.error} />
                <H3 className="alert">You Need a Membership</H3>
                <Paragraph>
                  You don't have an active membership. In order to begin receiving alerts, please
                  add your billing information.
                </Paragraph>
                {/* {dataStore.user ? (
                  <CreateMembershipForm
                    subscription={subscription}
                    setSubscription={setSubscription}
                    reactivateMembership
                  />
                ) : null} */}
                {dataStore.user ? (
                  <CreateBillingForm subscription={subscription} reactivateBilling />
                ) : null}
              </Animation>
            </ZeroState>
          ) : (
            <Animation
              initial="fadeIn"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
            >
              <MembershipRows>
                <MembershipRow
                  title="Your plan:"
                  detail={subscriptionType()}
                  paddingRight="0px"
                  noEdit="true"
                />
                <MembershipRow
                  title="Payment:"
                  detail={'•••• •••• •••• ' + user.subscription.last_four}
                  paymentIcon="visa"
                  paddingRight="0px"
                  noEdit="true"
                />

                {/* WIP --- ICEBOX --- Add at later date
              <MembershipRow title="Payment History:" detail="" view="true" /> */}
                <MembershipRow
                  title={
                    user.subscription.status === 'active'
                      ? 'Next payment date'
                      : 'Membership active until'
                  }
                  detail={BillingDate}
                  paddingRight="0px"
                  noEdit="true"
                />

                <Alerts>
                  {subscriptionStore.errors.map((e, k) => (
                    <p style={{ color: 'red' }}>{e}</p>
                  ))}
                </Alerts>
              </MembershipRows>
            </Animation>
          )}
        </StyledFormGroup>
      </FormContainer>
    </AccountMembershipDetailsContainer>
  )
}

export default inject('subscriptionStore', 'dataStore')(observer(AccountMembershipDetails))
