import React, { useState } from 'react'
import styled from '@emotion/styled'
import { getUrlParameter } from '../util'
import { FormGroup } from '@material-ui/core'
import { CheckCircle } from 'react-feather'
import { Box } from '@rebass/emotion'

import { Theme } from '../styles/settings'
import { AccentButton } from '../atoms/button'
import Password from '../atoms/password'
import { Paragraph, H3, H5 } from '../atoms/text'
import { Animation } from '../atoms/animation'

import { inject, observer } from 'mobx-react'

export const CustomContainer = styled.main`
  H3 {
    padding-bottom: 0rem;
    margin-top: -10px;
  }

  p {
    max-width: 520px;
  }

  H5 {
    &.link {
      color: ${(props) => props.theme.colors.brandSecondary};
      transition: 0.2s ease-in-out;
      cursor: pointer;

      :hover {
        color: ${(props) => props.theme.colors.brandPrimary};
      }
    }
  }
`

export const CustomInput = styled.main`
  margin-bottom: 2rem;
`

const SuccessAlert = styled.div``

const Alerts = styled.div`
  position: relative;
  p {
    margin: 0;
    color: ${(props) => props.theme.colors.error};
    position: absolute;
    bottom: -96px;
    left: -25%;
    width: max-content;
  }
`

export const CustomBox = styled.main`
  padding: 80px;
`

export const ResetPasswordContainer = styled.div`
  margin: 40px 0 0px;
`

export const CustomDiv = styled.main`
  display: block;
  padding: 32px 0 0 0;
  width: max-content;
  margin: auto;
  text-align: center;
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const ForgotPasswordForm = (props) => {
  const { dataStore } = props
  const reset_token = getUrlParameter('reset_token')

  const [password, setPassword] = useState('')
  const [passwordConf, setPasswordConf] = useState('')

  const isEnabled = password.length > 0 && passwordConf.length > 0

  const handleSubmit = (e) => {
    e.preventDefault()
    if (password !== passwordConf) {
      dataStore.setErrors({ errors: ['Password and password confirmation must match'] })
      return
    }
    dataStore.resetPassword({ password, reset_token })
  }

  return (
    <CustomContainer>
      <Animation
        initial="hidden"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
      >
        <H3>Reset your password</H3>
      </Animation>
      <Animation
        initial="hidden"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
      >
        <Paragraph>Enter your new passwords below.</Paragraph>
      </Animation>
      <ResetPasswordContainer>
        <FormGroup onSubmit={(e) => handleSubmit(e)}>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
          >
            <H5>Enter your password:</H5>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
          >
            <Password
              light={true}
              type="password"
              name="password"
              description="Enter your password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              placeholder="At least 4 characters"
            />
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
          >
            <H5>Confirm your password:</H5>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
          >
            <Password
              light={true}
              type="password"
              name="password"
              description="Confirm your password"
              value={passwordConf}
              onChange={(e) => {
                setPasswordConf(e.target.value)
              }}
              placeholder="Confirm your password"
            />
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
          >
            <CustomDiv>
              <Alerts>
                {dataStore.success &&
                  dataStore.success.map((m, k) => (
                    <SuccessAlert>
                      <Animation
                        initial="fadeIn"
                        animate="visible"
                        exit={{ opacity: 0 }}
                        variants={motionVariants}
                        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
                      >
                        <CheckCircle color={Theme.colors.success} size={32} />
                      </Animation>
                    </SuccessAlert>
                  ))}
                {dataStore.errors.map((e, k) => (
                  <Animation
                    initial="fadeIn"
                    animate="visible"
                    exit={{ opacity: 0 }}
                    variants={motionVariants}
                    transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
                  >
                    <Box mt={4}>
                      <Paragraph>{e}</Paragraph>
                    </Box>
                  </Animation>
                ))}
              </Alerts>
              <AccentButton
                title="Reset password"
                disabled={!isEnabled}
                onClick={(e) => {
                  handleSubmit(e)
                }}
              />
            </CustomDiv>
          </Animation>
        </FormGroup>
      </ResetPasswordContainer>
    </CustomContainer>
  )
}

export default inject('dataStore')(observer(ForgotPasswordForm))
