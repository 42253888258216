import { decorate, observable, action } from 'mobx'

class UiStateStore {
  showModal = false
  showPrescriptionForm = false
  showMembershipModal = false
  passwordStrength = false
  emailValidity = false

  setShowModal = (val) => {
    this.showModal = val
  }

  setShowPrescriptionForm = (val) => {
    this.showPrescriptionForm = val
  }

  setShowMembershipModal = (val) => {
    this.showMembershipModal = val
  }

  setPasswordStrength = (val) => {
    this.passwordStrength = val
  }

  setEmailValidity = (val) => {
    this.emailValidity = val
  }
}

decorate(UiStateStore, {
  showModal: observable,
  showPrescriptionForm: observable,
  showMembershipModal: observable,
  passwordStrength: observable,
  emailValidity: observable,
  setShowModal: action.bound,
  setShowPrescriptionForm: action.bound,
  setShowMembershipModal: action.bound,
  setPasswordStrength: action.bound,
  setEmailValidity: action.bound,
})

export default new UiStateStore()
