import React from 'react'
import styled from '@emotion/styled'
import MetaTags from 'react-meta-tags'
import { Link } from 'react-router-dom'

import { Mq } from '../styles/settings'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import { SmallHero } from '../organisms/smallHero'
import { Mission } from '../organisms/mission'
import HowItWorks from '../molecules/howItWorks'
import { Animation } from '../atoms/animation'
import SupportForm from '../molecules/supportForm'
import { H2, H3, Paragraph } from '../atoms/text'
import { Container } from '../atoms/container'

const ReviewsContainer = styled.div`
  width: 100%;
`

const AboutUsHero = styled.div`
  background: ${(props) => props.theme.colors.brandSecondary};
  width: 100%;
  margin: 80px 0 -300px 0;
  padding: 80px 0 260px;

  H2 {
    color: ${(props) => props.theme.colors.white};
    margin: 0;
    padding: 0;
  }
`

const CtaRow = styled.div`
  width: 100%;
  padding: 4rem 0 0;

  p {
    padding-right: 2rem;
  }
  ${Mq.md} {
    padding: 8rem 0 0;
    p {
      max-width: 600px;
      padding-right: 0;
    }
  }
`

const StyledDivider = styled.div`
  height: 80px;
  padding: 20px;
`

const AboutUs = () => {
  const variants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – About us</title>
        <meta
          name="description"
          content="Introducing MyRxCall -  MyRxCall - is the most reliable way to manage medications. Receive unlimited personalized medication reminders via phone call, text message, or email."
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription Reminders For You - Safe, Easy, & Low Cost."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallHero
        title="About us"
        aboutUsHero="true"
        subTitle="MyRxCall - is the most reliable way to manage medications. Receive unlimited personalized medication reminders via phone call, text message, or email."
        paddingBottom="27px"
      />
      <Mission />
      <ReviewsContainer>
        <AboutUsHero>
          <Container>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
            >
              <H2>We can help!</H2>
            </Animation>
          </Container>
        </AboutUsHero>
        <HowItWorks />
        <CtaRow>
          <Container>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
            >
              <H3>Have questions?</H3>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
            >
              <Paragraph>
                Please see our <Link to="/help">FAQ</Link> or you can always send us an email at{' '}
                <a href="mailto:support@myrxcall.com">support@myrxcall.com</a> and a human will
                respond within 24 hours.
              </Paragraph>
            </Animation>
          </Container>
        </CtaRow>
      </ReviewsContainer>
      <SupportForm hideHeader="true" />
      <StyledDivider />
    </main>
  )
}

export default AboutUs
