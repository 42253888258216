import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { DollarSign } from 'react-feather'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import { Animation } from '../atoms/animation'
import { Mq } from '../styles/settings'
import pricing from '../assets/images/pricing.png'
import { Container } from '../atoms/container'
import { Button } from '../atoms/button'
import { H1, H3, Paragraph } from '../atoms/text'

const PricingContainer = styled.div`
  background: ${(props) => props.theme.colors.lightGrey};
  margin-top: 80px;
  padding: 24px 0;

  button {
    margin-top: 2rem;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  display: block;
  justify-content: flex-start;
  position: relative;
  margin-top: 3rem;
  margin-bottom: -3rem;

  img {
    z-index: 2;
  }
  ${Mq.md} {
    margin-top: -60px;
    margin-left: -11px;
  }
  ${Mq.sm} {
    flex-direction: row;
    display: flex;
    margin-bottom: -2rem;
  }
`

const ImageBackground = styled.div`
  ${Mq.md} {
    display: block;
    width: 490px;
    height: 330px;
    border: 0px;
    right: 32px;
    top: 40px;
    border-radius: 0px;
    background-size: cover;
    border-radius: 8px;
    margin-right: ${(props) => props.margin};
    z-index: 1;
  }
`

const PricingContent = styled.div`
  display: block;
  padding-left: 0;
  padding: 1.5rem;
  ${Mq.md} {
    padding-left: 5rem;
  }
`

const PricingTitle = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;

  svg {
    position: absolute;
    left: 0;
    top: 5px;
  }

  H1 {
    color: ${(props) => props.theme.colors.brandPrimary};
    padding-left: 20px;
  }

  H3 {
    padding-left: 10px;
    color: ${(props) => props.theme.colors.brandSecondary};
  }
`

const PricingList = styled.div`
  display: block;
  text-align: left;
  margin-left: 20px;

  p {
    line-height: 32px;
  }

  ul {
    list-stype-type: none;

    li:before {
      content: '–';
      position: absolute;
      margin-left: -20px;
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`

export function PricingDetails(props) {
  const variants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
    fadeInStart: {
      opacity: 0,
    },
    fadeInFinish: {
      opacity: 1,
    },
  }
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px',
  })

  return (
    <Container>
      <PricingContainer>
        <Flex alignItems="center" flexDirection={['column-reverse', 'row', 'row']}>
          <Box width={[1]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
            >
              <ImageContainer>
                <img src={pricing} alt="How It Works" />
                <ImageBackground />
              </ImageContainer>
            </Animation>
          </Box>
          <Box width={[1]}>
            <PricingContent>
              <Animation
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
              >
                <PricingTitle>
                  <DollarSign color="#3488F8" size="20" />
                  <H1>9.95</H1>
                  <H3>per month</H3>
                </PricingTitle>
              </Animation>
              <PricingList>
                <Paragraph>
                  <ul ref={ref}>
                    <Animation
                      initial="hidden"
                      animate={inView ? 'visible' : 'hidden'}
                      listItem="true"
                      variants={variants}
                      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
                    >
                      Unlimited reminders to simplify your life
                    </Animation>
                    <Animation
                      initial="hidden"
                      animate={inView ? 'visible' : 'hidden'}
                      listItem="true"
                      variants={variants}
                      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.7 }}
                    >
                      One low monthly rate, no hidden fees
                    </Animation>
                    <Animation
                      initial="hidden"
                      animate={inView ? 'visible' : 'hidden'}
                      listItem="true"
                      variants={variants}
                      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
                    >
                      Cancel your membership at any time
                    </Animation>
                    <Animation
                      initial="hidden"
                      animate={inView ? 'visible' : 'hidden'}
                      listItem="true"
                      variants={variants}
                      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.9 }}
                    >
                      Try us free for 7 days!
                    </Animation>
                  </ul>
                </Paragraph>
              </PricingList>
              <Animation
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 1.2 }}
              >
                <Link to="/get-started">
                  <Button title="Get started" />
                </Link>
              </Animation>
            </PricingContent>
          </Box>
        </Flex>
      </PricingContainer>
    </Container>
  )
}
