import styled from '@emotion/styled'
import { Box } from '@rebass/emotion'

import { Mq } from '../styles/settings'

export const Container = styled(Box)`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 100%;
  ${Mq.md} {
    max-width: 1300px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

export const SmallContainer = styled(Box)`
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  ${Mq.md} {
    max-width: 1000px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`
