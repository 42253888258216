import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { Hidden } from '@material-ui/core'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { Mq } from '../styles/settings'
import howItWorks from '../assets/images/howItWorks.png'
import { Container } from '../atoms/container'
import { Paragraph } from '../atoms/text'

const AboutContainer = styled.div`
  padding: 0;
  text-align: left;
  margin-top: 2rem;
  background: ${(props) => props.theme.colors.white};

  p {
    margin: 10px 0;
    padding: 0;
  }

  ${Mq.md} {
    padding: 80px 0;
    background: ${(props) => props.theme.colors.lightBlue};

    p {
      padding: 0 64px;
    }
  }
`
const ImageContainer = styled.div`
  display: none;

  ${Mq.md} {
    width: 100%;
    display: flex;
    align-items: bottom;
    justify-content: flex-end;
    position: relative;

    img {
      z-index: 2;
      margin-bottom: -124px;
    }
  }
`

const ImageBackground = styled.div`
  width: 530px;
  height: 320px;
  border: 0px;
  position: absolute;
  border-radius: 0px;
  background: ${(props) => props.theme.colors.brandTertiary};
  background-size: cover;
  border-radius: 8px;
  margin-right: ${(props) => props.margin};
  margin-top: -40px;
  margin-left: -48px;
  z-index: 1;
`

export function Mission(props) {
  const variants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    visible: {
      opacity: 1,
      y: '0',
    },
  }
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px',
  })
  return (
    <Container>
      <AboutContainer>
        <Flex alignItems="center" ref={ref}>
          <Hidden smDown>
            <Box width={[1]}>
              <motion.div
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={variants}
                transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
              >
                <ImageContainer>
                  <img src={howItWorks} alt="How It Works" />
                  <ImageBackground />
                </ImageContainer>
              </motion.div>
            </Box>
          </Hidden>
          <Box width={[1]} pt={4}>
            <motion.div
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 1, delay: 0.6 }}
            >
              <Paragraph>
                The number of Americans ages 65+ is projected to double by 2060. Healthcare and
                medicinal advancements are helping us live longer. That’s the good news! As we age
                however, we tend to forget things. While this is normal, it can also be dangerous
                and life threatening. It’s estimated that drug noncompliance rates range from 40% to
                75%. The most common forms of noncompliance are: overuse/abuse, forgetting to take
                the doses, and alteration of schedules.
              </Paragraph>
            </motion.div>
          </Box>
        </Flex>
      </AboutContainer>
    </Container>
  )
}
