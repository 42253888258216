import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'

import { Mq } from '../styles/settings'
import AccountDetailsForm from '../molecules/accountDetailsForm'
import MemberForm from '../molecules/memberForm'
import AccountNavigation from '../molecules/accountNavigation'

export const AccountDetailsPage = styled.div`
  position: relative;
  margin-top: 2rem;

  ${Mq.md} {
    margin-top: 0px;
  }
`

export const AccountDetailsContainer = styled.div`
  padding: 3rem 0rem 3rem;
  margin-top: 2rem;
  background-color: ${(props) => props.theme.colors.lightBlue};

  ${Mq.md} {
    padding: 80px;
  }
`

let AccountDetails = (props) => {
  const { user } = props.dataStore
  const [userForm, setUserForm] = useState({})
  const [edited, setEdited] = useState({})

  useEffect(() => {
    setUserForm(user)
    const timer = setTimeout(() => {
      setEdited(false)
    }, 3000)
    return () => clearTimeout(timer)

    // return () => clearTimeout(timer)
  }, [user, edited])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (userForm.patient) {
      userForm['patient_attributes'] = { ...userForm.patient }
    }
    props.dataStore.updateUser({ user: userForm })
  }

  return (
    <AccountDetailsPage>
      <AccountNavigation />
      <AccountDetailsContainer>
        <AccountDetailsForm user={userForm} setUser={setUserForm} handleSubmit={handleSubmit} />
        {user && user.patient ? (
          <MemberForm user={userForm} setUser={setUserForm} handleSubmit={handleSubmit} />
        ) : null}
      </AccountDetailsContainer>
    </AccountDetailsPage>
  )
}

AccountDetails = inject('dataStore')(observer(AccountDetails))

export { AccountDetails }
