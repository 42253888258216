import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import uiStateStore from '../stores/uiStateStore'

import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { strengthIndicator, strengthColor, strengthText } from '../atoms/passwordStrength'
import { H6 } from '../atoms/text'
import { Theme } from '../styles/settings'

const PasswordInputContainer = styled.div`
  p {
    margin: 0;
  }
`

const PasswordWrapper = styled.div`
  position: relative;

  H6.helper-text {
    position: absolute;
    right: 0;
    bottom: 4px;
    font-family: ${Theme.boldFont};
  }
`

const styles = () => ({
  root: {
    display: 'block',
    marginBottom: 0,
    marginTop: 0,
    padding: '0.5rem 0 0.5rem 1rem',
    height: 'calc(1.8em + 1rem + 2px)',
    zIndex: 2,
    background: Theme.colors.lightBlue,
  },
  textField: {
    background: Theme.colors.lightBlue,
    width: '100%',
    borderRadius: '0px',
  },
  textFieldLight: {
    background: Theme.colors.white,
    width: '100%',
    borderRadius: '0px',
  },
})

const StyledTextField = withStyles({
  root: {
    marginBottom: '2rem',
    width: '100%',
    '& label.Mui-focused': {
      borderWidth: '0px',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.error,
    },
    '& .MuiInput-underline:after': {
      borderWidth: '0px',
    },
    '& .MuiInput-underline:before': {
      borderWidth: '0px',
    },
    '& .MuiInputBase-input': {
      color: '#363B3D',
      padding: '0.5rem 0.5rem 0.5rem 1rem',
      fontSize: '18px',
      fontFamily: 'Campton',
      height: 'calc(1.8em + 1rem + 2px)',
      boxSizing: 'border-box',
      borderWidth: '0px',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderWidth: '0px',
    },
  },
})(TextField)

const SignInPassword = withStyles({
  root: {
    marginBottom: '2rem',
    width: '100%',
    '& label.Mui-focused': {
      borderWidth: '0px',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.error,
    },
    '& .MuiInput-underline:after': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:before': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.brandTertiary,
    },
    '& .MuiInputBase-input': {
      color: '#363B3D',
      padding: '0.5rem 0.5rem 0.5rem 1rem',
      fontSize: '18px',
      fontFamily: 'Campton',
      height: 'calc(1.8em + 1rem + 2px)',
      boxSizing: 'border-box',
      borderWidth: '0px',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: Theme.colors.brandPrimary,
    },
  },
})(TextField)

class PasswordFormField extends React.Component {
  constructor() {
    super()
    this.state = {
      password: '',
      showPassword: false,
      passwordStrength: false,
    }

    this.handlePasswordChanges = this.handlePasswordChanges.bind(this)
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value })
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }))
  }

  handlePasswordChanges(event) {
    this.setState({ password: event.target.value })
  }

  render() {
    const { classes } = this.props
    const { props, state } = this
    const Strength = strengthIndicator(props.value)
    const Color = strengthColor(Strength)
    const Helper = strengthText(Strength)

    return (
      <PasswordInputContainer>
        {props.error ? (
          <PasswordWrapper>
            <StyledTextField
              id={props.id}
              label={props.label}
              className={props.light ? classes.textFieldLight : classes.textField}
              autoComplete={props.AutoComplete}
              name={props.name}
              placeholder={props.placeholder}
              type={state.showPassword ? 'text' : 'password'}
              onChange={props.onChange}
              handleChanges={this.handlePasswordChanges}
              style={{ borderBottom: '2px solid' + props.error }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={props.value}
              fullWidth={true}
              disabled={props.disabled === 'true' ? true : null}
              error
            />
          </PasswordWrapper>
        ) : (
          <PasswordWrapper>
            {props.signIn ? (
              <SignInPassword
                id={props.id}
                label={props.label}
                className={props.light ? classes.textFieldLight : classes.textField}
                autoComplete={props.AutoComplete}
                name={props.name}
                placeholder={props.placeholder}
                type={state.showPassword ? 'text' : 'password'}
                onChange={props.onChange}
                handleChanges={this.handlePasswordChanges}
                style={{ borderBottom: '2px solid' + props.error }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={props.value}
                fullWidth={true}
                disabled={props.disabled === 'true' ? true : null}
              />
            ) : (
              <StyledTextField
                id={props.id}
                label={props.label}
                className={props.light ? classes.textFieldLight : classes.textField}
                autoComplete={props.AutoComplete}
                name={props.name}
                placeholder={props.placeholder}
                type={state.showPassword ? 'text' : 'password'}
                onChange={props.onChange}
                handleChanges={this.handlePasswordChanges}
                style={{
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '2px',
                  borderColor: props.signIn ? Theme.colors.brandTertiary : Color,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={props.value}
                fullWidth={true}
                disabled={props.disabled === 'true' ? true : null}
              />
            )}
            {props.signIn ? null : (
              <H6
                style={{
                  color: Color,
                }}
                className="helper-text"
              >
                {Helper}
              </H6>
            )}
            {props.signIn ? null : Strength === 4 ? uiStateStore.setPasswordStrength(true) : null}
          </PasswordWrapper>
        )}
        {props.formFieldError ? (
          <FormHelperText className="form-field__error" id="component-error-text">
            {props.formFieldError}
          </FormHelperText>
        ) : null}
      </PasswordInputContainer>
    )
  }
}

PasswordFormField.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(observer(PasswordFormField))
