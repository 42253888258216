import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import history from './stores/history'

import Home from './pages/home'
import HowItWorks from './pages/howItWorks'
import Pricing from './pages/pricing'
import Reviews from './pages/reviews'
import Help from './pages/help'
import AboutUs from './pages/aboutUs'
import Unsubscribe from './pages/unsubscribe'
import NoMatch from './pages/noMatch'
import TermsOfService from './pages/terms'
import PrivacyPolicy from './pages/privacyPolicy'
import SignIn from './pages/signIn'
import ResetPassword from './pages/resetPassword'

import Account from './pages/account'
import Membership from './pages/membership'
import Prescriptions from './pages/prescriptions'
import PatientIntake from './pages/patientIntake'
import CreateAccount from './pages/createAccount'
import ForgotPassword from './pages/forgotPassword'
import Contact from './pages/contact'
import SupportRequest from './pages/supportRequest'

import Navigation from './organisms/navigation'
import { Footer } from './organisms/footer'

import Authenticated from './Authenticated'

const Routes = (props) => {
  return (
    <Router history={history}>
      <Switch>
        {/* Public Pages */}
        <Route exact path="/">
          <Navigation white={true} />
          <Home />
        </Route>
        <Route exact path="/how-it-works">
          <Navigation color={true} />
          <HowItWorks {...props} />
        </Route>
        <Route exact path="/pricing">
          <Navigation color={true} />
          <Pricing />
        </Route>
        <Route exact path="/reviews">
          <Navigation color={true} />
          <Reviews />
        </Route>
        <Route exact path="/help">
          <Navigation color={true} />
          <Help />
        </Route>
        <Route exact path="/contact">
          <Navigation color={true} />
          <Contact />
        </Route>
        <Route exact path="/support-request">
          <Navigation color={true} />
          <SupportRequest />
        </Route>
        <Route exact path="/sign-in">
          <Navigation white={true} />
          <SignIn />
        </Route>
        <Route exact path="/about-us">
          <Navigation color={true} />
          <AboutUs />
        </Route>
        <Route exact path="/get-started">
          <Navigation white={true} />
          <CreateAccount />
        </Route>
        <Route exact path="/create-account">
          <Navigation white={true} />
          <CreateAccount />
        </Route>
        <Route exact path="/terms-of-service">
          <Navigation color={true} />
          <TermsOfService />
        </Route>
        <Route exact path="/unsubscribe">
          <Navigation color={true} />
          <Unsubscribe />
        </Route>
        <Route exact path="/404">
          <Navigation color={true} />
          <NoMatch />
        </Route>
        <Route exact path="/privacy-policy">
          <Navigation color={true} />
          <PrivacyPolicy />
        </Route>
        <Route exact path="/forgot-password">
          <Navigation white={true} />
          <ForgotPassword />
        </Route>
        <Route exact path="/reset-password">
          <Navigation white={true} />
          <ResetPassword />
        </Route>

        {/* WIP --- ICEBOX --- Make this work for authenticated users too
        404 page */}
        {/* <Route>
          <Navigation color={true} />
          <NoMatch />
        </Route> */}

        {/* Logged in pages */}
        <Authenticated>
          <Route exact path="/prescriptions">
            <Navigation white={true} />
            <Prescriptions />
          </Route>
          <Route exact path="/account">
            <Navigation white={true} />
            <Account />
          </Route>
          <Route exact path="/patient-intake">
            <Navigation white={true} />
            <PatientIntake />
          </Route>
          <Route exact path="/membership">
            <Navigation white={true} />
            <Membership />
          </Route>
        </Authenticated>
      </Switch>
      <Footer />
    </Router>
  )
}

export default Routes
