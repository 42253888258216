import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { makeStyles } from '@material-ui/core/styles'
import { FormGroup } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import Loader from 'react-loader-spinner'

import { Theme, Mq } from '../styles/settings'
import { Animation } from '../atoms/animation'
import { H2, H4, Paragraph } from '../atoms/text'
import { CustomCheckbox } from '../atoms/customCheckbox'
import { AccentButton } from '../atoms/button'
import { Dropdown } from '../atoms/dropdown'
import { DosageInput, TimePicker, TextInput } from '../atoms/input'
import { frequencyOptions, ordinals } from '../constants'

var moment = require('moment-timezone')

const PrescriptionFormContainer = styled.div``

const FormContainer = styled.div`
  padding: 0;
  position: relative;

  p {
    margin-bottom: 16px;
    padding: 16px 0 0 0;
    font-size: 1.2rem;
  }

  H2 {
    text-align: center;
  }

  Button {
    margin-bottom: 0px;
    margin-left: auto;
    z-index: 3;
  }
`

const AlertContainer = styled.div`
  display: block;
  text-align: right;
  margin-top: 24px;

  p {
    color: ${(props) => props.theme.colors.error};
    margin: 0;
  }
`

const PrescriptionHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  H2 {
    padding-right: 30px;
    text-transform: capitalize;
    display: flex;
    min-width: 72%;
    margin: 0 auto 0 0;
    white-space: nowrap;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    ${Mq.md} {
      max-width: 80%;
      min-width: max-content;
    }
  }

  H4 {
    text-align: right;
    padding-left: 13px;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  svg {
    margin-left: 32px;
  }

  ${Mq.md} {
    H4 {
      padding-left: 32px;
    }
  }
`

const StyledFlex = styled(Flex)`
  display: block;

  ${Mq.md} {
    display: flex;
  }
`

const DividerLine = styled.div`
  display: none;
  ${Mq.md} {
    display: initial;
    min-width: 170px;
    height: 2px;
    margin-bottom: 7px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.brandTertiary};
    min-width: 20px;
  }
`

const Checkboxes = styled.div`
  color: #000;
  position: relative;
  display: block;

  ${Mq.md} {
    display: block;
  }
`
const LoadingContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -4rem;
  p {
    color: ${(props) => props.theme.colors.error};
    margin: 0;
  }
`

const DropdownWrapper = styled.div`
  max-width: 100%;
  width: 100%;
`

const Buttons = styled(Box)`
  position: relative;
  display: flex;
  margin-top: 3rem;
  justify-content: center;

  ${Mq.md} {
    bottom: 8px;
    margin-top: -3rem;
    justify-content: flex-end;
  }
`

const useStyles = makeStyles((theme) => ({
  root: {},
  textField: {
    background: 'white',
    width: '100%',
    margin: '0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  margin: {
    margin: '0',
    width: '100%',
    border: '0px',
    borderRadius: '0px',
  },
}))

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const PrescriptionForm = (props) => {
  const classes = useStyles()
  const { dataStore } = props
  const { user } = dataStore
  const isCaregiverAccount = user.patient ? true : false

  const [prescriptionTimes, setPrescriptionTimes] = useState([{}])
  const [prescriptionTitle, setPrescriptionTitle] = useState('')
  const [prescriptionDosage, setPrescriptionDosage] = useState('')
  const [phone, setPhone] = useState(false)
  const [email, setEmail] = useState(false)
  const [sms, setSms] = useState(false)

  const [state, setState] = useState({
    edited: false,
  })

  const isChecked = sms === true || email === true || phone === true

  const hasPrescriptionTimes = () =>
    prescriptionTimes.length > 0 && prescriptionTimes[0] && prescriptionTimes[0].time.length

  const isEnabledNew =
    prescriptionTitle.length > 0 &&
    prescriptionDosage.length > 0 &&
    isChecked &&
    hasPrescriptionTimes()

  const isEnabledUpdate = state.edited === true

  useEffect(() => {
    if (props.prescription) {
      setPrescriptionTitle(props.prescription.title)
      setPrescriptionDosage(props.prescription.dosage)
      setPhone(props.prescription.phone_notification)
      setEmail(props.prescription.email_notification)
      setSms(props.prescription.sms_notification)
      const updated = props.prescription.prescription_times.map((time) => {
        const timezone = isCaregiverAccount ? user.patient.timezone : user.timezone
        const pTime = moment.utc(time.time).tz(timezone).format('HH:mm A')
        console.log(pTime)
        return {
          time: pTime,
          order: time.order,
          id: time.id,
        }
      })
      setPrescriptionTimes(updated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prescription])

  const handleDropdownChange = (e) => {
    if (e.value === prescriptionTimes.length) {
      return
    } else if (e.value > prescriptionTimes.length) {
      const diff = e.value - prescriptionTimes.length
      let empty = []
      for (let i = 0; i < diff; i++) {
        empty.push({})
      }
      setPrescriptionTimes([...prescriptionTimes, ...empty])
    } else if (e.value < prescriptionTimes.length) {
      setPrescriptionTimes(prescriptionTimes.slice(0, e.value))
    }
    setState({ edited: true })
  }

  const handleTimePickerChange = (event, index) => {
    const mod = prescriptionTimes[index]
    mod['time'] = event.target.value
    mod['order'] = index
    setPrescriptionTimes([
      ...prescriptionTimes.slice(0, index),
      mod,
      ...prescriptionTimes.slice(index + 1, prescriptionTimes.length),
    ])
    setState({ edited: true })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.dataStore.createPrescription({
      title: prescriptionTitle,
      dosage: prescriptionDosage,
      phoneNotification: phone,
      emailNotification: email,
      smsNotification: sms,
      prescriptionTimes: prescriptionTimes.map((el, i) => {
        const timezone = isCaregiverAccount ? user.patient.timezone : user.timezone
        const dateString = moment(el.time, 'HH:mm A').format('YYYY-MM-DD HH:mm')
        const time = moment.tz(dateString, timezone).format()
        return { time, order: i }
      }),
      uiStateStore: props.uiStateStore,
    })
    setState({ edited: true })
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    let modPrescriptionTimes = prescriptionTimes.map((el, i) => {
      const timezone = isCaregiverAccount ? user.patient.timezone : user.timezone
      const dateString = moment(el.time, 'HH:mm A').format('YYYY-MM-DD HH:mm')
      const time = moment.tz(dateString, timezone).format()
      return { time, order: i, id: el.id }
    })
    setState({ edited: true })
    props.prescription.prescription_times.forEach((time) => {
      if (!modPrescriptionTimes.find((el) => el.id === time.id)) {
        modPrescriptionTimes.push({ id: time.id, _delete: 1, _destroy: 1 })
      }
    })
    props.dataStore.updatePrescription({
      id: props.prescription.id,
      title: prescriptionTitle,
      dosage: prescriptionDosage,
      phoneNotification: phone,
      emailNotification: email,
      smsNotification: sms,
      prescriptionTimes: modPrescriptionTimes,
      uiStateStore: props.uiStateStore,
    })
  }

  return (
    <PrescriptionFormContainer className={classes.root}>
      <FormContainer>
        {props.newPrescription ? null : (
          <Animation
            initial="fadeIn"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
          >
            <PrescriptionHeader>
              <H2>{props.prescription.title}</H2>
              <DividerLine />
              <H4
                onClick={() => {
                  props.dataStore.deletePrescription({
                    prescription: props.prescription,
                    uiStateStore: props.uiStateStore,
                  })
                }}
              >
                Remove
              </H4>
            </PrescriptionHeader>
          </Animation>
        )}

        {props.additionalPrescription ? (
          <Animation
            initial="fadeIn"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
          >
            <PrescriptionHeader>
              <H2>Add Another Prescription</H2>
              <DividerLine />
              <H4
                onClick={(e) => {
                  props.uiStateStore.setShowPrescriptionForm(false)
                }}
              >
                Cancel
              </H4>
            </PrescriptionHeader>
          </Animation>
        ) : null}
        <Animation
          initial="fadeIn"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
          <FormGroup>
            {/* ~~~~~~~~~~~~~~~~~~ SELECT PRESCRIPTION FROM LIST ~~~~~~~~~~~~~~~~~~ */}
            {props.newPrescription || props.additionalPrescription ? (
              <DropdownWrapper>
                <Paragraph>What is the name of this prescription?</Paragraph>
                <TextInput
                  name="email"
                  onChange={(e) => setPrescriptionTitle(e.target.value)}
                  placeholder="Add the full name here"
                  description="Prescription name"
                  value={prescriptionTitle}
                  maxLength="35"
                />
                {/* <Dropdown
                  placeholder="Search for a prescription"
                  options={drugs}
                  onChange={(e) => setPrescriptionTitle(e.value)}
                /> */}
              </DropdownWrapper>
            ) : null}

            {/* ~~~~~~~~~~~~~~~~~~ DOSAGE FREQUENCY ~~~~~~~~~~~~~~~~~~ */}
            <DropdownWrapper>
              <Paragraph>How many times per day do you take this prescription?</Paragraph>
              <Dropdown
                onChange={(e) => {
                  handleDropdownChange(e)
                }}
                value={frequencyOptions[prescriptionTimes.length - 1]}
                placeholder="Make a selection here"
                options={frequencyOptions}
              />
            </DropdownWrapper>

            {/* ~~~~~~~~~~~~~~~~~~ DOSAGE TIMES ~~~~~~~~~~~~~~~~~~ */}
            <Paragraph>What time do you take it?</Paragraph>
            <Flex width="auto" mx={-2} mb={3} flexDirection={['column', 'column', 'row']}>
              {prescriptionTimes.map((el, index) => {
                return (
                  <Box
                    width={[1, 1, 1 / 2]}
                    my={2}
                    pr={2}
                    pl={2}
                    key={index}
                    style={{ display: 'block' }}
                  >
                    <TimePicker
                      id="time"
                      type="time"
                      defaultValue={el.time}
                      value={el.time}
                      placeholder="Ex: 04:00 PM"
                      label={`${ordinals[index]} time`}
                      className={classes.textField}
                      onChange={(e) => {
                        handleTimePickerChange(e, index)
                      }}
                    />
                  </Box>
                )
              })}
            </Flex>

            {/* ~~~~~~~~~~~~~~~~~~ PRESCRIPTION DOSAGE ~~~~~~~~~~~~~~~~~~ */}
            <Checkboxes>
              <Flex width="100%" mb={3}>
                <Box width={1}>
                  <Paragraph>What is the dosage of your prescription?</Paragraph>

                  <FormControl className={clsx(classes.margin, classes.textField)} variant="filled">
                    <DosageInput
                      id="filled-adornment-milligrams"
                      placeholder="Enter in milligrams"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4)
                      }}
                      min={0}
                      aria-describedby="dosage-in-milligrams"
                      value={prescriptionDosage}
                      onChange={(e) =>
                        setPrescriptionDosage(e.target.value) || setState({ edited: true })
                      }
                    />
                  </FormControl>
                </Box>
              </Flex>

              {/* ~~~~~~~~~~~~~~~~~~ ALERT PREFERENCES ~~~~~~~~~~~~~~~~~~ */}
              <Flex width="100%">
                <Box width={1}>
                  <Paragraph>How would you like to receive alerts?</Paragraph>
                  <FormControl>
                    <StyledFlex alignItems="center">
                      <Box mr={3}>
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={phone}
                              onChange={() => setPhone(!phone) || setState({ edited: true })}
                              name="phone"
                              color="primary"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                          }
                          label="Phone"
                        />
                      </Box>
                      <Box mr={3}>
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={sms}
                              onChange={() => setSms(!sms) || setState({ edited: true })}
                              name="sms"
                              color="primary"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                          }
                          label="SMS"
                        />
                      </Box>
                      <Box mr={3}>
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={email}
                              onChange={() => setEmail(!email) || setState({ edited: true })}
                              name="email"
                              color="primary"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                          }
                          label="Email"
                        />
                      </Box>
                    </StyledFlex>
                  </FormControl>
                </Box>
              </Flex>
            </Checkboxes>
            <Box>
              <Buttons>
                {dataStore.isLoading ? (
                  <LoadingContainer>
                    <Loader
                      type="BallTriangle"
                      color={Theme.colors.brandPrimary}
                      height={50}
                      width={50}
                    ></Loader>
                  </LoadingContainer>
                ) : null}
                {props.updatePrescription ? (
                  <AccentButton
                    title="Update"
                    disabled={!isEnabledUpdate}
                    onClick={(e) => {
                      handleUpdate(e)
                    }}
                  />
                ) : null}
                {props.newPrescription ? (
                  <AccentButton
                    title="Add this prescription"
                    disabled={!isEnabledNew}
                    onClick={(e) => {
                      handleSubmit(e)
                    }}
                  />
                ) : null}
              </Buttons>
            </Box>
            {dataStore.errors && dataStore.errors.length ? (
              <AlertContainer>
                <Paragraph style={{ color: Theme.colors.error }}>
                  The following error(s) have occurred:
                  {dataStore.errors &&
                    dataStore.errors.map((err, key) => <Paragraph>{err}</Paragraph>)}
                </Paragraph>
              </AlertContainer>
            ) : null}
          </FormGroup>
        </Animation>
      </FormContainer>
    </PrescriptionFormContainer>
  )
}

export default inject('dataStore', 'uiStateStore')(observer(PrescriptionForm))
