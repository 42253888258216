import React from 'react'
import styled from '@emotion/styled'

import { Mq } from '../styles/settings'
import { Container } from '../atoms/container'
import { H2 } from '../atoms/text'
import { Animation } from '../atoms/animation'
import { HowItWorksOne, HowItWorksTwo, HowItWorksThree } from '../molecules/howItWorksSection'

const CustomContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 0;

  H2.title {
    text-align: center;
  }
  ${Mq.md} {
    padding-top: 100px;
    padding-bottom: 40px;
    padding-left: 40px;

    H2.title {
      text-align: left;
      margin-left: 3.5rem;
    }
  }
`

export function HowItWorks() {
  const variants = {
    hidden: {
      opacity: 0,
      y: '20px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <CustomContainer>
      <Container>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
        >
          <H2 className="title">How it works</H2>
        </Animation>

        <HowItWorksOne />
        <HowItWorksTwo />
        <HowItWorksThree />
      </Container>
    </CustomContainer>
  )
}
