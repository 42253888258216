import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { Link } from 'react-router-dom'

import { Animation } from '../atoms/animation'
import { Mq } from '../styles/settings'
import { Container } from '../atoms/container'
import { TertiaryButton } from '../atoms/button'
import { H1 } from '../atoms/text'

const CtaContainer = styled.div`
  background: ${(props) => props.theme.colors.brandSecondary};
  padding: 4rem 0;
  text-align: center;
  margin-top: 4rem;

  H1 {
    color: ${(props) => props.theme.colors.white};
    max-width: 480px;
    margin: auto;
    text-align: center;
    margin-bottom: 40px;
  }
  ${Mq.md} {
    padding: 100px 0;
    margin-top: 80px;
  }
`

const CtaContainerSmall = styled.div`
  background: ${(props) => props.theme.colors.brandSecondary};
  padding: 4rem 0;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1300px;
  margin: 80px auto;

  H1 {
    color: ${(props) => props.theme.colors.white};
    max-width: 480px;
    margin: auto;
    text-align: center;
    margin-bottom: 40px;
  }

  button.brand-button.brand-button--tertiary {
    margin: auto;
  }
  ${Mq.md} {
    padding: 100px 0;
  }
`

export function CtaSection(props) {
  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <CtaContainer>
      <Container>
        <Flex flexWrap="wrap">
          <Box width={[1]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
            >
              <H1>{props.headline}</H1>
            </Animation>
          </Box>

          <Box width={[1]} margin="auto" justifyContent="center">
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
            >
              <Link to="/pricing">
                <TertiaryButton title={props.cta} />
              </Link>
            </Animation>
          </Box>
        </Flex>
      </Container>
    </CtaContainer>
  )
}

export function CtaSectionSmall(props) {
  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <Container>
      <CtaContainerSmall>
        <Flex flexWrap="wrap">
          <Box width={[1]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
            >
              <H1>{props.headline}</H1>
            </Animation>
          </Box>

          <Box width={[1]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
            >
              <Link to={props.link ? props.link : '/pricing'}>
                <TertiaryButton title={props.cta} />
              </Link>
            </Animation>
          </Box>
        </Flex>
      </CtaContainerSmall>
    </Container>
  )
}
