import React from 'react'
import { Box, Flex } from '@rebass/emotion'

import hs1 from '../assets/images/hs1.jpg'
import hs2 from '../assets/images/hs2.jpg'
import hs3 from '../assets/images/hs3.jpg'
import hs4 from '../assets/images/hs4.jpg'
import hs5 from '../assets/images/hs5.jpg'
import hs6 from '../assets/images/hs6.jpg'
import hs7 from '../assets/images/hs7.jpg'
import hs8 from '../assets/images/hs8.jpg'
import hs10 from '../assets/images/hs10.jpg'
import hs12 from '../assets/images/hs12.jpg'

import { Animation } from '../atoms/animation'
import { Container } from '../atoms/container'
import { Review } from '../atoms/review'

export function SmallReviews(props) {
  const variants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Container>
      {props.limit ? (
        <Flex
          width="auto"
          mt={[3, 3, 6]}
          ml={-3}
          mr={-3}
          mb={4}
          flexDirection={['column', 'column', 'row']}
        >
          <Animation
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
          >
            <Box my={3} pl={3} pr={3}>
              <Review
                quote="I’m very forgetful and MyRxCall has made it so easy for me to remember to take my medications."
                name="Greg"
                image={hs6}
                location="Henderson, NV"
              />
            </Box>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
          >
            <Box my={3} pl={3} pr={3}>
              <Review
                quote="My elderly parents rely on multiple medications. I now have peace of mind knowing they’re taking their meds."
                name="Jack"
                image={hs2}
                location="Buckhead, GA"
              />
            </Box>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
          >
            <Box my={3} pl={3} pr={3}>
              <Review
                quote="So long are the days of forgetting to take my medication...MyRxCall is amazing!!!"
                name="Janice"
                image={hs3}
                location="Paris, TX"
              />
            </Box>
          </Animation>
        </Flex>
      ) : (
        <>
          <Flex
            width="auto"
            mt={[3, 3, 6]}
            ml={-3}
            mr={-3}
            mb={3}
            flexDirection={['column', 'column', 'row']}
          >
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
            >
              <Box width={1} my={3} pl={3} pr={3}>
                <Review
                  quote="I’m very forgetful and MyRxCall has made it so easy for me to remember to take my medications."
                  name="Greg"
                  image={hs6}
                  location="Henderson, NV"
                />
              </Box>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
            >
              <Box width={1} my={3} pl={3} pr={3}>
                <Review
                  quote="My elderly parents rely on multiple medications. I now have peace of mind knowing they’re taking their meds."
                  name="Jack"
                  image={hs5}
                  location="Buckhead, GA"
                />
              </Box>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
            >
              <Box width={1} my={3} pl={3} pr={3}>
                <Review
                  quote="So long are the days of forgetting to take my medication...MyRxCall is amazing!!!"
                  name="Janice"
                  image={hs8}
                  location="Paris, TX"
                />
              </Box>
            </Animation>
          </Flex>
          <Flex width="auto" ml={-3} mr={-3} mb={3} flexDirection={['column', 'column', 'row']}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
            >
              <Box width={1} my={3} pl={3} pr={3}>
                <Review
                  quote="My wife & I both use MyRxCall, she likes the SMS reminders, I prefer the phone call. Love having options!"
                  name="Gerald"
                  image={hs1}
                  location="Highland Park, IL"
                />
              </Box>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
            >
              <Box width={1} my={3} pl={3} pr={3}>
                <Review
                  quote="I am not very tech savvy, but MyRxCall is SO simple. Thank you for making it easy and affordable!"
                  name="Alexis"
                  image={hs12}
                  location="Tulsa, CA"
                />
              </Box>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 1.0 }}
            >
              <Box width={1} my={3} pl={3} pr={3}>
                <Review
                  quote="Keeps me in check, don’t have to worry about missing a dose."
                  name="Ronald"
                  image={hs2}
                  location="Buffalo, NY"
                />
              </Box>
            </Animation>
          </Flex>
          <Flex width="auto" ml={-3} mr={-3} flexDirection={['column', 'column', 'row']}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 1.2 }}
            >
              <Box width={1} my={3} pl={3} pr={3}>
                <Review
                  quote="I have reminders set up for my Dad and I get an alert so I can keep track of when he takes his meds. Very helpful"
                  name="Harry"
                  image={hs4}
                  location="Minneapolis, MN"
                />
              </Box>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 1.4 }}
            >
              <Box width={1} my={3} pl={3} pr={3}>
                <Review
                  quote="I’m SO HAPPY I found MyRxCall!!! Simple and effective, just the way I like it!!! THANK YOU!!!"
                  name="Christy"
                  image={hs10}
                  location="Ann Arbor, MI"
                />
              </Box>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 1.6 }}
            >
              <Box width={1} my={3} pl={3} pr={3}>
                <Review
                  quote="Taking daily meds are new to me. I’m so grateful I found MyRxCall to help me make it a part of my routine."
                  name="Hannah"
                  image={hs7}
                  location="Portland, OR"
                />
              </Box>
            </Animation>
          </Flex>
        </>
      )}
    </Container>
  )
}
