import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Flex, Box } from '@rebass/emotion'
import { Hidden } from '@material-ui/core'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { Animation } from '../atoms/animation'
import { Theme, Mq } from '../styles/settings'
import { AccentButton } from '../atoms/button'
import { H2, H3, H5, Paragraph } from '../atoms/text'
import Prescription from '../atoms/prescription'
import PrescriptionForm from '../molecules/prescriptionForm'

export const AddPrescriptionContainer = styled.div`
  margin-top: 2rem;
  padding: 0 1.5rem 1px;
  background-color: ${(props) => props.theme.colors.lightBlue};

  ${Mq.md} {
    padding: 0 4rem 1px;
  }
`

export const AddPrescriptionHeader = styled.div`
  margin-bottom: 40px;

  H2 {
    padding-right: 32px;
    min-width: max-content;
  }

  p {
    max-width: 600px;

    &span {
      font-family: ${(props) => props.theme.boldFont};
      text-transform: capitalize;
      display: flex;
    }
  }
`

const PrescriptionCta = styled.div`
  background: #fff;
  padding: 80px 2rem;
  display: flex;
  width: 100%;
  text-align: center;
  margin: 4rem auto;
  flex-direction: column;

  H5 {
    max-width: 300px;
    margin: 24px auto 0;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  p {
    max-width: 480px;
    font-size: 20px;
    margin: auto;
    padding: 0 0 40px;

    &.error {
      color: ${(props) => props.theme.colors.error};
      font-size: 16px;
      margin-top: 2rem;
      padding: 0;
      margin-bottom: 0;
    }
  }

  Button {
    margin-bottom: 2rem;
    width: max-content;
    margin: auto;
  }

  ${Mq.md} {
    padding: 80px 0;
  }
`

const CustomTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-top: 3rem;

  H2 {
    margin: 0;
    min-width: max-content;
    padding-right: 1rem;
  }

  &.additional {
    margin-top: 0rem;
  }
  ${Mq.md} {
    padding-top: 5rem;
  }
`

const DividerLine = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.brandTertiary};
`

const CustomIntroduction = styled.div`
  p {
    margin-bottom: 2rem;
    max-width: 320px;
    ${Mq.md} {
      max-width: 640px;
    }
  }
`

const ErrorContainer = styled.div`
  display: block;
  text-align: center;
  margin-top: 24px;
  max-width: 700px;
  margin: auto;

  p {
    color: ${(props) => props.theme.colors.error};
    margin: 0;
  }
`

const NewPrescription = () => {
  return (
    <>
      <CustomTitle className="additional">
        <H2>Add Another Prescription</H2>
        <DividerLine />
      </CustomTitle>
      <PrescriptionForm newPrescription="true" />
    </>
  )
}

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

class AddPrescription extends React.Component {
  state = {
    prescription: [],
    newPrescription: '',
  }

  addPrescription = () => {
    this.setState({
      prescription: [...this.state.prescription, <NewPrescription />],
      newPrescription: true,
      showPrescriptionForm: false,
    })
  }

  render() {
    const { dataStore, uiStateStore } = this.props
    const { showPrescriptionForm, setShowPrescriptionForm } = uiStateStore

    return (
      <AddPrescriptionContainer>
        <Animation
          initial="hidden"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
        >
          <AddPrescriptionHeader>
            <CustomTitle>
              <H2>Patient intake form</H2>
              <DividerLine />
            </CustomTitle>
          </AddPrescriptionHeader>
        </Animation>
        {/* WIP --- ICEBOX –-– Add field to select # of prescriptions and display that # conditionally

        <PrescriptionsNumber>
          <H5>How many prescriptions are you registering with us?</H5>
          <Flex justify-content="space-between">
            <Box width={1 / 2}>
              <TextInput
                placeholder="How many prescriptions?"
                light={true}
                value={this.state.prescriptionAmount}
                onChange={(e) => this.handleAddPrescription(e)}
                // email={true}
                name="prescriptionAmount"
                type="number"
                description="How many prescriptions are you registering with us?"
              />
            </Box>
            <Box width={1 / 2} textAlign="right">
              <AccentButton title="Submit" disabled={!isEnabled} onClick={this.showPrescriptions} />
            </Box>
          </Flex>
        </PrescriptionsNumber> */}
        <section id="back-to-top"></section>
        <Animation
          initial="fadeIn"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
        >
          <CustomIntroduction>
            <Paragraph>
              Please fill out the intake form for the member.
              <Hidden smDown>
                <br />
              </Hidden>
              {/* WIP  Make this show prescribers name not users namenamed:{' '}
              <span style={{ textTransform: 'capitalize' }}>
                <strong>

                  {dataStore.user.first_name} {dataStore.user.last_name}
                </strong>
              </span>
               */}
              Notifications to the member will begin delivering once complete.
            </Paragraph>
          </CustomIntroduction>
        </Animation>

        {dataStore.prescriptions.length > 0 ? (
          <Flex ml={-2} mr={-2} flexWrap="wrap">
            {dataStore.prescriptions.map((prescription, index) => {
              return (
                <Box width={[1, 1 / 2, 1 / 2]} pl={2} pr={2}>
                  <Prescription prescription={prescription} />
                </Box>
              )
            })}
          </Flex>
        ) : (
          <PrescriptionForm prescriptionName="First prescription" newPrescription="true" />
        )}
        <section id="newprescription"></section>
        {showPrescriptionForm === true ? (
          <Box mt={4} width="100%">
            <PrescriptionForm
              prescriptionName="Additional prescription"
              newPrescription="true"
              additionalPrescription="true"
            />
          </Box>
        ) : null}

        {dataStore.errors && dataStore.errors.length ? (
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
          >
            <ErrorContainer>
              <Paragraph style={{ color: Theme.colors.error }}>
                The following error(s) have occurred:
                {dataStore.errors &&
                  dataStore.errors.map((err, key) => <Paragraph>{err}</Paragraph>)}
              </Paragraph>
            </ErrorContainer>
          </Animation>
        ) : null}
        <Animation
          initial="fadeIn"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
        >
          <PrescriptionCta>
            <H3>Add prescriptions or finish</H3>
            <Paragraph>
              Would you like to add any additional prescriptions to the list, or are you finished?
            </Paragraph>
            <AnchorLink href="#newprescription">
              <AccentButton
                title="Add another"
                onClick={() => {
                  setShowPrescriptionForm(true)
                }}
              />
            </AnchorLink>
            {dataStore.prescriptions.length > 0 ? (
              <Link to="/prescriptions">
                <H5>Finish adding prescriptions</H5>
              </Link>
            ) : (
              <Paragraph className="error">
                Please add a prescription so that you can proceed.
              </Paragraph>
            )}
          </PrescriptionCta>
        </Animation>
      </AddPrescriptionContainer>
    )
  }
}

export default inject('dataStore', 'uiStateStore')(observer(AddPrescription))
