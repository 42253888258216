import React from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import InputMask from 'react-input-mask'
import RenderInBrowser from 'react-render-in-browser'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

import { Theme } from '../styles/settings'
import { H4, H5, H6 } from '../atoms/text'

const TextInputContainer = styled.div`
  position: relative;

  H5.link {
    margin: 0;
    text-align: right;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
`
const InputTitle = styled.div`
  position: absolute;
  left: 0;
  z-index: 9;
  bottom: 32px;
  H4 {
    color: ${(props) => props.theme.colors.black};
  }
`
const InputWrapper = styled.div`
  position: relative;

  H6.helper-text {
    position: absolute;
    right: 0;
    bottom: 4px;
    font-family: ${Theme.boldFont};
  }
`

// ~~~~~~~~~~~~~~~~~ MATERIAL UI FORM STYLING ~~~~~~~~~~~~~~~~~

const useStyles = makeStyles((theme) => ({
  root: {
    color: Theme.colors.brandPrimary,
    '&$checked': {
      color: Theme.colors.brandPrimary,
    },
  },
  checked: {},
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    background: Theme.colors.white,
    width: '100%',
    borderRadius: '0px',
  },
  phoneInput: {
    background: Theme.colors.white,
    width: '100%',
    margin: '0',
    border: '0px',
    borderRadius: '0px',
  },
  withoutLabel: {
    marginTop: '-32px',
    borderRadius: '0px',
  },
  margin: {
    margin: '0',
    width: '50%',
    border: '0px',
    borderRadius: '0px',
  },
}))

const StyledTextField = withStyles({
  root: {
    marginBottom: '2rem',
    width: '100%',
    '& label.Mui-focused': {
      color: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:after': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:before': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.brandTertiary,
    },
    '& .MuiInputBase-input': {
      color: Theme.colors.black,
      padding: '0.5rem 0.5rem 0.5rem 1rem',
      fontSize: '18px',
      fontFamily: Theme.brandFont,
      font: Theme.brandFont,
      height: 'calc(1.8em + 1rem + 2px)',
      boxSizing: 'border-box',
      background: Theme.colors.white,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: '24px',
      top: '50%',
    },
  },
})(TextField)

const StyledTextFieldDark = withStyles({
  root: {
    marginBottom: '2rem',
    width: '100%',
    '& label.Mui-focused': {
      color: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:after': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:before': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.brandTertiary,
    },
    '& .MuiInputBase-input': {
      color: Theme.colors.black,
      background: Theme.colors.lightBlue,
      padding: '0.5rem 0.5rem 0.5rem 1rem',
      fontSize: '18px',
      fontFamily: Theme.brandFont,
      font: Theme.brandFont,
      height: 'calc(1.8em + 1rem + 2px)',
      boxSizing: 'border-box',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: '24px',
      top: '16px',
    },
  },
})(TextField)

const DosageTime = withStyles({
  root: {
    width: '100%',
    '& label.Mui-focused': {
      color: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: Theme.colors.brandTertiary,
    },
    '& .MuiInputBase-input': {
      color: Theme.colors.black,
      padding: '16px',
      fontSize: '18px',
      fontFamily: Theme.brandFont,
      font: Theme.brandFont,
      textTransform: 'uppercase',
    },
    '& .MuiInputLabel-animated': {
      padding: '23px',
      marginTop: '-8px',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: Theme.colors.brandPrimary,
    },
  },
})(TextField)

const Dosage = withStyles({
  root: {
    width: '100%',
    '& label.Mui-focused': {
      color: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: Theme.colors.brandTertiary,
    },
    '& .MuiInputBase-input': {
      color: '#1d1f21',
      padding: '20px 16px',
      fontSize: '18px',
      opacity: '1',
      fontFamily: Theme.brandFont,
      font: Theme.brandFont,
      '&:placeholder': {
        color: 'red',
      },
    },
    '& .MuiInputLabel-animated': {
      padding: '23px',
      marginTop: '-8px',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiTypography-colorTextSecondary': {
      paddingRight: '20px',
    },
  },
})(TextField)

const StyledDatePicker = withStyles({
  root: {
    marginBottom: '2rem',
    width: '100%',
    '& label.Mui-focused': {
      color: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:after': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiInput-underline:before': {
      borderWidth: '2px',
      borderBottomColor: Theme.colors.brandTertiary,
    },
    '& .MuiInputBase-input': {
      color: Theme.colors.black,
      padding: '0.5rem 0.5rem 0.5rem 1rem',
      fontSize: '18px',
      fontFamily: Theme.brandFont,
      font: Theme.brandFont,
      height: 'calc(1.8em + 1rem + 2px)',
      boxSizing: 'border-box',
      background: Theme.colors.white,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: Theme.colors.brandPrimary,
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: '24px',
      top: '50%',
    },
  },
})(TextField)

// ~~~~~~~~~~~~~~~~~ MATERIAL UI FORMS ~~~~~~~~~~~~~~~~~

export function TextInput(props) {
  return (
    <TextInputContainer>
      <InputTitle>
        <H4 className="campton__semi-bold">{props.title}</H4>
      </InputTitle>
      {props.dark ? (
        <InputWrapper>
          <StyledTextFieldDark
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            error={props.error}
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            disabled={props.disabled === 'true' ? true : null}
            inputProps={{ 'aria-label': props.description, maxLength: props.maxLength }}
          />
          <H6 className="helper-text">{props.helperText}</H6>
        </InputWrapper>
      ) : (
        <InputWrapper>
          <StyledTextField
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            error={props.error}
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            disabled={props.disabled === 'true' ? true : null}
            maxLength={props.maxLength}
            inputProps={{ 'aria-label': props.description, maxLength: props.maxLength }}
            InputProps={
              props.endAdornment
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <H5 className="link">Remove</H5>
                      </InputAdornment>
                    ),
                  }
                : null
            }
          />
          <H6 className="helper-text">{props.helperText}</H6>
        </InputWrapper>
      )}
    </TextInputContainer>
  )
}

export function TimePicker(props) {
  const classes = useStyles()
  const firstnumber = /[0-1]/
  const letter = /(?!.*[BCDEFGHIJKLMNOQRSTUVWXYZ])[A-Z]/i
  const digit = /[0-9]/
  const mask = [firstnumber, digit, ':', digit, digit, ' ', letter, 'M']

  return (
    <>
      <RenderInBrowser except safari>
        <DosageTime
          id={props.id}
          type={props.type}
          defaultValue={props.defaultValue}
          label={props.label}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          onChange={props.onChange}
          {...props}
        />
      </RenderInBrowser>

      <RenderInBrowser safari only>
        <InputMask mask={mask} maskPlaceholder="-" onChange={props.onChange} value={props.value}>
          <DosageTime
            id={props.id}
            type={props.type}
            defaultValue={props.defaultValue}
            label={props.label}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            {...props}
          />
        </InputMask>
      </RenderInBrowser>
    </>
  )
}

export function DosageInput(props) {
  return (
    <Dosage
      {...props}
      id={props.id}
      placeholder={props.placeholder}
      InputProps={{
        endAdornment: <InputAdornment position="end">mg</InputAdornment>,
      }}
      aria-describedby={props.ariaDescribedby}
      inputProps={{
        maxLength: 4,
      }}
      labelWidth={0}
    />
  )
}

export function DatePicker(props) {
  const classes = useStyles()

  return (
    <form className={classes.container} noValidate>
      <StyledDatePicker
        id="date"
        type="date"
        defaultValue={moment()}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  )
}
