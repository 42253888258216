import React, { useState } from 'react'
import styled from '@emotion/styled'

import { Button } from '../atoms/button'
import { TextInput } from '../atoms/input'
import { Paragraph, H3, H5 } from '../atoms/text'
import { Animation } from '../atoms/animation'

import { inject, observer } from 'mobx-react'

export const CustomContainer = styled.main`
  H3 {
    padding-bottom: 0rem;
    margin-top: -10px;
  }

  p {
    max-width: 520px;
  }

  H5 {
    &.link {
      color: ${(props) => props.theme.colors.brandSecondary};
      transition: 0.2s ease-in-out;
      cursor: pointer;

      :hover {
        color: ${(props) => props.theme.colors.brandPrimary};
      }
    }
  }
`

export const CustomInput = styled.main`
  margin-bottom: 2rem;
`

export const CustomBox = styled.main`
  padding: 80px;
`

const Message = styled.div`
  text-align: center;
  max-width: 320px;
  margin: 2.5rem auto 0;
`

export const CustomDiv = styled.main`
  display: block;
  padding: 32px 0 0 0;
  width: max-content;
  margin: auto;
  text-align: center;
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const ForgotPasswordForm = (props) => {
  const { dataStore } = props

  const [user, setUser] = useState({})

  return (
    <CustomContainer>
      <Animation
        initial="hidden"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
      >
        <H3>Forgot your password?</H3>
      </Animation>
      <Animation
        initial="hidden"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
      >
        <Paragraph>Enter your email receive a link to reset your password.</Paragraph>
      </Animation>
      <Animation
        initial="hidden"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
      >
        <H5 style={{ marginTop: '32px' }}>Enter your email:</H5>
      </Animation>
      <Animation
        initial="hidden"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
      >
        <TextInput
          value={user.email}
          onChange={(e) => {
            setUser({ ...user, email: e.target.value })
          }}
          placeholder="benjaminbutton@gmail.com"
          email={true}
        />
      </Animation>
      <Animation
        initial="hidden"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
      >
        <CustomDiv>
          <Button
            title="Request new password"
            className="button"
            onClick={() => {
              dataStore.requestPasswordReset({ user })
            }}
          />
          <Message>
            {dataStore.success &&
              dataStore.success.map((m, k) => <p style={{ color: 'green' }}>{m}</p>)}
            {dataStore.errors.map((e, k) => (
              <p style={{ color: 'red' }}>{e}</p>
            ))}
          </Message>
        </CustomDiv>
      </Animation>
    </CustomContainer>
  )
}

export default inject('dataStore')(observer(ForgotPasswordForm))
