import React from 'react'
import styled from '@emotion/styled'
import MetaTags from 'react-meta-tags'
import { Link } from 'react-router-dom'

import { Mq } from '../styles/settings'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import { SmallHero } from '../organisms/smallHero'
import { CtaSectionSmall } from '../molecules/ctaSection'
import { Container } from '../atoms/container'
import { H3, H4, Paragraph } from '../atoms/text'

const LegalCopy = styled.div`
  display: block;
  padding-top: 80px;

  p {
    padding-bottom: 24px;
    max-width: 100%;

    ${Mq.md} {
      max-width: 66.66%;
    }
  }
  li {
    padding: 0 0 24px 0;
  }
  H3 {
    margin-top: 40px;
  }
`

const PrivacyPolicy = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Privacy policy</title>
        <meta
          name="description"
          content="Introducing MyRxCall - the most reliable way to manage medications. Receieve personalized medication reminders via phone call, SMS, or e-mail."
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription reminders for you - Safe, easy, & low cost."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallHero title="Privacy policy" subTitle="Last updated: July 23, 2020" />
      <LegalCopy>
        <Container>
          <H4>MyRxCall privacy policy</H4>

          <Paragraph>
            MyRxCall Inc. (“MyRxCall”or the“Company”) provides this Privacy Policy to inform
            users(each, a “User” or “You”) of the manner in which MyRxCall collects, uses,
            maintains, anddiscloses information (including personal information) collected from
            Users ofMyRxCall.com(the “Website”), as well as Users of the services provided by
            MyRxCall,including all medication reminder services offered via the website, telephone,
            text message, e-mail, or any other medium through which services are offered
            (collectively, the “Services”), andour related policies and procedures.
          </Paragraph>
          <Paragraph>
            This Privacy Policy applies to Your access to and use of the Website and the Services,
            and bychoosing to use them, You consent to the collection, transfer, processing,
            storage, disclosure, andother uses of Your information described in this Privacy Policy.
            If You do not agree to this Privacy Policy, You should immediately discontinue use of
            the Website and the Services.
          </Paragraph>
          <Paragraph>
            As used in this Privacy Policy, the term “Personal Information” means any information or
            datathat can be used to identify any individual, including, but not limited to, a first
            and last name,email address, a home, postal, or other physical address, other contact
            information, title,occupation, industry, personal interests, and other information.
          </Paragraph>
          <Paragraph>
            Any other defined term that is not defined in this Privacy Policy has the meaning given
            that termin the MyRxCall <Link to="/terms-of-service">Terms of Service</Link>.
          </Paragraph>

          <H3>Information MyRxCall Collects.</H3>

          <H4>Personal Information</H4>
          <Paragraph>
            <ul>
              <li>
                You may browse the Website anonymously without expressly providing us any
                PersonalInformation that expressly identifies You, unless You have previously
                registered with us andhave logged into a MyRxCall account (an “Account”).
              </li>
              <li>
                In order to make full use of the Services, You will be required to register for an
                Account.When You create an Account and become a member, we will ask You for certain
                PersonalInformation. In order to set up Your Account, You need to provide Your email
                address andYou will need to select a password. If You elect to add any other
                Personal Information toYour Account, which may include, but is not limited to,
                medication information, dosage andinstructions, we will collect that Personal
                Information and use it and share it only as setforth below. You are solely
                responsible for the accuracy of any Personal Information Youprovide to MyRxCall.
              </li>
              <li>
                MyRxCall may also collect Personal Information from You such as Your name,
                emailaddress, mailing address, and phone number when You communicate with us, such
                as byrequesting information about our Services or requesting customer or technical
                support.
              </li>
            </ul>
          </Paragraph>

          <H4>Non-Personal Information</H4>
          <Paragraph>
            <ul>
              <li>
                We may also collect non-personal information about You when You interact with
                ourWebsite or Services. Non-personal information may include, but is not limited to,
                thebrowser name, the type of computer or device, and the technical information about
                Your means of connection to our Website or Services, such as the operating system,
                the Internetservice providers utilized, mobile network information, and other
                similar information.
              </li>
              <li>
                Our Website may use “cookies” to collect information. A cookie is a small data file
                thatYour web browser transfers to Your computer’s hard drive for record-keeping
                purposes. Youcan instruct Your browser to stop accepting cookies or to prompt You
                before accepting acookie from the websites You visit. If You do not accept cookies,
                however, You may not beable to fully utilize the Website.
              </li>
            </ul>
          </Paragraph>

          <H4>How MyRxCall Uses Your Information</H4>
          <Paragraph>
            <ul>
              <li>
                MyRxCall collects Your Personal Information to provide the MyRxCall Services to
                You.Your Personal Information is also used to track Your preferences and keep You
                informedabout special offers and other products and services MyRxCall may offer.
              </li>
              <li>
                MyRxCall may collect information about the use of the Services and the Website, such
                asthe types of Services used, how many users we receive daily, and the IP/Internet
                address ofYour computer.
              </li>
              <li>
                MyRxCall may contact You in various ways including, but not limited to, via
                email,telephone, cell phone, and SMS/text messages.
              </li>
              <li>
                MyRxCall may aggregate and combine all Personal Information that we receive
                fromMembers. This anonymous information may be used to improve the Website and
                Services,to provide You with a better experience, and to analyze and understand how
                MyRxCallServices are used.
              </li>
            </ul>
          </Paragraph>

          <H4>How MyRxCall Shares or Discloses Your Personal Information</H4>
          <Paragraph>
            <ul>
              <li>Except as provided below, MyRxCall does not share Your Personal Information</li>
              <li>
                <strong>Service Providers.</strong> MyRxCall may disclose Personal Information to
                the third-partyservice providers that we engage to assist us in making the Website
                and Services availableto You. These service providers may include payment
                processors, customer serviceproviders, IT-related services, and other vendors to
                support the provision of the Website andServices. These third parties have access to
                Your Personal Information only to performthese tasks on our behalf and are not
                allowed to disclose or use it for any other purpose.
              </li>
              <li>
                <strong>Compliance with Laws and Law Enforcement.</strong> While MyRxCall
                maintainsinformation regarding Your health on Your behalf, MyRxCall is not a covered
                entity orbusiness associate that performs services on behalf of, or provides
                services to, a coveredentity under the Health Insurance Portability and
                Accountability Act (HIPAA). However,we take the protection of Your private health
                information very seriously. In the event thatMyRxCall receives any subpoena or
                similar request from a court, government or lawenforcement official, we will only
                disclose any Personal Information about You as we, inour sole discretion, believe
                necessary or appropriate to respond to claims and legal process,to protect the
                property and rights of MyRxCall or a third party, to protect the safety of thepublic
                or any person, or to prevent or stop activity we may consider to be, or to pose a
                riskof being, illegal, unethical or legally actionable activity. Given the
                sensitivity of thePersonal Information within Your Account, MyRxCall will carefully
                review any suchrequest and will decline to comply with any such request in our sole
                discretion.
              </li>
              <li>
                <strong>Business Asset Transfers.</strong> MyRxCall may sell, transfer or otherwise
                share some or all ofits assets, including Your Personal Information and related
                Account content, in connectionwith a merger, acquisition, reorganization or sale of
                assets or in the event of bankruptcy, aspermitted by law and/or contract.
              </li>
              <li>
                <strong>Accessing the Website or Services Outside the Us.</strong> You agree that
                all PersonalInformation processed by MyRxCall may be transferred, processed, and
                stored anywhere inthe world, including, but not limited to, the United States or
                other countries, which mayhave data protection laws that are different from the laws
                where You live. If You are locatedoutside the United States and choose to provide
                information to us, MyRxCall transfers Personal Information to the United States and
                processes it there. Your submission ofPersonal Information represents Your agreement
                to that transfer and Your consent to thisPrivacy Policy.
              </li>
            </ul>
          </Paragraph>

          <H3>Unsubscribe / Opt-Out</H3>
          <Paragraph>
            If You receive an unwanted email from MyRxCall, You can use the unsubscribe link found
            at thebottom of the email to opt out of receiving future emails. Please note that You
            will continue toreceive transaction-related emails regarding Services You have
            requested, as well as certain non-promotional communications regarding our Services,
            such as communications regarding updatesto our Terms of Service or this Privacy Policy.
            MyRxCall processes requests to be placed on do-not-mail, do-not-phone, and
            do-not-contact lists as required by applicable law.
          </Paragraph>

          <H3>Changing or Deleting Your Information</H3>
          <Paragraph>In accordance with applicable law, You may have the right to:</Paragraph>
          <ul>
            <li>Access/Portability of Your Personal Information.</li>
            <li>
              Request correction of Your Personal Information where it is inaccurate or incomplete.
            </li>
            <li>
              Request deletion of Your Personal Information, subject to certain exceptions
              prescribedby law.
            </li>
            <li style={{ maxWidth: '66%' }}>
              Request restriction of or object to processing of Your Personal Information, including
              theright to opt in or opt out of the sale of Your Personal Data to third parties.
            </li>
          </ul>
          <Paragraph>
            You may request to exercise any of these rights by contacting us as set forth below. We
            willrespond to and process such requests in accordance with applicable laws. To protect
            Your privacy, we may take steps to verify Your identity before doing so.
          </Paragraph>
          <Paragraph>
            You may also review, update, correct or delete any information stored in Your Account at
            anytime by contacting us or by logging in to Your Account on the Website. If You would
            like todeactivate Your Account, please contact us as set forth below. If You choose to
            deactivate Your Account, we will retain an archived copy of Your Personal Information as
            set forth below.
          </Paragraph>

          <H3>Data Retention</H3>
          <Paragraph>
            MyRxCall will retain the Personal Information we receive for as long as You use our
            Services oras needed to provide You with the Services. We will also retain and use the
            information You provide to fulfill the purpose(s) for which it was collected, resolve
            disputes, establish legaldefenses, conduct audits, pursue legitimate business purposes,
            enforce our agreements, andcomply with applicable laws. If You deactivate Your Account,
            any Personal Informationassociated with Your Account is moved out of our active
            databases, but will still be retained inour systems pursuant to our data backup and
            retention policies.
          </Paragraph>

          <H3>How MyRxCall Protects Your Personal Information</H3>
          <Paragraph>
            MyRxCall protects Your Personal Information in several ways. Access by You to Your
            Account isavailable through a password and unique username selected by You, which is
            encrypted. We recommend that You do not share Your password with anyone. In
            addition,Your Personal Information resides on secure servers that only selected MyRxCall
            personnel andcontractors have access to, and all Personal Information stored on the
            Website is encrypted usingthe latest encryption technology.
          </Paragraph>
          <Paragraph>
            MyRxCall strives to prevent unauthorized parties from viewing such
            information.Unfortunately, no system is 100% secure, and MyRxCall cannot ensure or
            warrant the security ofany Personal Information You provide to us. To the fullest extent
            permitted by applicable law,MyRxCall does not accept liability for unintentional
            disclosure. By using the Services orproviding Personal Information to us, You agree that
            we may communicate with You regardingsecurity, privacy, and administrative issues
            relating to Your use of the Services. If we learn of asecurity system’s breach, we may
            attempt to notify You by mail, by sending You an e-mail, or byposting a notice on the
            Website.
          </Paragraph>

          <H3>Links to Other Websites</H3>
          <Paragraph>
            MyRxCall’s Website may contain links to other websites. If You choose to click on a
            third-partylink, You will be directed to that third party’s website. MyRxCall does not
            share Your PersonalInformation with those third parties, does not exercise control over
            third-party websites, and isnot responsible for any third party’s privacy practices. We
            encourage You to read the privacypolicies or statements of any other websites You visit.
          </Paragraph>

          <H3>MyRxCall's Policy Toward Minors</H3>
          <Paragraph>
            The MyRxCall Website and Services are not targeted towards, nor intended for use
            by,individuals under the age of 13. MyRxCall does not knowingly collect Personal
            Informationfrom children under 13. If a parent or guardian becomes aware that his or her
            child has providedus with Personal Information without their consent, he or she should
            contact us as set forthbelow. If we become aware that a child under 13 has provided us
            with Personal Information, wewill delete such information from our files.
          </Paragraph>
          <Paragraph>
            If You have created an Account for a minor under the age of 18, You represent and
            warrant toMyRxCall that You are the parent or legal guardian for that minor with a right
            to provide us thePersonal Information of that minor.
          </Paragraph>

          <H3>Changes to the Privacy Policy</H3>
          <Paragraph>
            MyRxCall reserves the right, at its sole discretion, to modify this Privacy Policy from
            time totime in our sole discretion. If there are any material changes to this Privacy
            Policy, we will postthe modified Privacy Policy on our Website and notify You as
            required by applicable law. Wewill also update the “Effective Date” at the bottom of
            this Privacy Policy. You understand andagree that You will be deemed to have accepted
            the updated Privacy Policy if You continue touse the Services after the new Privacy
            Policy takes effect. If the modified Privacy Policy is notacceptable to You, Your only
            recourse is to cease using the Website and Services.
          </Paragraph>

          <H3>Contacting Us</H3>
          <Paragraph>
            If You have any questions about this Privacy Policy, or if You wish to submit a request
            toexercise Your rights as detailed in this Privacy Policy, please contact MyRxCall by
            calling <a href="tel:8004606507">800-460-6507</a>, e-mailing{' '}
            <a href="mailto:support@myrxcall.com">support@myrxcall.com</a>, or by sending a letter
            to us at:
          </Paragraph>

          <Paragraph>
            <strong>MyRxCall, Inc.</strong>
            <br />
            2700 Neilson Way, Unit 124
            <br />
            Santa Monica, CA 90405
          </Paragraph>
          <Paragraph>Effective: July 17, 2020</Paragraph>
        </Container>
      </LegalCopy>
      <CtaSectionSmall headline="The most reliable way to manage medications" cta="About pricing" />
    </main>
  )
}

export default PrivacyPolicy
