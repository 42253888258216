import React from 'react'
import styled from '@emotion/styled'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import MetaTags from 'react-meta-tags'

import { Mq } from '../styles/settings'
import { SmallContainer } from '../atoms/container'
import ForgotPasswordForm from '../molecules/forgotPasswordForm'

export const CustomContainer = styled.main`
  padding: 80px 0;
  margin-top: 3rem;
  margin-bottom: 2rem;

  ${Mq.md} {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`

export const CustomHeader = styled.main`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  H4 {
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;

    :hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`

export const CustomBox = styled.main`
  padding: 80px;
  margin-top: 20px;
  background-color: ${(props) => props.theme.colors.lightBlue};
`

const ForgotPassword = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Forgot password</title>
        <meta
          name="description"
          content="Introducing MyRxCall - the most reliable way to manage medications. Receieve personalized medication reminders via phone call, SMS, or e-mail."
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription reminders for you - Safe, easy, & low cost."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallContainer>
        <CustomContainer>
          <CustomBox>
            <ForgotPasswordForm />
          </CustomBox>
        </CustomContainer>
      </SmallContainer>
    </main>
  )
}

export default ForgotPassword
