import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import { Mq } from '../styles/settings'
import CreateAccountForm from '../molecules/createAccountForm'
// import CreateMembershipForm from '../molecules/createMembershipForm'
import CreateBillingForm from '../molecules/createBillingForm'

export const CreateMembershipPage = styled.div`
  position: relative;
`

export const CreateMembershipContainer = styled.div`
  padding: 2rem 1rem;
  margin-top: 1rem;
  background-color: ${(props) => props.theme.colors.lightBlue};

  ${Mq.md} {
    padding: 3rem 4rem;
  }
`

const CreateMembership = (props) => {
  const { dataStore, subscriptionStore } = props
  const [subscription, setSubscription] = useState({})
  subscriptionStore.getPrices()

  return (
    <CreateMembershipPage>
      <CreateMembershipContainer>
        <CreateAccountForm />
        {/* <section id="membership"></section>
        {dataStore.localUser ? (
          <CreateMembershipForm subscription={subscription} setSubscription={setSubscription} />
        ) : null} */}
        <section id="billing"></section>
        {dataStore.localUser ? (
          <CreateBillingForm
            subscription={subscription}
            setSubscription={setSubscription}
            newAccount={true}
          />
        ) : null}
      </CreateMembershipContainer>
    </CreateMembershipPage>
  )
}

export default inject('dataStore', 'subscriptionStore')(observer(CreateMembership))
