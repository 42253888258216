import React from 'react'
import MetaTags from 'react-meta-tags'

import metaImage from '../assets/images/my-rx-call__meta-share.png'

import { SmallHero } from '../organisms/smallHero'
import { FrequentlyAskedQuestions } from '../organisms/frequentlyAskedQuestions'
import { CtaSectionSmall } from '../molecules/ctaSection'

const Help = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Help</title>
        <meta
          name="description"
          content="MyRxCall - Unlimited medication reminders, one low monthly rate, no hidden fees. Sign Up Today."
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription Reminders For You - Safe, Easy, & Low Cost."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallHero
        title="Help"
        helpHero="true"
        subTitle={
          <span>
            "I need to update my prescription dosage."
            <br />
            "Can I remove a prescription from my reminders?"
            <br />
            "How much wood does a wood chuck chuck?”
          </span>
        }
      />
      <FrequentlyAskedQuestions />
      <CtaSectionSmall headline="The most reliable way to manage medications" cta="About pricing" />
    </main>
  )
}

export default Help
