import React from 'react'

import MetaTags from 'react-meta-tags'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import { SmallHero } from '../organisms/smallHero'
import { PricingDetails } from '../organisms/pricingDetails'
import { CtaSectionSmall } from '../molecules/ctaSection'

const Pricing = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Pricing</title>
        <meta
          name="description"
          content="MyRxCall - Low cost medication reminders for you and your loved ones -- try us for 7 days free."
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription reminders for you - Safe, easy, & low cost."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallHero
        title="Pricing"
        pricingHero="true"
        subTitle="Unlimited personalized reminders, one low monthly fee."
        extraTitle="One price. No surprises."
      />
      <PricingDetails />
      <CtaSectionSmall
        link="/get-started"
        headline="The most reliable way to manage medications"
        cta="Get started"
      />
    </main>
  )
}

export default Pricing
