import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { darken } from 'polished'
import { Box, Flex } from '@rebass/emotion'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

import { Mq } from '../styles/settings'
import { Animation } from '../atoms/animation'
import { Container } from '../atoms/container'
import { H2, H4, Paragraph } from '../atoms/text'

const FrequentlyAskedQuestionsContainer = styled.div`
  margin-top: 40px;

  H2 {
    margin: 30px 0px 20px;
  }
  H4 {
    color: ${(props) => props.theme.colors.brandSecondary};
    margin: 0;
    max-width: 80%;

    ${Mq.md} {
      max-width: 100%;
    }
  }
  p {
    max-width: 900px;
    margin-bottom: 40px;
  }
  .accordion {
    border: 0px;

    &:active,
    :focus {
      border: 0px;
    }
  }
  .accordion__button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    margin-bottom: 20px;
    background-color: ${(props) => props.theme.colors.lightBlue};
    transition: 0.2s ease-in-out;

    &:before {
      color: ${(props) => props.theme.colors.brandSecondary};
      transition: 0.2s ease-in-out;
      margin-right: 0px;
      ${Mq.md} {
        margin-right: 12px;
      }
    }

    &:focus {
      outline: none;
      background-color: ${(props) => darken(0.05, props.theme.colors.lightBlue)};
    }
  }
`
const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

export function FrequentlyAskedQuestions() {
  return (
    <Container>
      <FrequentlyAskedQuestionsContainer>
        <Flex alignItems="center">
          <Box width={1}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.5 }}
            >
              <H2>Free trial FAQ</H2>
            </Animation>
          </Box>
        </Flex>
        <Accordion preExpanded="optional" allowZeroExpanded={true}>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>How does the free 7 day trial work?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  When you start your first membership/subscription on MyRxCall, it will begin with
                  a seven (7) day free trial, which lets you try the MyRxCall service for free for
                  seven (7) days. On the 8th day, your free trial will automatically become a paid
                  membership/subscription unless you cancel your free trial. During your free trial
                  you will have full access to MyRxCall services.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>How do I start my free trial of MyRxCall?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  To start a free trial, you'll need to provide a valid credit card or use your
                  PayPal account. Your card card or PayPal account will be charged for the first
                  month of the subscription after the free trial ends. Your free trial will
                  automatically become a paid membership/subscription on the 8th day after you begin
                  your trial.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>If I'm providing a payment, will I be charged?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  The credit card that you provide will only be charged if you do not cancel your
                  Trial Plan within seven (7) days. To avoid being charged after your free trial
                  ends, be sure to cancel before the 7 days are over. On the 8th day, your payment
                  method on file will be automatically charged for the first month of the
                  subscription.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>The free trial wasn't for me, how can I cancel?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  We’re sad to see you go, to cancel your MyRxCall free trial, simply log into your
                  account settings and press <b>cancel</b>.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>I forgot to cancel my free trial, can I get a refund?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  Unfortunately we are unable to offer refunds for failure to cancel on time. If you
                  don't want to make any more payments, you can cancel your membership/subscription.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>
        </Accordion>

        <Flex alignItems="center" pt={4}>
          <Box width={1}>
            <H2>General questions</H2>
          </Box>
        </Flex>
        <Accordion preExpanded="none" allowZeroExpanded={true}>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>What is MyRxCall?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  MyRxCall is the safest, easiest and most reliable medication reminder service
                  available to members. Receive medication alerts via text message, e-mail, and/or
                  phone call.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>How does My RX Call work?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  Create an account on MyRxCall, input your medication information, your dosage and
                  time you take your pills and we’ll take care of the rest!
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>Is it safe?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  MyRxCall is 100% safe and secure --- we secure your data with the latest
                  encryption technology.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>How much does it cost?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>The cost of MyRxCall is $9.95/month.</Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>When does my membership renew?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  Your membership renews monthly on the date of activation. For example, if you
                  select the monthly membership plan on the 10th of the month, you will
                  automatically renew on the 10th of every month.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>How do I pay for my membership?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  We offer payment by credit card, as a convenience for our customers. We accept
                  VISA, MasterCard, Discover, and PayPal.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>Can I cancel my membership?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  If you choose to cancel your membership, please email support@myrxcall.com
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>How do I change my dosage or prescription?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  Simply log into your MyRxCall account and edit your medication information.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>Can I change my phone number and email address?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  You can edit all personal information, including medication adjustments, by simply
                  logging into your MyRxCall account.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>How can I reset my password?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  If you’re having trouble logging in, please{' '}
                  <Link to="/forgot-password">request password</Link>.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>How long is my reminder phone call?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  All reminder phone calls are 30 seconds long, with the option to repeat if
                  necessary.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>How many membership plans do you offer?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  MyRxCall is proud to offer 3 membership plans to fit your needs. Option 1 (our
                  most popular) is $99.50/12 months (that’s two months free!). Option 2 is $49.75/6
                  months (one month free), and lastly, our monthly membership for $9.95/month.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>What if I'm not getting my phone calls?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  Please make sure your account is up-to-date with a valid phone number and all
                  information is entered correctly. If you are using a smartphone, please make sure
                  your phone is functioning properly and you have plenty of battery life.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>What if I'm not getting my text messages?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  Please check with your carrier to ensure MyRxCall is not blocked and that your
                  smartphone is working properly and is fully charged.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>What if I'm not getting my emails?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  Please check your promotions folder as well as your spam and junk folder. Please
                  be sure to add <a href="mailto:alerts@myrxcall.com">alerts@myrxcall.com</a> to
                  your address book to ensure you receive all emails.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>What type of reminders can I get?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  Receive personalized medication reminders via phone call, SMS/text message, and/or
                  e-mail. Your choice. 1, or all 3 -- it’s up to you!
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
          >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <H4>Do you offer free memberships?</H4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Paragraph>
                  We understand that memberships can be cost prohibitive for some, please e-mail{' '}
                  <a href="mailto:support@myrxcall">support@myrxcall</a> to request a free
                  membership.
                </Paragraph>
              </AccordionItemPanel>
            </AccordionItem>
          </Animation>
        </Accordion>
      </FrequentlyAskedQuestionsContainer>
    </Container>
  )
}
