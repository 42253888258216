import React from 'react'
import styled from '@emotion/styled'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import MetaTags from 'react-meta-tags'
import { inject, observer } from 'mobx-react'

import { Mq } from '../styles/settings'
import PrescriptionDetails from '../organisms/prescriptionDetails'
import { SmallContainer } from '../atoms/container'
import { H1 } from '../atoms/text'

export const CustomContainer = styled.main`
  padding: 7rem 0 80px;
  ${Mq.md} {
    padding: 10rem 0 80px;
  }
`

export const CustomHeader = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-between;

  H1 {
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 430px;
    margin-bottom: -0.5rem;
  }
`

const Prescriptions = (props) => {
  const { dataStore } = props
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Prescriptions</title>
        <meta
          name="description"
          content="Introducing MyRxCall - the most reliable way to manage medications. Receieve personalized medication reminders via phone call, SMS, or e-mail."
        />
        <meta property="og:title" content="MyRxCall – Your prescriptions" />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallContainer>
        <CustomContainer>
          <CustomHeader>
            <H1>Welcome, {dataStore.user && dataStore.user.first_name}</H1>
          </CustomHeader>
          <PrescriptionDetails />
        </CustomContainer>
      </SmallContainer>
    </main>
  )
}

export default inject('dataStore')(observer(Prescriptions))
