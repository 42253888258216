import { Theme } from '../styles/settings'

// const hasNumber = (value) => {
//   return new RegExp(/[0-9]/).test(value)
// };
//
// const hasMixed = (value) => {
//   return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value)
// };
//
// const hasSpecial = (value) => {
//   return new RegExp(/[!#@$%^&*)(+=._-]/).test(value)
// };

export const strengthColor = (count) => {
  if (count < 1) return Theme.colors.brandTertiary;
  if (count < 2) return Theme.colors.error;
  if (count <= 3) return Theme.colors.warning;

  return Theme.colors.success;
};

export const strengthIndicator = (value) => {
  let strengths = 0;

  if (value.length > 0) strengths++;
  if (value.length > 1) strengths++;
  if (value.length > 2) strengths++;
  if (value.length > 3) strengths++;

  return strengths
};

export const strengthText = (count) => {
  if (count < 1) return '';
  if (count < 2) return 'Too Weak';
  if (count <= 3) return 'Almost There';
  if (count < 4) return 'Great Password!';

  return 'Great Password!';
};
