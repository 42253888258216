import { css } from '@emotion/core'

export const Fonts = css`
  @font-face {
    font-family: 'Canela Web';
    src: local('Canela Web Medium Regular'), local('CanelaWeb-Medium'),
      url('../../assets/fonts/CanelaWeb-Medium.woff2') format('woff2'),
      url('../../assets/fonts/CanelaWeb-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Campton';
    src: local('Campton'), local('Campton'),
      url('../../assets/fonts/campton-book-webfont.woff2') format('woff2'),
      url('../../assets/fonts/campton-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Campton Semibold';
    src: local('Campton Semibold'), local('Campton Semibold'),
      url('../../assets/fonts/campton-semibold-webfont.woff2') format('woff2'),
      url('../../assets/fonts/campton-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`
