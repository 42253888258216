import React from 'react'
import styled from '@emotion/styled'
import { makeStyles } from '@material-ui/core/styles'

import { H2, H5, Paragraph } from '../atoms/text'
import { Mq } from '../styles/settings'

const PaymentHistoryContainer = styled.div``

const FormContainer = styled.div`
  padding: 0;

  p {
    margin-bottom: 16px;
    padding: 16px 0 0 0;
    font-size: 1.2rem;
  }

  H2 {
    text-align: center;
  }

  Button {
    margin-bottom: 0px;
    float: right;
    z-index: 3;
  }
`

const PaymentHistoryHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  align-items: center;

  H2 {
    padding-right: 30px;
    min-width: max-content;
  }

  H4 {
    text-align: right;
    padding-left: 32px;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  svg {
    margin-left: 32px;
  }
`

const PaymentHistoryButtonHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  H5 {
    margin: 0;
    text-align: right;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`

const DividerLine = styled.div`
  height: 2px;
  margin-bottom: 7px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.brandTertiary};
`

const PaymentHistoryList = styled.div`
  display: block;
  max-height: 400px;
  overflow-y: auto;
`

const PaymentHistoryLineItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 2px solid ${(props) => props.theme.colors.brandTertiary};

  H5 {
    margin: 0;
    margin-top: 5px;

    &.link {
      color: ${(props) => props.theme.colors.brandSecondary};
      transition: 0.2s ease-in-out;
      cursor: pointer;

      :hover {
        color: ${(props) => props.theme.colors.brandPrimary};
      }
    }
  }

  ${Mq.md} {
    flex-wrap: nowrap;
  }
`

const useStyles = makeStyles((theme) => ({
  root: {},
  textField: {
    background: 'white',
    width: '100%',
    margin: '0',
  },
  margin: {
    margin: '0',
    width: '50%',
    border: '0px',
    borderRadius: '0px',
  },
}))

export function PaymentHistory(props) {
  const classes = useStyles()

  return (
    <PaymentHistoryContainer className={classes.root}>
      <FormContainer>
        <PaymentHistoryHeader>
          <H2>{props.title}</H2>
          <DividerLine />
        </PaymentHistoryHeader>

        <PaymentHistoryButtonHeader>
          <Paragraph>Here is a list of your most recent payments</Paragraph>
          {/* WIP --- ICEBOX ---- Add if its easy to download all <H5 className="link">Download All</H5> */}
        </PaymentHistoryButtonHeader>

        <PaymentHistoryList>
          {/* WIP --- ICEBOX --- Update with data for dates of all payments, charge, and last 4 of card if possible */}
          <PaymentHistoryLineItem>
            <H5>Jul 13, 2020 – $9.95 Charged to card ending in 1234</H5>
            <H5 className="link">Download</H5>
          </PaymentHistoryLineItem>

          <PaymentHistoryLineItem>
            <H5>Jun 13, 2020 – $9.95 Charged to card ending in 1234</H5>
            <H5 className="link">Download</H5>
          </PaymentHistoryLineItem>
        </PaymentHistoryList>
      </FormContainer>
    </PaymentHistoryContainer>
  )
}

export default PaymentHistory
