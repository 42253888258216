import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Link as ExternalLink } from '@rebass/emotion'
import { Link } from 'react-router-dom'
import { Facebook } from 'react-feather'
import moment from 'moment'

import { Mq } from '../styles/settings'
import { Animation } from '../atoms/animation'
import { LightLogo } from '../atoms/logo'
import { Container } from '../atoms/container'
import { EmailCapture } from '../molecules/emailCapture'
import { H4, H5 } from '../atoms/text'

const FooterContainer = styled.div`
  background: ${(props) => props.theme.colors.blueGrey};
  padding: 2rem 0;
  position: static;
  bottom: 0;
  font-size: 1rem;
  width: 100%;
  z-index: 10;

  H4 {
    color: #fff;
    margin-bottom: 0rem;
    margin-top: 2rem;
    ${Mq.md} {
      margin-bottom: 40px;
      margin-top: 0px;
    }
  }

  H5 {
    color: #fff;
    padding-bottom: 0px;
    line-height: 30px;
    transition: 0.2s ease-in-out;
    max-width: 300px;
    margin-bottom: 0px;
    font-family: ${(props) => props.theme.brandFont};

    &.resource {
      max-width: max-content;
    }
  }

  p {
    color: #fff;
    max-width: 60%;
    margin-bottom: 0px;
  }

  a {
    width: max-content;
    transition: 0.2s ease-in-out;
    & h5:hover {
      color: ${(props) => props.theme.colors.brandTertiary};
      text-decoration: none;
    }
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.colors.brandTertiary};
    }
  }

  height: 100%;

  ${Mq.md} {
    padding: 80px 0;
    height: auto;

    H5 {
      max-width: max-content;
    }
  }
`
const EmailCaptureHeader = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;

  a {
    & h5:hover {
      color: ${(props) => props.theme.colors.brandTertiary};
      text-decoration: none;
    }
    color: ${(props) => props.theme.colors.brandTertiary};
    text-decoration: none;
  }

  svg {
    padding: 10px;
    bottom: 172px;
    right: 0;
    border: 3px solid #fff;
    width: 48px;
    height: 48px;
    overflow: visible;
    margin-left: 16px;
    border-radius: 40px;
    transition: 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      border-color: ${(props) => props.theme.colors.brandTertiary};
    }

    H5 {
      width: auto;
      display: flex;
      align-items: flex-end;
      width: 80%;
    }
  }

  ${Mq.md} {
    position: relative;

    svg {
      top: 0;
      position: absolute;
    }
  }
`

const CustomColumnLeft = styled.div`
  width: 100%;

  ${Mq.md} {
    width: 60%;
  }
`

const CustomColumnRight = styled.div`
  position: absolute;
  top: 20px;
  right: 0px;
  ${Mq.md} {
    width: 40%;
    top: 0px;
  }
`

const CustomColumnRightLinks = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  ${Mq.md} {
    width: 40%;
    top: 0px;
    justify-content: flex-end;
  }
`

const EmailCaptureContainer = styled.div`
  height: 240px;

  ${Mq.md} {
    height: 100%;
  }
`

const CopyrightSection = styled.div`
  width: 100%;
  display: block;
  padding: 15px 0 10px;
  background-color: ${(props) => props.theme.colors.darkGrey};

  h5 {
    transition: 0.2s ease-in-out;
    color: ${(props) => props.theme.colors.white};
  }

  a {
    & h5:hover {
      color: ${(props) => props.theme.colors.brandTertiary};
      text-decoration: none;
    }
    color: ${(props) => props.theme.colors.brandTertiary};
    text-decoration: none;
  }

  ${Mq.md} {
    display: flex;
    justify-content: space-between;
  }
`

const CopyrightContainer = styled.div`
  width: 100%;
  display: block;
  padding: 15px 0 10px;
  text-align: center;

  h5 {
    color: ${(props) => props.theme.colors.white};
  }

  ${Mq.md} {
    display: flex;
    text-align: inherit;
    justify-content: space-between;
  }
`

const LeftBox = styled(Box)`
  width: auto;
  H5 {
    max-width: max-content;
  }
  ${Mq.md} {
    width: 25%;
  }
`

const CenterBox = styled(Box)`
  width: auto;
  ${Mq.md} {
    width: 25%;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const RightBox = styled(Box)`
  width: auto;
  ${Mq.md} {
    width: 50%;
  }
`

const StyledFlex = styled(Flex)`
  position: relative;
`

const currentYear = moment(Date.now()).format('YYYY')

export function Footer(props) {
  const variants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    fadeIn: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      <FooterContainer>
        <Container>
          <StyledFlex width="auto" flexDirection={['column', 'column', 'row']}>
            <LeftBox width="auto">
              <Link to="/">
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
                >
                  <LightLogo />
                </Animation>
              </Link>

              <H5 light="true" style={{ marginTop: '42px' }}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.1 }}
                >
                  2700 Neilson Way, Unit 124
                  <br />
                  Santa Monica, CA 90405
                </Animation>
              </H5>
              <br />

              <H5 light="true" style={{ paddingTop: '12px' }}>
                <ExternalLink href="tel:8004606507">
                  <Animation
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
                  >
                    800-460-6507
                  </Animation>
                </ExternalLink>
              </H5>

              <H5 light="true">
                <ExternalLink href="mailto:hello@myrxcall.com">
                  <Animation
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.3 }}
                  >
                    hello@myrxcall.com
                  </Animation>
                </ExternalLink>
              </H5>
            </LeftBox>
            <CenterBox my={4} width="auto">
              <Animation
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.3 }}
              >
                <H4>Resources</H4>
              </Animation>

              <H5 className="resource" light="true" style={{ lineHeight: '38px' }}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
                >
                  <Link to="/about-us"> About us </Link>
                </Animation>
              </H5>

              <H5 className="resource" light="true">
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.5 }}
                >
                  <Link to="/how-it-works">How it works </Link>
                </Animation>
              </H5>

              <H5 className="resource" light="true">
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
                >
                  <Link to="/contact"> Contact us </Link>{' '}
                </Animation>
              </H5>

              <H5 className="resource" light="true">
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.7 }}
                >
                  <Link to="/sign-in"> Sign in </Link>{' '}
                </Animation>
              </H5>

              <H5 className="resource" light="true">
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
                >
                  <Link to="/help"> Help </Link>
                </Animation>
              </H5>
            </CenterBox>

            <RightBox width="auto">
              <EmailCaptureContainer>
                <EmailCaptureHeader>
                  <CustomColumnLeft>
                    <Animation
                      initial="hidden"
                      animate="visible"
                      variants={variants}
                      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
                    >
                      <H4>Join our email list</H4>
                    </Animation>
                    <Animation
                      initial="hidden"
                      animate="visible"
                      variants={variants}
                      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.7 }}
                    >
                      <H5 light="true">
                        Sign up today to receive new product updates from the MyRxCall team.
                      </H5>
                    </Animation>
                  </CustomColumnLeft>
                  <CustomColumnRight>
                    <ExternalLink
                      href="https://www.facebook.com/Myrxcall-110087317356390/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Animation
                        initial="fadeIn"
                        animate="visible"
                        variants={variants}
                        transition={{ ease: 'easeInOut', duration: 0.5, delay: 1 }}
                      >
                        <Facebook color="#fff" />
                      </Animation>
                    </ExternalLink>

                    {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <Twitter color="#fff" />
                  </a>
                  <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <Instagram color="#fff" />
                  </a> */}
                  </CustomColumnRight>
                </EmailCaptureHeader>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
                >
                  <EmailCapture />
                </Animation>
              </EmailCaptureContainer>
            </RightBox>
          </StyledFlex>
        </Container>
      </FooterContainer>
      <CopyrightSection>
        <Container>
          <CopyrightContainer>
            <CustomColumnLeft>
              <Animation
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
              >
                <H5 light="true">
                  <span role="img" aria-label="copyright">
                    ©️
                  </span>{' '}
                  {currentYear} MyRxCall – All rights reserved.
                </H5>
              </Animation>
            </CustomColumnLeft>
            <CustomColumnRightLinks>
              <Link to="/privacy-policy">
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
                >
                  <H5 light="true">Privacy policy</H5>
                </Animation>
              </Link>
              <Animation
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.45 }}
              >
                <H5 style={{ padding: '0 10px' }}>–</H5>
              </Animation>
              <Link to="/terms-of-service">
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.5 }}
                >
                  <H5 light="true">Terms of service</H5>{' '}
                </Animation>
              </Link>
              <Animation
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.55 }}
              >
                <H5 style={{ padding: '0 10px' }}> – </H5>
              </Animation>

              <ExternalLink href="https://myrxcall.com/sitemap.xml" target="_blank">
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
                >
                  <H5 light="true">Sitemap</H5>
                </Animation>
              </ExternalLink>
            </CustomColumnRightLinks>
          </CopyrightContainer>
        </Container>
      </CopyrightSection>
    </>
  )
}
