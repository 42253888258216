import React from 'react'
import styled from '@emotion/styled'

import { Mq } from '../styles/settings'

const BlackLogo = styled.div`
  display: flex;
  margin-right: 30px;
`

const WhiteLogo = styled.div`
  display: flex;
  margin-right: 30px;

  svg {
    max-height: 30px;
  }

  ${Mq.md} {
    svg {
      max-height: auto;
    }
  }
`

export function LightLogo(props) {
  return (
    <WhiteLogo className={props.className ? props.className + 'logo__light' : 'logo__light'}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        height="100%"
        width="140px"
        viewBox="0 0 215 49"
        xmlSpace="preserve"
      >
        <path
          className="st0"
          fill="#fff"
          d="M1.5 36.9v-1.4h.2c2.3 0 3.1-1.1 3.1-3.6v-9.4c0-1.4-.2-2.1-1.4-2.9L.3 17.4c4.3-1.5 8.7-3.7 13.3-6.3v4.3c2-2.8 4.7-4 8.3-4 3.5 0 6 1.4 7.2 3.9 1.8-2.6 4.6-4 7.9-4 5.5 0 8.2 2.8 8.2 8.5v12.1c0 2.6.8 3.6 3.1 3.6h.2v1.4H35.6V20c0-3.3-.6-4.8-2.9-4.8-2.1 0-2.8 1.6-2.8 4.8v11.8c0 2.6.8 3.6 3.1 3.6h.2v1.4H17.1v-1.4h.2c2.3 0 3.1-1.1 3.1-3.6L20 20c0-3.3-.6-4.8-2.9-4.8-2.1 0-2.7 1.6-2.7 4.8v16.9H1.5z"
        />
        <path
          fill="#fff"
          className="st0"
          d="M45.8 13.7c-.2 0-.4.1-.6.1v-1.4h17.2v1.4c-1.3.1-2.1.6-2.1 1.4 0 .5.3 1.2.7 2.1l3.8 7.5 2.7-5.8c.7-1.5.9-2.7.9-3.2 0-1.4-.9-1.9-2.7-1.9v-1.4h9.5v1.4h-.1c-2.7 0-3.9 1.7-5.6 5.3L63 32.8c-4.1 8.8-8.2 15.5-13.6 15.5-3.7 0-6.1-2.1-6.1-4.8 0-2.1 1.7-3.7 3.9-3.7 1.6 0 2.8.9 4.2 1.9 1.1.8 2.1.9 3.3.9 2 0 3.5-3.9 3.5-6.9 0-2-.8-4.4-2.4-7.4L50.9 19c-1.6-3.5-2.9-5.3-5.1-5.3z"
        />
        <path
          fill="#fff"
          className="st1"
          d="M96.1 11.1c-2.3 0-4.2 1.4-5.8 4.2v-4.4c-5.4 2.8-9.8 5-13.5 6.3l3.1 2.2c1.3.9 1.4 1.5 1.4 2.9v9.4c0 2.6-.8 3.6-3.1 3.6H78v1.4h16.4v-1.4h-.2c-2.5 0-3.4-1.1-3.4-3.6V21.3c0-3.4.3-5 1.5-5 1 0 1.9 2.3 4.3 2.2 2.1 0 3.4-1.4 3.4-3.5.2-2.3-1.4-3.9-3.9-3.9z"
        />
        <path
          fill="#fff"
          className="st0"
          d="M144.2 37.9c-7.5 0-12.8-5.2-12.8-13.1 0-4 1.6-7.7 4.6-10.4 2.5-2.2 5.5-3.3 8.8-3.3 5.8 0 9.8 3.3 9.9 7.5 0 2.4-1.7 4.2-4.1 4.2-2.2 0-3.7-1.7-3.7-3.9 0-3.8-.3-5.9-3-5.9-2.5 0-3.8 2.2-3.8 6.5 0 7.9 4.6 13 10.4 13 1.9 0 3.8-1.3 6-4.5l-.9 4.5c-3.1 3.3-7.1 5.4-11.4 5.4z"
        />
        <path
          fill="#fff"
          className="st0"
          d="M162.1 37.8c-4.2 0-6.9-2.6-6.9-6.2 0-5.1 4.9-7.6 14.9-11.5v-1.8c0-3.6-.8-5.2-3.1-5.2-1.8 0-3.1 1.2-3.1 2.8 0 1.1.1 1.9.1 2.5 0 2.5-1.5 4.1-3.7 4.1-2 0-3.4-1.4-3.4-3.5 0-4.3 4.4-7.9 11.5-7.9 3.9 0 7.1 1.1 9 2.8 2 1.9 2.2 4.1 2.2 7.2v7.1c0 3.7.5 5.5 2 5.5.3 0 .6-.2.9-.4v1.6c-1.3 1.7-3.5 2.7-6.1 2.7-3 0-5-1.4-5.9-4-2.5 2.8-5.3 4.2-8.4 4.2zm3.3-8.2c0 2.2 1 3.5 2.3 3.5 2.3 0 2.3-2.1 2.3-6.3V22c-2.9 1.2-4.6 3.8-4.6 7.6zM185.2 8.3l-2.7-2.2c3.9-1.2 8.5-2.9 13.8-5.1v30.8c0 2.6.8 3.6 3.1 3.6h.2v1.4h-16.1v-1.4h.2c2.3 0 3.1-1.1 3.1-3.6V11.2c-.2-1.5-.4-1.9-1.6-2.9z"
        />
        <path
          fill="#fff"
          className="st0"
          d="M200.3 8.3l-2.7-2.2c3.9-1.2 8.5-2.9 13.8-5.1v30.8c0 2.6.8 3.6 3.1 3.6h.2v1.4h-16.1v-1.4h.2c2.3 0 3.1-1.1 3.1-3.6V11.2c-.2-1.5-.4-1.9-1.6-2.9z"
        />
        <path
          fill="#fff"
          className="st1"
          d="M107.5 27.4l-2.2 2.9c-.8 1.1-1.2 2.1-1.2 2.9 0 1.5.9 2.2 2.8 2.1v1.4H94.5v-1.4c3.1-.1 5-1.7 7.1-4l4.8-5.5 1.1 1.6z"
        />
        <path
          fill="#fff"
          className="st0"
          d="M45.8 13.7c-.2 0-.4.1-.6.1v-1.4h17.2v1.4c-1.3.1-2.1.6-2.1 1.4 0 .5.3 1.2.7 2.1l3.8 7.5 2.7-5.8c.7-1.5.9-2.7.9-3.2 0-1.4-.9-1.9-2.7-1.9v-1.4h9.5v1.4h-.1c-2.7 0-3.9 1.7-5.6 5.3L63 32.8c-4.1 8.8-8.2 15.5-13.6 15.5-3.7 0-6.1-2.1-6.1-4.8 0-2.1 1.7-3.7 3.9-3.7 1.6 0 2.8.9 4.2 1.9 1.1.8 2.1.9 3.3.9 2 0 3.5-3.9 3.5-6.9 0-2-.8-4.4-2.4-7.4L50.9 19c-1.6-3.5-2.9-5.3-5.1-5.3z"
        />
        <g>
          <path
            fill="#B5C9DB"
            className="st1"
            d="M127.8 35.4c-1.9 0-3.1-1.2-4.6-3.3l-7.3-10.5 3.5-4.3c1.8-2.2 3.3-3.5 5.7-3.6v-1.4h-9.7v1.4c1.4.3 2.1.8 2.1 1.7 0 1.1-.9 2.8-2.7 4.7l-2.4-3.4c-.5-.7-.8-1.2-.8-1.7 0-.7.6-1.1 1.7-1.3v-1.4h-11.5c.4.9.7 1.8.7 2.9 0 1.4-.4 2.6-1 3.5l4.8 7.3-4.8 5.5c-1.5 1.7-2.8 3.1-4.7 3.7v1.7h10v-1.4c-1.8 0-2.8-.7-2.8-2.1 0-.9.4-1.8 1.2-2.9l2.2-2.9 3.3 5.1c.4.5.5 1 .5 1.3 0 1.2-.8 1.5-2.6 1.5v1.4h19.2v-1.4c.1-.1 0-.1 0-.1zM92.5 16.4c1 0 1.9 2.3 4.3 2.2 2.1 0 3.4-1.4 3.4-3.5 0-2.4-1.6-4-4.1-4-2.3 0-4.2 1.4-5.8 4.2v-4.4c-5.4 2.8-9.8 5-13.5 6.3l3.1 2.2c1.3.9 1.4 1.5 1.4 2.9v9.4c0 2.6-.8 3.6-3.1 3.6H78v1.4h16.4v-1.4h-.2c-2.5 0-3.4-1.1-3.4-3.6V21.3c.1-3.3.4-4.9 1.7-4.9z"
          />
        </g>
        <path
          fill="#B5C9DB"
          className="st1"
          d="M127.8 35.4c-1.9 0-3.1-1.2-4.6-3.3l-7.3-10.5 3.5-4.3c1.8-2.2 3.3-3.5 5.7-3.6v-1.4h-9.7v1.4c1.4.3 2.1.8 2.1 1.7 0 1.1-.9 2.8-2.7 4.7l-2.4-3.4c-.5-.7-.8-1.2-.8-1.7 0-.7.6-1.1 1.7-1.3v-1.4h-11.5c.4.9.7 1.8.7 2.9 0 1.4-.4 2.6-1 3.5l4.8 7.3-4.8 5.5c-2 2.3-4 3.9-7.1 4h-.1c-2.5 0-3.4-1.1-3.4-3.6V21.5c0-3.4.3-5 1.5-5 1 0 1.9 2.3 4.3 2.2 2.1 0 3.4-1.4 3.4-3.5 0-2.4-1.6-4-4.1-4-2.3 0-4.2 1.4-5.8 4.2V11c-5.4 2.8-9.8 5-13.5 6.3l3.1 2.2c1.3.9 1.4 1.5 1.4 2.9v9.4c0 2.6-.8 3.6-3.1 3.6h-.2v1.4H106.7v-1.4c-1.8 0-2.8-.7-2.8-2.1 0-.9.4-1.8 1.2-2.9l2.2-2.9 3.3 5.1c.4.5.5 1 .5 1.3 0 1.2-.8 1.5-2.6 1.5v1.4h19.2v-1.4h.1z"
        />
      </svg>
    </WhiteLogo>
  )
}

export function DarkLogo(props) {
  return (
    <BlackLogo className={props.className ? props.className + 'logo__dark' : 'logo__dark'}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        height="100%"
        width="140px"
        viewBox="0 0 215 49"
        xmlSpace="preserve"
      >
        <path
          className="st0"
          fill="#3F474B"
          d="M1.5 36.9v-1.4h.2c2.3 0 3.1-1.1 3.1-3.6v-9.4c0-1.4-.2-2.1-1.4-2.9L.3 17.4c4.3-1.5 8.7-3.7 13.3-6.3v4.3c2-2.8 4.7-4 8.3-4 3.5 0 6 1.4 7.2 3.9 1.8-2.6 4.6-4 7.9-4 5.5 0 8.2 2.8 8.2 8.5v12.1c0 2.6.8 3.6 3.1 3.6h.2v1.4H35.6V20c0-3.3-.6-4.8-2.9-4.8-2.1 0-2.8 1.6-2.8 4.8v11.8c0 2.6.8 3.6 3.1 3.6h.2v1.4H17.1v-1.4h.2c2.3 0 3.1-1.1 3.1-3.6L20 20c0-3.3-.6-4.8-2.9-4.8-2.1 0-2.7 1.6-2.7 4.8v16.9H1.5z"
        />
        <path
          fill="#3F474B"
          className="st0"
          d="M45.8 13.7c-.2 0-.4.1-.6.1v-1.4h17.2v1.4c-1.3.1-2.1.6-2.1 1.4 0 .5.3 1.2.7 2.1l3.8 7.5 2.7-5.8c.7-1.5.9-2.7.9-3.2 0-1.4-.9-1.9-2.7-1.9v-1.4h9.5v1.4h-.1c-2.7 0-3.9 1.7-5.6 5.3L63 32.8c-4.1 8.8-8.2 15.5-13.6 15.5-3.7 0-6.1-2.1-6.1-4.8 0-2.1 1.7-3.7 3.9-3.7 1.6 0 2.8.9 4.2 1.9 1.1.8 2.1.9 3.3.9 2 0 3.5-3.9 3.5-6.9 0-2-.8-4.4-2.4-7.4L50.9 19c-1.6-3.5-2.9-5.3-5.1-5.3z"
        />
        <path
          fill="#3F474B"
          className="st1"
          d="M96.1 11.1c-2.3 0-4.2 1.4-5.8 4.2v-4.4c-5.4 2.8-9.8 5-13.5 6.3l3.1 2.2c1.3.9 1.4 1.5 1.4 2.9v9.4c0 2.6-.8 3.6-3.1 3.6H78v1.4h16.4v-1.4h-.2c-2.5 0-3.4-1.1-3.4-3.6V21.3c0-3.4.3-5 1.5-5 1 0 1.9 2.3 4.3 2.2 2.1 0 3.4-1.4 3.4-3.5.2-2.3-1.4-3.9-3.9-3.9z"
        />
        <path
          fill="#3F474B"
          className="st0"
          d="M144.2 37.9c-7.5 0-12.8-5.2-12.8-13.1 0-4 1.6-7.7 4.6-10.4 2.5-2.2 5.5-3.3 8.8-3.3 5.8 0 9.8 3.3 9.9 7.5 0 2.4-1.7 4.2-4.1 4.2-2.2 0-3.7-1.7-3.7-3.9 0-3.8-.3-5.9-3-5.9-2.5 0-3.8 2.2-3.8 6.5 0 7.9 4.6 13 10.4 13 1.9 0 3.8-1.3 6-4.5l-.9 4.5c-3.1 3.3-7.1 5.4-11.4 5.4z"
        />
        <path
          fill="#3F474B"
          className="st0"
          d="M162.1 37.8c-4.2 0-6.9-2.6-6.9-6.2 0-5.1 4.9-7.6 14.9-11.5v-1.8c0-3.6-.8-5.2-3.1-5.2-1.8 0-3.1 1.2-3.1 2.8 0 1.1.1 1.9.1 2.5 0 2.5-1.5 4.1-3.7 4.1-2 0-3.4-1.4-3.4-3.5 0-4.3 4.4-7.9 11.5-7.9 3.9 0 7.1 1.1 9 2.8 2 1.9 2.2 4.1 2.2 7.2v7.1c0 3.7.5 5.5 2 5.5.3 0 .6-.2.9-.4v1.6c-1.3 1.7-3.5 2.7-6.1 2.7-3 0-5-1.4-5.9-4-2.5 2.8-5.3 4.2-8.4 4.2zm3.3-8.2c0 2.2 1 3.5 2.3 3.5 2.3 0 2.3-2.1 2.3-6.3V22c-2.9 1.2-4.6 3.8-4.6 7.6zM185.2 8.3l-2.7-2.2c3.9-1.2 8.5-2.9 13.8-5.1v30.8c0 2.6.8 3.6 3.1 3.6h.2v1.4h-16.1v-1.4h.2c2.3 0 3.1-1.1 3.1-3.6V11.2c-.2-1.5-.4-1.9-1.6-2.9z"
        />
        <path
          fill="#3F474B"
          className="st0"
          d="M200.3 8.3l-2.7-2.2c3.9-1.2 8.5-2.9 13.8-5.1v30.8c0 2.6.8 3.6 3.1 3.6h.2v1.4h-16.1v-1.4h.2c2.3 0 3.1-1.1 3.1-3.6V11.2c-.2-1.5-.4-1.9-1.6-2.9z"
        />
        <path
          fill="#3F474B"
          className="st1"
          d="M107.5 27.4l-2.2 2.9c-.8 1.1-1.2 2.1-1.2 2.9 0 1.5.9 2.2 2.8 2.1v1.4H94.5v-1.4c3.1-.1 5-1.7 7.1-4l4.8-5.5 1.1 1.6z"
        />
        <path
          fill="#3F474B"
          className="st0"
          d="M45.8 13.7c-.2 0-.4.1-.6.1v-1.4h17.2v1.4c-1.3.1-2.1.6-2.1 1.4 0 .5.3 1.2.7 2.1l3.8 7.5 2.7-5.8c.7-1.5.9-2.7.9-3.2 0-1.4-.9-1.9-2.7-1.9v-1.4h9.5v1.4h-.1c-2.7 0-3.9 1.7-5.6 5.3L63 32.8c-4.1 8.8-8.2 15.5-13.6 15.5-3.7 0-6.1-2.1-6.1-4.8 0-2.1 1.7-3.7 3.9-3.7 1.6 0 2.8.9 4.2 1.9 1.1.8 2.1.9 3.3.9 2 0 3.5-3.9 3.5-6.9 0-2-.8-4.4-2.4-7.4L50.9 19c-1.6-3.5-2.9-5.3-5.1-5.3z"
        />
        <g>
          <path
            fill="#3F474B"
            className="st1"
            d="M127.8 35.4c-1.9 0-3.1-1.2-4.6-3.3l-7.3-10.5 3.5-4.3c1.8-2.2 3.3-3.5 5.7-3.6v-1.4h-9.7v1.4c1.4.3 2.1.8 2.1 1.7 0 1.1-.9 2.8-2.7 4.7l-2.4-3.4c-.5-.7-.8-1.2-.8-1.7 0-.7.6-1.1 1.7-1.3v-1.4h-11.5c.4.9.7 1.8.7 2.9 0 1.4-.4 2.6-1 3.5l4.8 7.3-4.8 5.5c-1.5 1.7-2.8 3.1-4.7 3.7v1.7h10v-1.4c-1.8 0-2.8-.7-2.8-2.1 0-.9.4-1.8 1.2-2.9l2.2-2.9 3.3 5.1c.4.5.5 1 .5 1.3 0 1.2-.8 1.5-2.6 1.5v1.4h19.2v-1.4c.1-.1 0-.1 0-.1zM92.5 16.4c1 0 1.9 2.3 4.3 2.2 2.1 0 3.4-1.4 3.4-3.5 0-2.4-1.6-4-4.1-4-2.3 0-4.2 1.4-5.8 4.2v-4.4c-5.4 2.8-9.8 5-13.5 6.3l3.1 2.2c1.3.9 1.4 1.5 1.4 2.9v9.4c0 2.6-.8 3.6-3.1 3.6H78v1.4h16.4v-1.4h-.2c-2.5 0-3.4-1.1-3.4-3.6V21.3c.1-3.3.4-4.9 1.7-4.9z"
          />
        </g>
        <path
          fill="#3488F8"
          className="st1"
          d="M127.8 35.4c-1.9 0-3.1-1.2-4.6-3.3l-7.3-10.5 3.5-4.3c1.8-2.2 3.3-3.5 5.7-3.6v-1.4h-9.7v1.4c1.4.3 2.1.8 2.1 1.7 0 1.1-.9 2.8-2.7 4.7l-2.4-3.4c-.5-.7-.8-1.2-.8-1.7 0-.7.6-1.1 1.7-1.3v-1.4h-11.5c.4.9.7 1.8.7 2.9 0 1.4-.4 2.6-1 3.5l4.8 7.3-4.8 5.5c-2 2.3-4 3.9-7.1 4h-.1c-2.5 0-3.4-1.1-3.4-3.6V21.5c0-3.4.3-5 1.5-5 1 0 1.9 2.3 4.3 2.2 2.1 0 3.4-1.4 3.4-3.5 0-2.4-1.6-4-4.1-4-2.3 0-4.2 1.4-5.8 4.2V11c-5.4 2.8-9.8 5-13.5 6.3l3.1 2.2c1.3.9 1.4 1.5 1.4 2.9v9.4c0 2.6-.8 3.6-3.1 3.6h-.2v1.4H106.7v-1.4c-1.8 0-2.8-.7-2.8-2.1 0-.9.4-1.8 1.2-2.9l2.2-2.9 3.3 5.1c.4.5.5 1 .5 1.3 0 1.2-.8 1.5-2.6 1.5v1.4h19.2v-1.4h.1z"
        />
      </svg>
    </BlackLogo>
  )
}
