import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'
import { NavLink } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Toolbar, makeStyles } from '@material-ui/core'

import { Container } from '../atoms/container'
import { Animation } from '../atoms/animation'
import { Button } from '../atoms/button'
import { H5, H6 } from '../atoms/text'
import { DarkLogo } from '../atoms/logo'

const LeftNav = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;

  H5 {
    margin-bottom: 0px;
    transition: 0.2s ease-in-out;
    border-bottom: 2px solid ${(props) => props.theme.colors.white};

    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.brandPrimary};
    }

    &:first-of-type {
      margin-left: 40px;
    }
  }

  a.navbar__link.navbar__link--active H5 {
    padding: 0;
    border-bottom: 2px solid ${(props) => props.theme.colors.brandPrimary};
  }
`

const RightNav = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: auto;
  align-items: center;
  z-index: 1;

  H5 {
    margin-bottom: 0px;
    transition: 0.2s ease-in-out;
    border-bottom: 2px solid ${(props) => props.theme.colors.white};
    position: relative;

    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.brandPrimary};
    }

    &:last-of-type {
      margin-left: 40px;
    }
  }

  Button {
    margin-left: 40px;
  }

  a.navbar__link.navbar__link--active H5 {
    padding: 0;
    border-bottom: 2px solid ${(props) => props.theme.colors.brandPrimary};
  }
`

const Dropdown = styled(Box)`
  position: absolute;
  text-align: right;
  padding-top: 16px;
  padding-bottom: 4px;
  top: 26px;
  right: 0px;
  width: 100%;
  z-index: 0;

  H6 {
    font-size: 16px;
    transition: 0.2s ease-in-out;
    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1.5rem 0',
    border: '0px',
    top: '0',
    '&.MuiToolbar-regular': {
      maxHeight: '64px',
    },
  },
  appBar: {
    backgroundColor: '#F3F6F9',
  },
  hide: {
    display: 'none',
  },
  closeIcon: {
    flex: 'auto',
    maxWidth: '56px',
    margin: 'auto 0px auto auto',
    justifyContent: 'flex-end',
    width: 'max-content',
  },
  header: {
    height: 'auto',
    border: '0px',
  },
}))

export function DesktopNavigation(props) {
  const { dataStore } = props
  const classes = useStyles()

  const [state, setState] = useState({
    dropdown: false,
  })

  const motionVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  }

  const showDropdown = () => {
    setState({ dropdown: true })
  }

  const hideDropdown = () => {
    setState({ dropdown: false })
  }

  return (
    <Toolbar className={classes.root}>
      <Container>
        <Flex justifyContent="space-between" width="auto">
          <LeftNav>
            <NavLink
              exact
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/"
              ariaLabel="Homepage Link"
            >
              <DarkLogo aria-label="Logo" tabIndex="1" />
            </NavLink>

            <NavLink
              exact
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/how-it-works"
            >
              <H5 light="true" aria-label="How It Works">
                How it works
              </H5>
            </NavLink>

            <NavLink
              exact
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/pricing"
            >
              <H5 light="true" aria-label="Pricing">
                Pricing
              </H5>
            </NavLink>

            <NavLink
              exact
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/reviews"
            >
              <H5 light="true" aria-label="Reviews">
                Reviews
              </H5>
            </NavLink>
          </LeftNav>

          <RightNav>
            <NavLink
              exact
              activeClassName="navbar__link--active"
              className="navbar__link"
              to="/help"
            >
              <H5 light="true" aria-label="help">
                Help
              </H5>
            </NavLink>

            {dataStore.token ? (
              <NavLink exact to="/prescriptions">
                <H5
                  semiBold="true"
                  aria-label="My Account"
                  onMouseEnter={showDropdown}
                  onMouseLeave={hideDropdown}
                  style={{ marginBottom: '-3px' }}
                >
                  My account
                  {state.dropdown === true ? (
                    <Animation
                      initial="hidden"
                      animate="visible"
                      variants={motionVariants}
                      transition={{ ease: 'easeInOut', duration: 0.4, delay: 0 }}
                    >
                      <Dropdown>
                        <H6
                          light="true"
                          aria-label="Log Out"
                          onClick={() => {
                            dataStore.logOut()
                          }}
                        >
                          Log out
                        </H6>
                      </Dropdown>
                    </Animation>
                  ) : null}
                </H5>
              </NavLink>
            ) : (
              <>
                <NavLink
                  exact
                  activeClassName="navbar__link--active"
                  className="navbar__link"
                  to="/sign-in"
                >
                  <H5 light="true" aria-label="Sign In">
                    Sign in
                  </H5>
                </NavLink>

                <NavLink to="/get-started">
                  <Button title="Get started" aria-label="Get Started" />
                </NavLink>
              </>
            )}
          </RightNav>
        </Flex>
      </Container>
    </Toolbar>
  )
}

export default inject('dataStore')(observer(DesktopNavigation))
