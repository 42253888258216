export const timeZoneOptions = [
  { value: 'America/New_York', label: 'Eastern' },
  { value: 'America/Chicago', label: 'Central' },
  { value: 'America/Phoenix', label: 'Mountain' },
  { value: 'America/Denver', label: 'Mountain (Arizona)' },
  { value: 'America/Los_Angeles', label: 'Pacific' },
  { value: 'Pacific/Honolulu', label: 'Hawaii' },
  { value: 'America/Anchorage', label: 'Alaska' },
]

export const frequencyOptions = [
  { value: 1, label: 'One time per day' },
  { value: 2, label: 'Two times per day' },
  { value: 3, label: 'Three times per day' },
  { value: 4, label: 'Four times per day' },
  { value: 5, label: 'Five times per day' },
]

export const ordinals = ['First', 'Second', 'Third', 'Fourth', 'Fifth']

export const prescriptionOptions = [
  { value: 'xanax', label: 'Xanax' },
  { value: 'adderall', label: 'Adderall' },
  { value: 'zoloft', label: 'Zoloft' },
  { value: 'solodine', label: 'Solodine' },
  { value: 'lorazapam', label: 'Lorazapam' },
]
