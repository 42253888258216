import React from 'react'
import styled from '@emotion/styled'
import ReactModal from 'react-modal'
import { Box, Flex } from '@rebass/emotion'
import { Paragraph, H1, H4 } from '../atoms/text'
import { Link } from 'react-router-dom'
import { Play, X } from 'react-feather'
import { Hidden } from '@material-ui/core'

import Animation from '../atoms/animation'
import { Theme, Mq } from '../styles/settings'
import heroImage from '../assets/images/hero__no-button.png'
import heroImageMobile from '../assets/images/hero__with-button.png'
import Video from '../atoms/video'
import { Button, AccentButton } from '../atoms/button'
import { Container } from '../atoms/container'

const CustomContainer = styled.main`
  padding-top: 2rem;
  z-index: 0;
  H1 {
    padding-top: 4rem;
  }
  p {
    color: ${(props) => props.theme.colors.black};
  }

  ${Mq.md} {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 4rem;

    H1 {
      color: ${(props) => props.theme.colors.black};
    }
    p {
      color: ${(props) => props.theme.colors.black};
      margin-bottom: 2rem;
      padding-right: 3rem;
    }
  }
`

const CustomParagraph = styled(Paragraph)`
  color: #fff;
  max-width: 510px;
  margin: 25px auto 50px 0;
  text-align: left;
`

const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;

  button {
    position: absolute;
    bottom: 114px;
    left: 27.5vw;
    width: 170px;
  }
  ${Mq.md} {
    position: relative;
    text-align: none;
    display: flex;
    margin-top: 3rem;
    width: 100%;
    justify-content: flex-end;

    button {
      left: 30%;
      width: 38%;
    }
  }
`

const LayeredSvg = styled.div`
  position: relative;
  z-index: 1;
`

const StyledCta = styled.div`
  display: block;
  align-items: center;
  :hover {
    color: ${(props) => props.theme.colors.brandPrimary};
  }

  H4 {
    margin: 2px 0 0 0;
    color: ${(props) => props.theme.colors.brandSecondary};
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) and (min-width: 961px) {
    display: block;
  }
  ${Mq.md} {
    display: flex;
  }
`

const StyledPlayButton = styled.div`
  margin: 0px 1rem 0 0;
  display: flex;
  align-items: center;
  border-width: 3px;
  border-color: ${(props) => props.borderColor};
  border-radius: 40px;
  border-style: solid;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  position: relative;

  :hover {
    border-color: ${(props) => props.theme.colors.brandSecondary};
  }

  svg {
    fill: ${(props) => props.theme.colors.brandSecondary};
    stroke: ${(props) => props.theme.colors.brandSecondary};
    position: absolute;
    left: 9px;
    top: 8px;
  }

  ${Mq.md} {
  }
`

const VideoLightbox = styled.div`
  margin: 0;
  padding-top: 2rem;

  display: flex;
  align-items: center;

  @media screen and (max-width: 1200px) and (min-width: 961px) {
    margin-left: 0px;
    margin-top: 1rem;
  }
  ${Mq.md} {
    margin: 0 0 0 2rem;
    padding-top: 0;
  }
`

const SvgContainer = styled.div`
  display: hidden;
  bottom: -46px;
  ${Mq.md} {
    position: absolute;
    display: block;
    bottom: -110px;
    padding: 0;
    right: 20px;
    border: 0px;
    z-index: 2;
  }
`

const ImageBackground = styled.div`
  width: 640px;
  height: 264px;
  left: -40px;
  background: ${(props) => props.theme.colors.brandTertiary};
  position: absolute;
  top: 40px;
  z-index: -1;

  ${Mq.md} {
    width: 584px;
    height: 350px;
    border: 0px;
    margin-right: -5rem;
    border-radius: 0px;
    margin-bottom: 4rem;
    background: ${(props) => props.theme.colors.brandTertiary};
    background-size: cover;
    border-radius: 8px;
    margin-right: ${(props) => props.margin};
    z-index: 1;
    position: relative;
  }
`

const Blob = styled.div`
  display: none;
  ${Mq.md} {
    top: -230px;
    display: block;
    right: 0px;
    z-index: -1;
    position: absolute;

    @media screen and (max-width: 1200px) and (min-width: 961px) {
      display: none;
    }
  }
`

const CloseModal = styled.div`
  position: absolute;
  top: -40px;
  right: -4px;

  svg {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    stroke: ${(props) => props.theme.colors.brandPrimary};
  }
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Theme.colors.black,
    border: '0px',
    padding: '0px',
    borderRadius: '0px',
    width: '90%',
    maxWidth: '970px',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'RGBA(54,59,61,0.8)',
    backdropFilter: 'blur(4px)',
    width: '100vw',
    zIndex: '9999',
  },
}

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  fadeInStart: {
    opacity: 0,
  },
  fadeInFinish: {
    opacity: 1,
  },
  slideInStart: {
    opacity: 0,
    x: '120px',
  },
  slideInFinish: {
    opacity: 1,
    x: '0',
  },
}

class LargeHero extends React.Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
      hover: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleToggleHover = this.handleToggleHover.bind(this)
    this.handleToggleLeave = this.handleToggleLeave.bind(this)
  }

  handleOpenModal() {
    this.setState({ showModal: true })
  }

  handleCloseModal() {
    this.setState({ showModal: false })
  }

  handleToggleHover(e) {
    this.setState({ hover: true })
  }

  handleToggleLeave() {
    this.setState({ hover: false })
  }

  render(props) {
    return (
      <CustomContainer>
        <Container>
          <Flex
            minHeight="600px"
            style={{ zIndex: '4' }}
            alignItems="center"
            flexDirection={['column', 'column', 'row']}
          >
            <Box width={1}>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
              >
                <H1>
                  Personalized reminders
                  <br />
                  to take your daily dose.
                  <br />
                </H1>
              </Animation>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
              >
                <CustomParagraph largeText>
                  Have you ever asked yourself: Did I take my pills today? We have too. That’s why we designed a MyRxCall. Get unlimited medication and vitamin reminders when you need them — how you want them. It’s like having a personal caretaker making sure that you’re staying as healthy as possible.
                </CustomParagraph>
              </Animation>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
              >
                <StyledCta>
                  <Link to="/get-started">
                    <AccentButton
                      title="Try 7 days free"
                      className="button"
                      aria-label="Free Trial"
                    />
                  </Link>
                  <VideoLightbox>
                    <StyledPlayButton
                      borderColor={
                        this.state.hover === false
                          ? Theme.colors.brandTertiary
                          : this.state.hover === true
                          ? Theme.colors.brandSecondary
                          : null
                      }
                      onClick={this.handleOpenModal}
                    >
                      <Play size={18} />
                    </StyledPlayButton>
                    <H4
                      onMouseEnter={(e) => this.handleToggleHover(e)}
                      onMouseLeave={(e) => this.handleToggleLeave(e)}
                      onClick={this.handleOpenModal}
                      name="how-it-works"
                      ariaLabel="How it works"
                      tabIndex={0}
                    >
                      How it works
                    </H4>
                  </VideoLightbox>
                </StyledCta>
              </Animation>
            </Box>
            <Box width={1}>
              <ImageContainer>
                <LayeredSvg>
                  <Animation
                    initial="fadeInStart"
                    animate="fadeInFinish"
                    variants={motionVariants}
                    transition={{ ease: 'easeInOut', duration: 1, delay: 1 }}
                  >
                    <SvgContainer>
                      <Hidden smDown>
                        <Link to="/get-started">
                          <Button title="Join now" className="button" aria-label="Join Now" />
                        </Link>
                      </Hidden>
                      <Link to="/get-started" ariaLabel="Hero Image Link">
                        <Hidden smDown>
                          <img src={heroImage} alt="Hero" ariaLabel="Hero Image" />
                        </Hidden>
                        <Hidden mdUp>
                          <img src={heroImageMobile} alt="Hero" ariaLabel="Hero Image" />
                        </Hidden>
                      </Link>
                    </SvgContainer>{' '}
                    <ImageBackground />
                  </Animation>
                </LayeredSvg>
                <Animation
                  initial="slideInStart"
                  animate="slideInFinish"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 2, delay: 1.6 }}
                >
                  <Blob>
                    <svg width="708" height="671" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M200.676 320.727s125.901-70.937 241.988-206.665c116.087-135.729 200.763-33.795 230.811 3.16 30.048 36.955 110.517 217.219-91.049 306.668C380.86 513.339 265.973 577.632 116.5 517.5 73 500 29.757 435.572 51 395c21.244-40.572 82.224-99.709 149.676-74.273z"
                        fill="#F3F6F9"
                      />
                    </svg>
                  </Blob>
                </Animation>
              </ImageContainer>
            </Box>
          </Flex>
        </Container>
        <ReactModal
          style={modalStyles}
          isOpen={this.state.showModal}
          contentLabel="Edit Prescription Modal"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModal>
            <X onClick={this.handleCloseModal} color="white" />
          </CloseModal>
          <Video />
        </ReactModal>
      </CustomContainer>
    )
  }
}

export default LargeHero
