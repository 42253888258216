import React from 'react'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import { SmallHero } from '../organisms/smallHero'
import { CtaSectionSmall } from '../molecules/ctaSection'
import VideoSection from '../molecules/videoSection'
import MetaTags from 'react-meta-tags'

const HowItWorks = (props) => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – How it works</title>
        <meta
          name="description"
          content="MyRxCall - The safest, most reliable medication reminders via text, e-mail, and phone call -- Sign Up Today!"
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription reminders for you - Safe, easy, & low cost"
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallHero
        title="How it works"
        howItWorksHero="true"
        subTitle="Stop worrying if you took your daily dose. Get personalized medication and vitamin reminders in the way that makes sense for you."
      />
      <VideoSection buttonTitle="See how simple it really is" />
      <CtaSectionSmall headline="The most reliable way to manage medications" cta="About pricing" />
    </main>
  )
}

export default HowItWorks
