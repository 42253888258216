import React from 'react'
import styled from '@emotion/styled'
import { darken } from 'polished'
import { ArrowRightCircle } from 'react-feather'
import { Link } from 'react-router-dom'

import { Mq } from '../styles/settings'
import { H2, H4, Paragraph } from '../atoms/text'

const BaseButton = styled.button`
  padding: 10px 30px 7px;
  font-size: 1.2rem;
  line-height: 19px;
  margin-left: ${(props) => props.margin};
  font-family: ${(props) => props.theme.boldFont};
  background-color: ${(props) => props.theme.colors.brandPrimary};
  border-radius: 10rem;
  color: ${(props) => props.theme.colors.white};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  border: 0px solid;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => darken(0.1, props.theme.colors.brandPrimary)};
    border-color: #fff;
  }

  padding-bottom: 12px;
  ${Mq.md} {
    padding-bottom: 8px;
  }
`

const BaseSubmitButton = styled(Button)`
  height: calc(1.8em + 1rem + 2px);
  background: transparent;
  border-radius: 40px;
  float: right;
  margin-bottom: 0px;
  margin-top: ${(props) => props.marginTop};
  color: ${(props) => props.theme.colors.brandPrimary};
  border-color: ${(props) => props.theme.colors.brandPrimary};
  border-width: 4px;

  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.brandPrimary};
    border-color: ${(props) => props.theme.colors.brandPrimary};
    border-width: 4px;
  }

  padding-bottom: 12px;
  ${Mq.md} {
    padding-bottom: auto;
  }
`

const BaseDisabledButton = styled.button`
  padding: 10px 30px 7px;
  font-size: 1.2rem;
  line-height: 19px;
  margin-left: ${(props) => props.margin};
  font-family: ${(props) => props.theme.boldFont};
  background-color: ${(props) => props.theme.colors.brandTertiary};
  border-radius: 10rem;
  color: ${(props) => props.theme.colors.white};
  transition: 0.2s ease-in-out;
  cursor: pointer;
  border: 0px solid;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
  }

  padding-bottom: 12px;
  ${Mq.md} {
    padding-bottom: 8px;
  }
`

const BaseDisabledAccentButton = styled(BaseButton)`
  height: calc(1.8em + 1rem + 2px);
  border: 4px solid ${(props) => props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.middleGrey};
  background: transparent;
  border-radius: 40px;
  float: right;
  margin-bottom: 0px;
  margin-top: ${(props) => props.marginTop};

  &:hover,
  &:focus,
  &:active {
    background: transparent;
    border: 4px solid ${(props) => darken(0.05, props.theme.colors.lightGrey)};
    color: ${(props) => props.theme.colors.middleGrey};
  }

  padding-bottom: 12px;
  ${Mq.md} {
    padding-bottom: 8px;
  }
`

const BaseJumboButton = styled.button`
  height: 56px;
  font-size: 1rem;
  line-height: 19px;
  width: 100%;
  margin-left: ${(props) => props.margin};
  font-family: ${(props) => props.theme.boldFont};
  background-color: ${(props) => props.theme.colors.lightBlue};
  border-radius: 10rem;
  border: 4px solid ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.brandSecondary};
  transition: 0.2s ease-in-out;
  cursor: pointer;
  outline: none;

  ${(props) =>
    props.active ? 'border: 4px solid ' + props.theme.colors.brandTertiary + ';' : null}

  &:hover {
    border: 4px solid ${(props) => props.theme.colors.brandTertiary};
  }

  &:active,
  &:focus {
    border: 4px solid ${(props) => props.theme.colors.brandSecondary};
  }
`

const BaseMembershipButton = styled.button`
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
  position: relative;
  font-family: ${(props) => props.theme.boldFont};
  background-color: ${(props) => props.theme.colors.lightBlue};
  border-radius: 1rem;
  border: 4px solid ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.brandSecondary};
  transition: 0.2s ease-in-out;
  cursor: pointer;
  outline: none;

  p {
    color: ${(props) => props.theme.colors.brandSecondary};
    font-family: ${(props) => props.theme.brandFont};
    margin: 0;
  }

  ${(props) => (props.active ? 'border: 4px solid ' + props.theme.colors.brandPrimary + ';' : null)}

  &:hover {
    border: 4px solid ${(props) => props.theme.colors.brandSecondary};
  }

  &.reactivate {
    padding: 2rem 0px;
  }

  &:active,
  &:focus {
    border: 4px solid ${(props) => props.theme.colors.brandPrimary};
  }
  ${Mq.md} {
    padding: 2rem;
  }
`
const StyledPrice = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  H2 {
    font-size: 3rem;
    color: ${(props) => props.theme.colors.brandSecondary};
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
  }

  H4 {
    color: ${(props) => props.theme.colors.brandTertiary};
    margin-top: -8px;
    margin-right: 12px;
  }
`
const Highlight = styled.div`
  position: absolute;
  top: -16px;
  left: calc(50% - 50px);
  width: 100px;
  padding: 4px 0 2px;
  text-align: center;
  background: #fff;
  border-radius: 40px;
  border: 3px solid ${(props) => props.theme.colors.brandPrimary};
`

const BaseAccentButton = styled(BaseButton)`
  background-color: initial;
  font-family: ${(props) => props.theme.boldFont};
  color: ${(props) => props.theme.colors.brandPrimary};
  border-color: ${(props) => props.theme.colors.brandPrimary};
  border-width: 3px;

  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.brandPrimary};
    border-color: ${(props) => props.theme.colors.brandPrimary};
    border-width: 3px;
  }

  &.disabled {
    color: ${(props) => props.theme.colors.brandTertiary};
    background-color: transparent;
    border-color: ${(props) => props.theme.colors.brandTertiary};
    border-width: 3px;
  }

  padding-bottom: 12px;
  ${Mq.md} {
    padding-bottom: 8px;
  }
`

const BaseTertiaryButton = styled(BaseButton)`
  background-color: initial;
  color: ${(props) => props.theme.colors.white};
  border-color: ${(props) => props.theme.colors.brandPrimary};
  border-width: 3px;

  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.colors.white};
    background-color: transparent;
    border-color: ${(props) => props.theme.colors.brandTertiary};
    border-width: 3px;
  }

  padding-bottom: 12px;
  ${Mq.md} {
    padding-bottom: 8px;
  }
`

const BaseArrowButton = styled.div`
  background-color: initial;
  position: relative;
  margin-top: 40px;
  max-width: max-content;

  H4 {
    color: ${(props) => props.theme.colors.brandPrimary};
    padding-right: 10px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
      stroke: 2px solid ${(props) => props.theme.colors.brandTertiary};
      transition: 0.5s ease-in-out;
      margin-top: -2px;
    }

    &:hover,
    &:focus,
    &:active {
      svg {
        transform: translateX(3px);
        color: ${(props) => props.theme.colors.brandTertiary};
      }
    }
  }
`

export function Button(props) {
  return props.variant === 'disabled' ? (
    <BaseDisabledButton
      className={props.className}
      disabled={props.disabled ? props.disbaled : null}
      type={props.type}
      {...props}
    >
      {props.title}
    </BaseDisabledButton>
  ) : (
      <BaseButton className={props.className} type={props.type} {...props}>
        {props.title}
      </BaseButton>
    )
}

export function SubmitButton(props) {
  return props.variant === 'disabled' ? (
    <BaseDisabledAccentButton className={props.className} type={props.type} {...props}>
      {props.title}
    </BaseDisabledAccentButton>
  ) : (
      <BaseSubmitButton
        className={props.className}
        onClick={props.onClick}
        type={props.type}
        {...props}
        ariaLabel="Submit"
      >
        {props.title}
      </BaseSubmitButton>
    )
}

export function AccentButton(props) {
  return (
    <BaseAccentButton
      className={
        props.disabled
          ? 'brand-button brand-button--accent disabled'
          : 'brand-button brand-button--accent'
      }
      variant="secondary"
      type={props.type}
      name={props.name}
      disabled={props.disabled ? props.disbaled : null}
      ariaLabel="Submit"
      {...props}
    >
      {props.title}
    </BaseAccentButton>
  )
}

export function TertiaryButton(props) {
  return (
    <BaseTertiaryButton
      className="brand-button brand-button--tertiary"
      variant="secondary"
      type={props.type}
      disabled={props.disabled ? props.disbaled : null}
    >
      {props.title}
    </BaseTertiaryButton>
  )
}

export function ArrowButton(props) {
  return (
    <BaseArrowButton
      className="brand-button brand-button--play"
      variant="secondary"
      type={props.type}
      onClick={props.onClick}
    >
      <Link to={props.link} ariaLabel={props.title}>
        <H4>
          {props.title}
          <ArrowRightCircle strokeWidth={2} size="24px" />
        </H4>
      </Link>
    </BaseArrowButton>
  )
}

export function JumboButton(props) {
  return (
    <BaseJumboButton
      className={props.className}
      type={props.type}
      onClick={props.onClick}
      ariaLabel="Select"
      {...props}
    >
      {props.title}
    </BaseJumboButton>
  )
}

export function MembershipButton(props) {
  return (
    <BaseMembershipButton
      className={props.reactivate ? 'reactivate' : props.className}
      type={props.type}
      onClick={props.onClick}
      ariaLabel="Select Membership"
      {...props}
    >
      {props.highlight ? <Highlight>{props.highlight}</Highlight> : null}
      <Paragraph>{props.valueProp}</Paragraph>
      <StyledPrice>
        <H4>$</H4>
        <H2>{(props.price / 100).toFixed(2)}</H2>
      </StyledPrice>

      <Paragraph>{props.frequency}</Paragraph>
    </BaseMembershipButton>
  )
}
