import React from 'react'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import { CtaSectionSmall } from '../molecules/ctaSection'
import { SmallHero } from '../organisms/smallHero'
import MetaTags from 'react-meta-tags'

const NoMatch = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – 404</title>
        <meta
          name="description"
          content="Introducing MyRxCall - the most reliable way to manage medications. Receieve personalized medication reminders via phone call, SMS, or e-mail."
        />
        <meta
          property="og:title"
          content="MyRxCall – The most reliable way to manage medications."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallHero
        title="Oops!"
        noMatchHero="true"
        subTitle="It looks like you landed on a page that does not exist. Please make sure you entered the correct URL. Better luck in your search next time!"
      />
      <CtaSectionSmall
        headline="Or, you can start over by visiting our home page"
        cta="Back to home"
      />
    </main>
  )
}

export default NoMatch
