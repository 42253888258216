import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'

import { Mq } from '../styles/settings'
import { Animation } from '../atoms/animation'
import { Container } from '../atoms/container'
import {
  HowItWorksHeroSubContent,
  PricingHeroSubContent,
  ReviewsHeroSubContent,
  HelpHeroSubContent,
  GetStartedHeroSubContent,
  SupportHeroSubContent,
  AboutHeroSubContent,
  NoMatchHeroSubContent,
} from '../molecules/heroSubContent'
import { H1, H3, Paragraph } from '../atoms/text'

const CustomContainer = styled.div`
  display: block;
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding-top: 8rem;
  padding-bottom: 3rem;

  p {
    margin-bottom: 0px;
    padding-bottom: 0;
    ${Mq.md} {
      padding-bottom: ${(props) => props.paddingBottom};
    }
  }

  H3 {
    margin-top: 12px;
    margin-bottom: 0px;
    paddng: 0;
  }

  ${Mq.md} {
    display: flex;
    padding-top: 12rem;
    padding-bottom: 5rem;
  }
`

export function SmallHero(props) {
  const variants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    fadeIn: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }
  return (
    <CustomContainer>
      <Container>
        <Flex flexWrap="nowrap" alignItems="flex-end" flexDirection={['column', 'column', 'row']}>
          <Box width={1} pr="5%">
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
            >
              <H1>{props.title}</H1>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
            >
              <Paragraph styles={props.paddingBottom}>{props.subTitle}</Paragraph>
            </Animation>
            {props.extraTitle ? (
              <Animation
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
              >
                <H3>{props.extraTitle}</H3>
              </Animation>
            ) : null}
          </Box>
          {props.howItWorksHero ? <HowItWorksHeroSubContent /> : null}
          {props.pricingHero ? <PricingHeroSubContent /> : null}
          {props.reviewsHero ? <ReviewsHeroSubContent /> : null}
          {props.helpHero ? <HelpHeroSubContent /> : null}
          {props.supportHero ? <SupportHeroSubContent /> : null}
          {props.getStartedHero ? <GetStartedHeroSubContent /> : null}
          {props.aboutUsHero ? <AboutHeroSubContent /> : null}
          {props.noMatchHero ? <NoMatchHeroSubContent /> : null}
        </Flex>
      </Container>
    </CustomContainer>
  )
}
