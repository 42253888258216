import React from 'react'
import styled from '@emotion/styled'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import MetaTags from 'react-meta-tags'

import { Mq } from '../styles/settings'
import { SmallContainer } from '../atoms/container'
import { Animation } from '../atoms/animation'
import { H1 } from '../atoms/text'
import CreateMembership from '../organisms/createMembership'

export const CustomContainer = styled.main`
  padding: 7rem 0 4rem;

  ${Mq.md} {
    padding: 10rem 0;
  }
`

export const CustomHeader = styled.main`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  H4 {
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;

    :hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const CreateAccount = (props) => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Create account</title>
        <meta
          name="description"
          content="Introducing MyRxCall - the most reliable way to manage medications. Receieve personalized medication reminders via phone call, SMS, or e-mail."
        />
        <meta property="og:title" content="MyRxCall – Create account" />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallContainer>
        <CustomContainer>
          <CustomHeader>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
            >
              <H1>Set up your membership</H1>
            </Animation>
          </CustomHeader>
          <CreateMembership />
        </CustomContainer>
      </SmallContainer>
    </main>
  )
}

export default CreateAccount
