import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import styled from '@emotion/styled'
import { Box } from '@rebass/emotion'
import { NavLink } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Mq } from '../styles/settings/theme'
import { Toolbar, makeStyles, Drawer, IconButton } from '@material-ui/core'
import { XCircle, Menu } from 'react-feather'

import { Container } from '../atoms/container'
import { H1, H5 } from '../atoms/text'
import { DarkLogo } from '../atoms/logo'

const MobileNavWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LeftNav = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;

  H5 {
    margin-bottom: 0px;
    transition: 0.2s ease-in-out;
    border-bottom: 2px solid ${(props) => props.theme.colors.white};

    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.brandPrimary};
    }

    &:first-of-type {
      margin-left: 40px;
    }
  }

  a.navbar__link.navbar__link--active H5 {
    color: ${(props) => props.theme.colors.brandPrimary};
  }
`

const RightNav = styled(Box)`
  z-index: 1;

  H5 {
    margin-bottom: 0px;
    text-transform: capitalize;
    transition: 0.2s ease-in-out;
    border-bottom: 2px solid ${(props) => props.theme.colors.white};
    position: relative;

    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.brandPrimary};
    }

    &:last-of-type {
      margin-right: 40px;
    }
  }

  a.navbar__link.navbar__link--active H5 {
    color: ${(props) => props.theme.colors.brandPrimary};
  }
`

const StyledDrawer = styled(Drawer)`
  flex-shrink: 0;
  padding-left: 1rem;
  width: 80%;

  H1 {
    font-size: 40px;
    margin: 1rem 0;
    min-width: 250px;
  }

  H5 {
    width: 100%;
    text-align: center;
    font-size: 24px;
    min-width: 250px;
    padding-bottom: 0.5rem;
  }
`

const DrawerHeader = styled(Box)`
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 4;
  right: 0;
`

const StyledDivider = styled(Box)`
  width: 100%;
  text-align: center;
  padding: 0 0 0;

  H1 {
    color: ${(props) => props.theme.colors.brandTertiary};
  }

  ${Mq.md} {
    padding: 1.5rem 0 0;
  }
`

const StyledList = styled(Box)`
  padding: 0 1rem;
  width: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  svg {
    ${Mq.md} {
      display: none;
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0.75rem 0',
    border: '0px',
    top: '0',
    height: '3rem',

    '& .MuiDrawer-paper': {
      width: '100%',
    },
  },
  appBar: {
    backgroundColor: '#F3F6F9',
  },
  hide: {
    display: 'none',
  },
  closeIcon: {
    flex: 'auto',
    maxWidth: '56px',
    margin: '0px 8px auto auto',
    justifyContent: 'flex-end',
    width: 'max-content',
  },
  header: {
    width: '100%',
    border: '0px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    height: '3rem',
  },
}))

export function MobileNavigation(props) {
  const { dataStore } = props
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [false])

  return (
    <Toolbar className={classes.root}>
      <Container>
        <MobileNavWrapper>
          <LeftNav>
            <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/">
              <DarkLogo />
            </NavLink>
          </LeftNav>

          <RightNav>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="end"
              className={clsx(open && classes.hide)}
            >
              <Menu />
            </IconButton>

            <StyledDrawer variant="persistent" anchor="right" open={open}>
              <DrawerHeader className={clsx(open && classes.header)}>
                <IconButton onClick={() => setOpen(false)} className={clsx(classes.closeIcon)}>
                  <XCircle size={32} color="#000" />
                </IconButton>
              </DrawerHeader>
              <StyledList>
                {dataStore.token ? null : (
                  <NavLink
                    exact
                    onClick={() => setOpen(false)}
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    to="/get-started"
                  >
                    <H5 light="true" className="cta">
                      Get started
                    </H5>
                  </NavLink>
                )}
                <NavLink
                  exact
                  onClick={() => setOpen(false)}
                  activeClassName="navbar__link--active"
                  className="navbar__link"
                  to="/how-it-works"
                >
                  <H5 light="true">How it works</H5>
                </NavLink>
                <NavLink
                  exact
                  onClick={() => setOpen(false)}
                  activeClassName="navbar__link--active"
                  className="navbar__link"
                  to="/pricing"
                >
                  <H5 light="true">Pricing</H5>
                </NavLink>
                <NavLink
                  exact
                  onClick={() => setOpen(false)}
                  activeClassName="navbar__link--active"
                  className="navbar__link"
                  to="/reviews"
                >
                  <H5 light="true">Reviews</H5>
                </NavLink>
                <NavLink
                  exact
                  onClick={() => setOpen(false)}
                  activeClassName="navbar__link--active"
                  className="navbar__link"
                  to="/about-us"
                >
                  <H5 light="true">About us</H5>
                </NavLink>
                <NavLink
                  exact
                  onClick={() => setOpen(false)}
                  activeClassName="navbar__link--active"
                  className="navbar__link"
                  to="/help"
                >
                  <H5 light="true">Help</H5>
                </NavLink>
                <NavLink
                  exact
                  onClick={() => setOpen(false)}
                  activeClassName="navbar__link--active"
                  className="navbar__link"
                  to="/contact"
                >
                  <H5 light="true">Contact us</H5>
                </NavLink>
                <StyledDivider>
                  <H1>•  •  •</H1>
                </StyledDivider>

                {dataStore.token ? (
                  <>
                    <NavLink exact to="/prescriptions">
                      <H5 semiBold="true" aria-label="My Account" onClick={() => setOpen(false)}>
                        My account
                      </H5>
                    </NavLink>
                    <H5
                      light="true"
                      aria-label="Log Out"
                      semiBold="true"
                      onClick={() => {
                        dataStore.logOut()
                      }}
                    >
                      Log out
                    </H5>
                  </>
                ) : (
                  <>
                    <NavLink
                      exact
                      onClick={() => setOpen(false)}
                      activeClassName="navbar__link--active"
                      className="navbar__link"
                      to="/sign-in"
                    >
                      <H5 light="true">Sign in</H5>
                    </NavLink>
                    <NavLink
                      exact
                      onClick={() => setOpen(false)}
                      activeClassName="navbar__link--active"
                      className="navbar__link"
                      to="/support-request"
                    >
                      <H5 light="true">Support</H5>
                    </NavLink>
                  </>
                )}
              </StyledList>
            </StyledDrawer>
          </RightNav>
        </MobileNavWrapper>
      </Container>
    </Toolbar>
  )
}

export default inject('dataStore')(observer(MobileNavigation))
