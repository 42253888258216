import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'

import { H5, Paragraph } from '../atoms/text'

const ReviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  width: 100%;
  padding: 30px 40px;
  background: ${(props) => props.theme.colors.lightBlue};

  p {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
`

const ReviewQuote = styled.div`
  text-align: left;
  width: 100%;
  display: flex;
`

const Avatar = styled.div`
  width: 52px;
  margin-right: 0px;
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-bottom: -12px;

  img {
    border-radius: 52px;
    border: 3px solid ${(props) => props.theme.colors.brandTertiary};
  }
`

const ReviewPerson = styled.div`
  display: flex;
  align-items: center;

  H5 {
    padding: 20px 0 0 0;
    margin: 0;
  }
`

export function Review(props) {
  return (
    <ReviewContainer>
      <ReviewQuote>
        <Paragraph>"{props.quote}"</Paragraph>
      </ReviewQuote>
      <ReviewPerson>
        <Flex width={1} justifyContent="space-between" alignItems="center">
          <Box width={1}>
            <H5>{props.name}</H5>
            <Paragraph>{props.location}</Paragraph>
          </Box>
          <Box width={1}>
            <Avatar>
              <img src={props.image} alt="User Review" />
            </Avatar>
          </Box>
        </Flex>
      </ReviewPerson>
    </ReviewContainer>
  )
}
