import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { FormGroup } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { CheckCircle } from 'react-feather'
import * as emailjs from 'emailjs-com'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { Theme, Mq } from '../styles/settings'
import { AccentButton } from '../atoms/button'
import { TextInput } from '../atoms/input'
import { Animation } from '../atoms/animation'
import { H2, H5, Paragraph } from '../atoms/text'
import { Container } from '../atoms/container'
import { Dropdown } from '../atoms/dropdown'

const ContactFormContainer = styled.div`
  background: ${(props) => props.theme.colors.lightBlue};
  padding: 4rem 1rem;
  margin-top: 60px;

  p {
    max-width: 600px;
    margin-bottom: 32px;
  }

  Button {
    margin-top: 16px;
  }

  ${Mq.md} {
    padding: 60px;
  }
`

const SuccessAlert = styled.div`
  display: flex;
  position: absolute;
  width: 230px;
  right: 120px;
  bottom: 8px;
  align-items: center;

  H5 {
    margin: 0px 0 -12px;
    padding-right: 1rem;
    display: inline-flex;
    color: ${(props) => props.theme.colors.success};
  }
`

const ErrorAlert = styled.div`
  display: flex;
  position: absolute;
  width: 230px;
  right: 120px;
  bottom: 8px;
  align-items: center;
  color: ${(props) => props.theme.colors.error};

  H5 {
    margin: 0px 0 -12px;
    padding-right: 1rem;
    display: inline-flex;
  }
`

const SubmitBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 1.5rem;
    margin-top: 0;
    margin-bottom: -10px;
  }

  ${Mq.md} {
    margin-top: 0rem;
    justify-content: space-between;
  }
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const contactOptions = [
  { value: 'generalQuestion', label: 'General question' },
  { value: 'websiteIssue', label: 'Website issue' },
  { value: 'membership', label: 'Memberships' },
  { value: 'pricing', label: 'Pricing' },
  { value: 'billing', label: 'Billing' },
]

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
})

const recaptchaRef = React.createRef()

class ContactForm extends Component {
  state = {
    full_name: '',
    email_address: '',
    request: '',
    message: '',
    success: false,
  }

  handleChange = (e, param) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value })
    this.setState({ [param]: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { full_name, email_address, request, message } = this.state
    let templateParams = {
      from_name: email_address,
      to_name: 'MyRxCall',
      full_name: full_name,
      email_address: email_address,
      request: request,
      message: message,
    }
    emailjs.send('gmail', 'template_TMn5sKc5', templateParams, 'user_z1tmk4VoWtql1VydKuiAp')
    this.resetForm()
  }

  onChange(value) {
    console.log('Captcha value:', value)
  }
  onSubmit = () => {
    const recaptchaValue = recaptchaRef.current.getValue()
    this.props.onSubmit(recaptchaValue)
  }

  resetForm() {
    this.setState({
      full_name: '',
      email_address: '',
      request: '',
      message: '',
      success: true,
    })
  }

  render() {
    const { dataStore } = this.props
    const { full_name, email_address, message } = this.state
    const isEnabled = full_name.length > 0 && email_address.length > 0 && message.length > 0

    return (
      <Container>
        <ContactFormContainer>
          <p>{dataStore.data}</p>
          {this.props.hideHeader ? null : (
            <>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
              >
                <H2>How can we help?</H2>
              </Animation>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
              >
                <Paragraph>
                  Enter your name, email and what your request is and a member from our team will
                  get back to you within 24 business hours.
                </Paragraph>
              </Animation>
            </>
          )}
          {/* WIP --- ICEBOX --- Get the Formik error to show up instead of system error */}
          <Formik initialValues={{ email_address: '' }} validationSchema={validationSchema}>
            {() => (
              <Form
                onSubmit={(e) => {
                  this.handleSubmit(e)
                }}
              >
                <FormGroup>
                  <Animation
                    initial="hidden"
                    animate="visible"
                    variants={motionVariants}
                    transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
                  >
                    <Flex mr={-2} ml={-2} flexDirection={['column', 'column', 'row']}>
                      <Box width={1} pr={2} pl={2}>
                        <H5>What's your name?</H5>
                        <TextInput
                          placeholder="Add your first and last name"
                          description="Full Name"
                          type="text"
                          name="full_name"
                          value={this.state.full_name}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Box>

                      <Box width={1} pr={2} pl={2}>
                        <H5>And your e-mail address?</H5>
                        <TextInput
                          placeholder="Enter your e-mail address"
                          description="Email address"
                          type="email"
                          name="email_address"
                          value={this.state.email_address}
                          email={true}
                          required
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Box>
                    </Flex>

                    <H5>What is this request regarding?</H5>
                    <Dropdown
                      placeholder="Please select from this list"
                      name="request"
                      description="What is the request?"
                      type="dropdown"
                      margin="24"
                      options={contactOptions}
                      onChange={(e) => {
                        this.setState({ request: e.value })
                      }}
                    />
                    <H5 style={{ marginTop: '12px' }}>Add your message</H5>
                    <TextInput
                      placeholder="Add your message"
                      description="Add your message"
                      type="text"
                      name="message"
                      value={this.state.message}
                      required
                      onChange={(e) => this.handleChange(e)}
                    />

                    <Flex justifyContent="flex-end">
                      <SubmitBox width={1} textAlign="right">
                        {this.state.success === true ? (
                          <SuccessAlert>
                            <Animation
                              initial="fadeIn"
                              animate="visible"
                              style={{ width: '300px' }}
                              variants={motionVariants}
                              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
                            >
                              <H5 className="success">Message Received</H5>
                              <CheckCircle color={Theme.colors.success} size={32} />
                            </Animation>
                          </SuccessAlert>
                        ) : null}

                        <ErrorAlert>
                          <Animation
                            initial="fadeIn"
                            animate="visible"
                            style={{ width: '300px' }}
                            variants={motionVariants}
                            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
                          >
                            <ErrorMessage name="email" component="div" />
                          </Animation>
                        </ErrorAlert>

                        <AccentButton title="Submit" type="submit" disabled={!isEnabled} />
                      </SubmitBox>
                    </Flex>
                  </Animation>
                  {/* WIP --- ICEBOX --- Make recaptcha work
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lc2wcwZAAAAACAsBKEY2bQTYwuIrSMtmJiPqCeV"
                    onChange={this.onChange}
                    size="compact"
                  />
                  */}
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ContactFormContainer>
      </Container>
    )
  }
}

export default inject('dataStore')(observer(ContactForm))
