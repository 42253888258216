import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Flex } from '@rebass/emotion'
import { makeStyles } from '@material-ui/core/styles'
import { FormGroup } from '@material-ui/core'

import { Mq } from '../styles/settings'
import { H2, H5, Paragraph } from '../atoms/text'
import { AccentButton } from '../atoms/button'
import { Animation } from '../atoms/animation'
import { TextInput } from '../atoms/input'

// const moment = require('moment')

const UpdateMembershipFormContainer = styled.div``

const FormContainer = styled.div`
  padding: 0px;
  ${Mq.md} {
    padding: 0 4rem;
  }

  p {
    margin-bottom: 16px;
    padding: 16px 0 0 0;
    font-size: 1.2rem;
  }

  H2 {
    text-align: center;
  }

  Button {
    margin-bottom: 0px;
    float: right;
    z-index: 3;
  }
`

const MembershipHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  align-items: center;

  H2 {
    padding-right: 30px;
    min-width: max-content;
  }

  H4 {
    text-align: right;
    padding-left: 32px;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  svg {
    margin-left: 32px;
  }
`

const MembershipButtonHeader = styled.div`
  display: block;
  width: 100%;

  H5 {
    margin: 0;
    text-align: left;
    padding-bottom: 16px;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  ${Mq.md} {
    display: flex;
    align-items: center;
    justify-content: space-between;

    H5 {
      text-align: right;
      padding-bottom: 0px;
    }
  }
`

const RemoveCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -4px;

  p {
    padding: 0;
    margin: 0 24px 0 0;
  }

  H5.link {
    margin: 0 16px -6px 0;
    text-align: right;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
`

const SavedCardField = styled.div`
  position: relative;

  H5.link {
    margin: 0 8px -6px 0;
    position: absolute;
    right: 16px;
    top: 14px;
    text-align: right;
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
`

const StripeContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 40px 16px;
  margin-bottom: 24px;
`

const DividerLine = styled.div`
  display: none;
  ${Mq.md} {
    display: block;
    height: 2px;
    margin-bottom: 7px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.brandTertiary};
  }
`

const Buttons = styled.div`
  text-align: right;
  margin: 24px 0 0 auto;
`

// const ordinals = ['First', 'Second', 'Third', 'Fourth', 'Fifth']

const useStyles = makeStyles((theme) => ({
  root: {},
  textField: {
    background: 'white',
    width: '100%',
    margin: '0',
  },
  margin: {
    margin: '0',
    width: '50%',
    border: '0px',
    borderRadius: '0px',
  },
}))

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

export function UpdateMembershipForm(props) {
  const classes = useStyles()

  const [state, setState] = useState({
    removeCard: false,
    addCard: false,
    paymentDate: false,
  })

  const handleRemoveCard = (event) => {
    setState({ removeCard: true })
  }

  const hideRemoveCard = (event) => {
    setState({ addCard: false })
  }

  const handleAddCard = (event) => {
    setState({ addCard: true })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <UpdateMembershipFormContainer className={classes.root}>
      <FormContainer>
        <Animation
          initial="fadeIn"
          animate="visible"
          variants={motionVariants}
          exit="hidden"
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
        >
          <MembershipHeader>
            <H2>{props.title}</H2>
            <DividerLine />
          </MembershipHeader>
        </Animation>

        <FormGroup>
          <Animation
            initial="fadeIn"
            animate="visible"
            variants={motionVariants}
            exit="hidden"
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
          >
            <MembershipButtonHeader>
              <Paragraph>Update your billing information</Paragraph>
              <H5 className="link" onClick={handleAddCard}>
                Add Another Card
              </H5>
            </MembershipButtonHeader>
            {state.addCard ? (
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                exit="hidden"
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
              >
                <StripeContainer>Add Stripe Here for New Card</StripeContainer>
              </Animation>
            ) : null}
            <SavedCardField>
              <TextInput disabled="true" value="Card ending in 1234" placeholder="Add a payment" />
              <H5 className="link" onClick={handleRemoveCard}>
                Remove
              </H5>
            </SavedCardField>
            {state.removeCard ? (
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                exit="hidden"
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
              >
                <RemoveCard>
                  <Paragraph>Are you sure?</Paragraph>
                  <H5 className="link">Yes</H5>
                  <H5 className="link" onClick={hideRemoveCard}>
                    No
                  </H5>
                </RemoveCard>
              </Animation>
            ) : null}

            <MembershipButtonHeader>
              <Paragraph>Payment date</Paragraph>
            </MembershipButtonHeader>

            <SavedCardField>
              <TextInput
                disabled="true"
                value="The 13th of every month"
                placeholder="No active membership"
              />
            </SavedCardField>

            {/*  WIP --- ICEBOX --- Allow user to update payment date
           <H5 className="link" onClick={handlePaymentDate}>
              Change Date
            </H5>
          {state.paymentDate ? (
            <NewPaymentDate>
              <H6>Select a new payment date</H6>
              <DatePicker />
            </NewPaymentDate>
          ) : null} */}

            <Flex width={1}>
              <Buttons>
                {/* WIP --- ICEBOX --- Make buttons update/create prescriptions - Make disabled toggle off when form is full */}
                {props.updatePrescription ? (
                  <AccentButton
                    title="Update"
                    disabled={state.prescriptionComplete ? null : 'disabled'}
                  />
                ) : null}
                {props.newPrescription ? (
                  <AccentButton
                    title="Add Prescription"
                    disabled={state.prescriptionComplete ? null : 'disabled'}
                    onClick={(e) => {
                      handleSubmit(e)
                    }}
                  />
                ) : null}
              </Buttons>
            </Flex>
          </Animation>
        </FormGroup>
      </FormContainer>
    </UpdateMembershipFormContainer>
  )
}

export default UpdateMembershipForm
