import React from 'react'
import styled from '@emotion/styled'
import MetaTags from 'react-meta-tags'

import { Mq } from '../styles/settings'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import { SmallHero } from '../organisms/smallHero'
import { CtaSectionSmall } from '../molecules/ctaSection'
import { Container } from '../atoms/container'
import { H3, H4, Paragraph } from '../atoms/text'
import { Link } from 'react-router-dom'

const LegalCopy = styled.div`
  display: block;
  padding-top: 80px;

  p {
    padding-bottom: 24px;
    max-width: 100%;

    ${Mq.md} {
      max-width: 66.66%;
    }
  }

  H4 {
    margin-top: 40px;
  }
`

const TermsOfService = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Terms of service</title>
        <meta
          name="description"
          content="Introducing MyRxCall - the most reliable way to manage medications. Receieve personalized medication reminders via phone call, SMS, or e-mail."
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription reminders for you - Safe, easy, & low cost."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallHero title="Terms of service" subTitle="Last updated: July 23, 2020" />
      <LegalCopy>
        <Container>
          <H3>MyRxCall TERMS OF SERVICE</H3>

          <H4>1. Acceptance of Terms of Service</H4>
          <Paragraph>
            PLEASE READ THE TERMS OF SERVICE AGREEMENT (“AGREEMENT” OR“TERMS OF SERVICE”) CAREFULLY
            BEFORE YOU START TO USE THE WEBSITEOR SERVICES OFFERED BY MYRXCALL, INC. (“MYRXCALL” OR
            THE“COMPANY”). THE TERMS OF SERVICE SET FORTH THE LEGALLY BINDINGTERMS AND CONDITIONS
            FOR YOUR USE OF THE WEBSITEATWWW.MYRXCALL.COM(“WEBSITE”) AND SERVICES PROVIDED
            BYMYRXCALL, INCLUDING ALL MEDICATION REMINDER SERVICES OFFEREDVIA THE WEBSITE,
            TELEPHONE, TEXT MESSAGE, E-MAIL, OR ANY OTHERMEDIUM THROUGH WHICH SERVICES ARE OFFERED
            (COLLECTIVELY,“SERVICES”).{' '}
          </Paragraph>
          <Paragraph>
            BY USING THIS WEBSITE OR THE SERVICES, YOU ACCEPT AND AGREE TO BEBOUND AND ABIDE BY THE
            TERMS OF SERVICE.THESE TERMS OF SERVICEAPPLY TO ALL USERS AND SUBSCRIBERS OF MYRXCALL.
            IF YOU DO NOTWANT TO AGREE TO THESE TERMS OF SERVICE, YOU MUST NOT ACCESS ORUSE THE
            WEBSITE OR OUR SERVICES.AS A CONDITION OF USING MYRXCALL,YOU AGREE NOT TO USE ITS
            CONTENTS OR INFORMATION FOR ANYCOMMERCIAL OR NON-PERSONAL PURPOSE, WHETHER DIRECTLY
            ORINDIRECTLY.
          </Paragraph>
          <Paragraph>
            This Agreement is effective on July 1, 2020 for current users and as of the first use of
            theWebsite or Services by new users. This Agreement is between You (the “User” or
            “You”)and MyRxCall. Any User who is found to be in violation of the following Agreement
            andany other policies posted on the Website may be banned, at the sole discretion
            ofMyRxCall, from using the Website or any Services.
          </Paragraph>

          <H4>2. Intended Users.</H4>
          <Paragraph>
            MyRxCall is offered and available to Users who are 18 years of age or older. Individuals
            under the age of 18 must at all times use our Services only in conjunction with and
            under the supervision of a parent or legal guardian who is at least 18 years of age. In
            all cases, the adult is the User and is responsible for any and all activities of any
            minors. IF YOU ARE A PARENT OR GUARDIAN THAT PROVIDES CONSENT TO A MINOR’S REGISTRATION
            WITH AND USE OF THE SERVICES AND WEBSITE, YOU AGREE TO BE BOUND BY THESE TERMS.
          </Paragraph>
          <Paragraph>
            By using this Website or the Services, You represent and warrant that You are of legal
            age to form a binding contract with MyRxCall and that: (a) You are at least 18 years of
            age or older, (b) You are located and reside in the United States; (c) all registration
            information You submit is accurate and truthful; and (d) if You are acting on behalf of
            a legal or business entity, You are authorized to bind that entity to the Agreement. If
            You do not meet all of these requirements, You must not access or use the Website or
            Services.
          </Paragraph>
          <Paragraph>
            For the avoidance of doubt, MyRxCall’s Services are not targeted towards, nor intended
            for use by, individuals under the age of 13. If You are not at least 13 years of age, do
            not access, use or register for an Account.
          </Paragraph>

          <H4>3. Personal Information and Privacy.</H4>
          <Paragraph>
            By using the Website or Services, You agree to MyRxCall’s privacy policy available at
            [insert link] (the “Privacy Policy”), which is incorporated herein by reference.
            MyRxCall will only use Your personal information in accordance with its Privacy Policy.
          </Paragraph>

          <H4>4. MyRxCall Account and Membership Terms.</H4>
          <Paragraph>
            To access the Services, You must create a MyRxCall account (an “Account”). All required
            registration information that You provide to create an Account must be current, complete
            and accurate, and kept up to date on a prompt, timely basis. If required, You must also
            choose a username and password for such Account. It is Your sole responsibility to keep
            Your Account number, username, password, and other sensitive information confidential.
            If You become aware of any unauthorized use of Your Account or any other breach of
            security, You shall notify MyRxCall immediately. You acknowledge and agree that You are
            fully responsible for all activity, liability, and damage resulting from Your failure to
            securely maintain Your Account information and password.
          </Paragraph>
          <Paragraph>
            Once You create an Account, You can input Your medication information and set reminder
            alerts via phone call, text message, and/or e-mail. For more information on creating an
            Account, please see our FAQs [insert link to page describing account registration
            process]. You may edit the information in Your Account, including Your medical
            information, at any time by logging in to Your Account on the Website.
          </Paragraph>
          <Paragraph>
            By creating an account and using MyRxcall, you are expressly consenting to receive
            automated informational communications via telephone calls, text messages, and/or
            e-mails by or on behalf of MyRxcall to any phone number, mobile device, or e-mail
            address associated with Your Account or otherwise provided to MyRxcall, containing
            medical information that You provide in Your Account profile. For more information on
            how You may receive communications from MyRxCall, please see our Privacy Policy [insert
            link]. You are solely responsible for having access to and maintaining a properly
            working telephone, cell phone, computer, or other electronic device to receive
            MyRxCall’s calls, text messages, and e-mails, and for any applicable fees resulting from
            carriers of the access services for those devices, including without limitation Your
            cell phone provider.
          </Paragraph>

          <H4>5. Membership Fees.</H4>
          <Paragraph>
            MyRxCall reserves the right at any time to charge fees for access to the Services. In no
            event, however, will You be charged for access to the Services unless MyRxCall obtains
            Your prior agreement to pay such charges. All new fees, if any, will be posted
            prominently on the Website. You shall pay all fees and charges incurred through Your
            account at the rates in effect for the billing period in which such fees and charges are
            incurred. All fees and charges shall be billed to and paid for by You. You shall pay all
            applicable taxes relating to use of the Services through Your account.
          </Paragraph>
          <Paragraph>
            You must maintain a valid payment method on file with MyRxCall. Payment is accepted via
            VISA, MasterCard, Discover, and PayPal. You agree that MyRxCall may charge Your credit
            or debit card, or withdraw amounts from Your designated account at Your depository
            institution, or charge any other payment method that you have on file with the fees due
            hereunder, along with any applicable sales and use taxes.
          </Paragraph>
          <Paragraph>
            MyRxCall may, in its sole and absolute discretion, waive certain fees under limited
            circumstances based on need. MyRxCall reserves the right to end any such fee waivers at
            any time and for any reason, with or without notice.
          </Paragraph>
          <Paragraph>
            Your membership plan may start with a free trial or an introductory period during.
            MyRxCall will not give You a free trial if You previously received a free trial. If
            granted to You, the free trial period lasts for the time period specified during Your
            registration process for the Services. Please note that You will be charged for Your
            membership plan at the end of the free trial period, unless You cancel Your subscription
            prior to the end of the free trial period.
          </Paragraph>
          <Paragraph>
            For information on membership plans and associated fees, please see our{' '}
            <Link to="/pricing">Plans and Pricing</Link> page.
          </Paragraph>

          <H4>6. Membership Renewal and Cancellation.</H4>
          <Paragraph>
            Your Account membership will automatically be renewed on a monthly basis, on the date of
            Your Account activation (the “Renewal Date”). For example, if You create an Account and
            select a membership plan on the 10th of the month, your membership will automatically be
            renewed on the 10th of every month. You may cancel Your membership at any time through
            Your Account or by emailing support@myrxcall.com. If You wish to cancel Your membership
            and not be billed a fee for another month, You must do so before the Renewal Date. If a
            cancellation request is received after the Anniversary Date, the cancellation will
            become effective the following month.
          </Paragraph>

          <H4>7. Restriction or Termination of Use.</H4>
          <Paragraph>
            MyRxCall may terminate or restrict Your access to all or any part of the Website,
            Services, and/or Account at any time, with or without cause, with or without prior
            notice to You, effective immediately. In the event that MyRxCall terminates such access,
            it shall provide You with a prorated refund for any paid but unused portions of Your
            membership fee, unless Your Account is terminated for a violation of the Terms of
            Service. If You violate any of the Terms of Service, Your authorization to access or use
            the Website and Services automatically terminates. All provisions of this Agreement
            which by their nature should survive termination shall survive termination, including,
            without limitation, intellectual property ownership provisions, warranty disclaimers,
            indemnity, and limitations of liability.
          </Paragraph>

          <H4>8. Third Party Vendors.</H4>
          <Paragraph>
            MyRxCall enlists the support of third-party vendors to provide and maintain the
            Services. By using the Website and Services, You agree that MyRxCall may share any
            information MyRxCall receives with third-party vendors and service providers retained to
            help provide or improve the Website or Services. By using the Website or Services, You
            may also be subject to an agreement with the third-party vendors or service providers
            and subject to the terms and conditions set forth from time to time by such third-party
            providers. For example, MyRxCall uses [insert third-party’s name] for [insert services
            provided], available at [link]. Acceptance of MyRxCall’s Terms of Service automatically
            constitutes acceptance of [third-party’s name]’s terms of service [insert link], which
            are incorporated herein by reference.
          </Paragraph>
          <H4>9. No Medical Advice and No Guarantees.</H4>
          <Paragraph>
            MyRxCall’s Website and Services contain general information related to medication
            reminder only. MyRxCall does not and will not provide medical advice of any kind, and
            the Website and/or Services, are not intended to offer professional advice, diagnosis,
            or treatment nor are they intended to substitute any advice provided by Your health care
            provider. MyRxcall makes no representations or warranties whatsoever with respect to the
            Services. MyRxCall is not responsible for any missed medication dosages, nor can
            MyRxCall guarantee that You will actually take the correct medication or dosage at the
            correct time. You are solely responsible for correctly adding, updating, and removing
            Your medication information from Your Account profile. YOU ASSUME FULL RISK AND
            RESPONSIBILITY FOR ANY ACTIONS YOU TAKE BASED ON THE CONTENT AND/OR NOTIFICATIONS
            PROVIDED BY THE SERVICES, INCLUDING WITHOUT LIMITATION, CONDITION, DOSE, MEDICINE, AND
            TIME(S) FOR WHEN YOU TAKE YOUR MEDICATION.
          </Paragraph>

          <H4>10. Access to Services and Force Majecture.</H4>
          <Paragraph>
            MyRxCall makes no guarantee for continuous, uninterrupted access to the Website or
            Services, and operation of the Website or Services may be interfered with by numerous
            factors outside MyRxCall’s control. MyRxCall may suspend the Services at any time in
            case of occurrence of an event beyond its control, or as a consequence of an action of a
            third party outside the control of MyRxCall. For the avoidance of doubt, neither You nor
            MyRxCall will be deemed to have any liability whatsoever to the other or to any third
            party for any failure of performance hereunder occasioned by an act of God, force of
            nature, war or warlike activity, insurrection or civil commotion, labor dispute,
            pandemic, transportation delay, governmental regulatory action whether or not with
            proper authority, or any other cause similar or dissimilar to the foregoing and beyond
            its reasonable control, provided the affected party gives prompt notice to the other
            party.
          </Paragraph>

          <H4>11. Disclaimers.</H4>
          <Paragraph>
            MyRxCall and its affiliates, officers, directors, shareholders, members, employees,
            agents, assigns, suppliers and third party partners hereby disclaim all warranties of
            any kind, express or implied, including, without limitation, the warranties of
            merchantability, fitness for a particular purpose, and non-infringement. You understand
            that receive information from, or otherwise obtain the Services through MyRxCall at Your
            own discretion and risk.
          </Paragraph>

          <H4>12. Intellectual Property.</H4>
          <Paragraph>
            All content on the Website and Services, including service names, company names, tag
            lines, images and logos, whether or not appearing in large print or with the trademark
            symbol, are owned by or licensed to MyRxCall, its affiliates, related companies or
            licensors, unless otherwise noted. The use or misuse of these or any other materials,
            except as permitted herein, is expressly prohibited and may be in violation of copyright
            law, trademark law, the law of slander and libel, the law of privacy and publicity, and
            communications regulations and statutes. Please be advised that MyRxCall actively and
            aggressively enforces its intellectual property rights to the fullest extent of the law.
          </Paragraph>

          <H4>13. Indemnefecation.</H4>
          <Paragraph>
            You agree to indemnify, defend and hold harmless MyRxCall, its affiliates, officers,
            directors, shareholders, members, employees, agents, assigns, and third party partners
            from and against all claims, losses, and expenses, including reasonable attorneys’ fees,
            arising out of Your use of the Website or Services or as a result of any violation by
            You of these Terms of Service or otherwise, including without limitation, missed doses
            of prescription or non-prescription pills, liquids, or any other medications, death,
            hospitalization, and emergency room visits.
          </Paragraph>

          <H4>14. Limitation of Liability.</H4>
          <Paragraph>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES SHALL MYRXCALL, OR ITS
            LICENSORS, AFFILIATES, OFFICERS, DIRECTORS, SHAREHOLDERS, MEMBERS, EMPLOYEES, AGENTS, OR
            ASSIGNS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR
            CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR INABILITY TO USE, THIS WEBSITE OR
            THE SERVICES. THIS LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON
            CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF MYRXCALL HAS
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. BECAUSE SOME JURISDICTIONS DO NOT ALLOW
            THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, MYRXCALL’S LIABILITY
            IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW.
          </Paragraph>
          <Paragraph>
            IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICES OR WITH THESE TERMS OF SERVICE,
            YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICES.
          </Paragraph>

          <H4>15. Disputes.</H4>
          <Paragraph>
            PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND HOW CLAIMS THAT YOU AND
            MYRXCALL HAVE AGAINST EACH OTHER ARE RESOLVED.
          </Paragraph>
          <Paragraph>
            This Section is deemed to be a “written agreement to arbitrate” pursuant to the Federal
            Arbitration Act. You and MyRxCall agree that this Section satisfies the “writing”
            requirement of the Federal Arbitration Act. This Section can only be amended by mutual
            agreement.
          </Paragraph>
          <Paragraph>
            MyRxCall will make every reasonably effort to informally resolve any complaint, dispute,
            or disagreement a User may have with MyRxCall. Should those efforts fail, You agree that
            any complaint, dispute, or disagreement You may have against MyRxCall, and any claim
            that MyRxCall may have against You, arising out of, relating to, or connected in any way
            with these Terms of Service, our Privacy Policy, or any use or interaction with the
            Website or Services, shall be resolved exclusively by final and binding arbitration
            (“Arbitration”) administered by JAMS or its successor (“JAMS”) and conducted in
            accordance with the JAMS Streamlined Arbitration Rules And Procedures in effect at the
            time the Arbitration is initiated or, if the amount in controversy exceeds $100,000, in
            accordance with the JAMS Comprehensive Arbitration Rules And Procedures then in effect
            (respectively, the “Applicable Rules”). The Applicable Rules can be found at
            www.jamsadr.com. If JAMS is no longer in existence, the Arbitration shall be
            administered by the American Arbitration Association or its successor (the “AAA”)
            instead, and conducted in accordance with the AAA Commercial Arbitration Rules in effect
            at that time (which shall be the “Applicable Rules” in such circumstances). If JAMS (or,
            if applicable, AAA) at the time the Arbitration is filed has Minimum Standards of
            Procedural Fairness for Consumer Arbitrations in effect which would be applicable to the
            matter in dispute, MyRxCall agrees to provide the benefit of such Minimum Standards to
            You to the extent they are more favorable than the comparable arbitration provisions set
            forth in this Section, provided, however, that in no event may such Minimum Standards
            contravene or restrict the application of subpart (e) or (i) below. For the avoidance of
            doubt, pursuant to these Terms of Service You will not have the right to pursue a claim
            in court or have a jury decide the claim, and You will not have the right to bring or
            participate in any class action or similar proceeding in court or in arbitration. By
            using or interacting with the Services, You agree to binding Arbitration as provided
            herein. Furthermore, this Section shall not prevent any party from seeking provisional
            remedies in aid of arbitration from a court of appropriate jurisdiction. You further
            agree that:
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                <strong>1.</strong>
                <br />
                Single Arbitrator. The Arbitration shall be conducted before a single arbitrator
                selected in accordance with the Applicable Rules or by mutual agreement between you
                and MyRxCall (the “Arbitrator”);
              </li>
              <li>
                <strong>2.</strong>
                <br />
                Arbitrator Will Interpret This Agreement. The Arbitrator, and not any federal, state
                or local court or agency, shall have the exclusive authority to resolve any dispute
                arising under or relating to the validity, interpretation, applicability,
                enforceability or formation of these Terms of Service and/or these arbitration
                provisions in this Section hereof, including but not limited to any claim that all
                or any part of these Terms of Service is void or voidable;
              </li>
              <li>
                <strong>3.</strong>
                <br />
                Location of Arbitration. The Arbitration shall be held either: (i) at a location
                determined by JAMS (or, if applicable, AAA) pursuant to the Applicable Rules
                (provided that such location is reasonably convenient for you and does not require
                travel in excess of 100 miles from your home or place of business); or (ii) at such
                other location as may be mutually agreed upon by you and MyRxCall; or (iii) at your
                election, if the only claims in the Arbitration are asserted by you and are for less
                than $10,000 in aggregate, by telephone or by written submission.
              </li>
              <li>
                <strong>4.</strong>
                <br />
                Governing Law. The Arbitrator (i) shall apply internal laws of the State of
                California consistent with the Federal Arbitration Act and applicable statutes of
                limitations, or, to the extent (if any) that federal law prevails, shall apply the
                law of the U.S., irrespective of any conflict of law principles; (ii) shall
                entertain any motion to dismiss, motion to strike, motion for judgment on the
                pleadings, motion for complete or partial summary judgment, motion for summary
                adjudication, or any other dispositive motion consistent with California or federal
                rules of procedure, as applicable; (iii) shall honor claims of privilege recognized
                at law; and (iv) shall have authority to award any form of legal or equitable
                relief;
              </li>
              <li>
                <strong>5.</strong>
                <br />
                No Class Relief. The Arbitration can resolve only your and/or MyRxCall’s individual
                claims, and the Arbitrator shall have no authority to entertain or arbitrate any
                claims on a class or representative basis, or to consolidate or join the claims of
                other persons or parties who may be similarly situated;
              </li>
              <li>
                <strong>1.</strong>
                <br />
                Written Award. The Arbitrator shall issue a written award supported by a statement
                of decision setting forth the Arbitrator’s complete determination of the dispute and
                the factual findings and legal conclusions relevant to it (an “Award”). Judgment
                upon the Award may be entered by any court having jurisdiction thereof or having
                jurisdiction over the relevant party or its assets;
              </li>
              <li>
                <strong>6.</strong>
                <br />
                Interpretation and Enforcement of Arbitration Clause. With the exception of subpart
                (e) above, if any part of this arbitration provision is deemed to be invalid,
                unenforceable or illegal, or otherwise conflicts with the Applicable Rules, then the
                balance of this arbitration provision shall remain in effect and shall be construed
                in accordance with its terms as if the invalid, unenforceable, illegal or
                conflicting provision were not contained herein. If, however, subpart (e) is found
                to be invalid, unenforceable or illegal, then the entirety of this arbitration
                provision shall be null and void, and neither you nor MyRxCall shall be entitled to
                arbitrate their dispute, and must instead bring any claims in a court of competent
                jurisdiction.
              </li>
              <li>
                <strong>7.</strong>
                <br />
                Modification of Arbitration Clause With Notice. MyRxCall may modify these
                arbitration provisions, but such modifications shall only become effective thirty
                (30) days after MyRxCall has given notice of such modifications and only on a
                prospective basis for claims arising from MyRxCall Transactions and Relationships
                occurring after the effective date of such notification.
              </li>
              <li>
                <strong>8.</strong>
                <br />
                Small Claims Matters are Excluded. No Class Relief or Joinder of Claims.
                Notwithstanding the foregoing arbitration provisions, at your option, you may bring
                any claim you have against MyRxCall in your local small claims court within the
                U.S., if your claim is within such court’s jurisdictional limit; provided that such
                court does not have the authority to entertain any claims on a class or
                representative basis, or to consolidate or join the claims of other persons or
                parties who may be similarly situated in such proceeding.
              </li>
              <li>
                <strong>9.</strong>
                <br />
                Government Regulators and Ombudsman. Nothing in this section excludes your rights to
                seek resolution of disputes through the applicable government regulators, ombudsmen
                or their associated dispute resolution processes at law in any jurisdiction in which
                the Website or Services are available.
              </li>
            </ul>
          </Paragraph>

          <H4>16. Entire Agreement.</H4>
          <Paragraph>
            These Terms of Service constitute the entire agreement between MyRxCall and You
            concerning the subject matter hereof. No other agreements, promises, representations, or
            understandings shall be binding upon MyRxCall with respect to the Website, Services or
            Privacy Policy, unless contained in these Terms of Service, separately agreed to in
            writing and signed by an authorized representative of MyRxCall, or MyRxCall posts a
            revised version of these Terms of Service.
          </Paragraph>

          <H4>17. Assignment and Third Parties.</H4>
          <Paragraph>
            MyRxCall may assign its rights under this Agreement without condition. You may assign
            Your rights under this Agreement only with MyRxCall’s prior written consent. This
            Agreement will be binding upon and will inure to the benefit of the parties, their
            successors, and permitted assigns. No term or provision of the Terms of Service is meant
            or intended to benefit any third party.
          </Paragraph>

          <H4>18. Severability and Waiver.</H4>
          <Paragraph>
            If any part of the Terms of Service is held invalid or unenforceable, such provision
            shall be modified to reflect the parties’ intention, and the remaining provisions will
            remain in full force and effect. A waiver by either party of any term or condition of
            this Agreement or any breach thereof, in any one instance, will not waive such term or
            condition or any subsequent breach thereof.
          </Paragraph>

          <H4>19. Void Where Prohibited.</H4>
          <Paragraph>
            This Website, the Services, and any related contents are intended to comply with the
            laws and regulations in the United States. Although the information on this Website is
            accessible to users outside of the United States, the information on the Website
            pertaining to MyRxCall’s Services is intended for use only by residents of the United
            States. Other countries may have laws, regulatory requirements and practices that differ
            from those in the United States, and accessing this Website and its contents or
            distributing this Website’s contents to territories where the content is illegal is
            strictly prohibited. You agree to comply with all applicable laws regarding the
            transmission of technical data exported from the United States or the country in which
            You reside. If You access this Website from other locations, You do so on Your own
            initiative and are responsible for compliance with local laws. MyRxCall reserves the
            right to limit provision of Services to any person, geographic region or jurisdiction.
            Any offer for Services made on this Site is void where prohibited.
          </Paragraph>

          <H4>20. Contact Information.</H4>
          <Paragraph>
            Unless provided elsewhere herein, You may direct any question, notice, demand, or
            communication regarding the Terms of Service, Privacy Policy, or any other rules or
            requirements incorporated herein, we encourage You to contact us by calling{' '}
            <a href="tel:8004606507">(800) 460-6507</a>, e-mailing{' '}
            <a href="mailto:support@myrxcall.com">support@myrxcall.com</a>, or by sending a letter
            to us at
          </Paragraph>
          <Paragraph>
            MyRxCall, Inc.
            <br />
            2700 Neilson Way, Unit 124 Santa Monica, CA 90405
          </Paragraph>

          <H4>21. Civil Code Section 1789.3 Notice.</H4>
          <Paragraph>
            Under California Civil Code Section 1789.3, California users of an electronic commercial
            service receive the following consumer rights notice: The Complaint Assistance Unit of
            the Division of Consumer Services of the California Department of Consumer Affairs may
            be contacted in writing at 1625 North Market Blvd., Suite N112, Sacramento, CA 95834, or
            by telephone at <a href="tel:8009525120">(800) 952-5210</a>.
          </Paragraph>

          <H4>22. Changes to the Terms of Service.</H4>
          <Paragraph>
            MyRxCall reserves the right, in its sole and absolute discretion, to change, modify, add
            to, supplement, replace, or delete any of the terms and conditions of these Terms of
            Service. Although MyRxCall may attempt to notify You when material changes are made to
            these Terms of Service, YOU ARE RESPONSIBLE FOR PERIODICALLY REVIEWING THIS TERMS OF
            SERVICE FOR ANY CHANGES. IF YOU CANNOT COMPLY WITH CHANGES TO THESE TERMS OF SERVICE, OR
            SUCH CHANGES ARE UNACCEPTABLE TO YOU, YOU MUST TERMINATE, AND IMMEDIATELY STOP USING THE
            SERVICES. Your continued access or use of the Website or Services constitutes Your
            acceptance of any changes. Please regularly check the Terms of Service to view the
            then-current terms.
          </Paragraph>
          <Paragraph>
            MyRxCall may also, in the future, offer new services and/or features through the
            Website. Such new features and/or services shall be subject to the terms and conditions
            of this Agreement.
          </Paragraph>
        </Container>
      </LegalCopy>
      <CtaSectionSmall headline="The most reliable way to manage medications" cta="About pricing" />
    </main>
  )
}

export default TermsOfService
