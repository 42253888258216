import React, { useState } from 'react'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'
import { Flex, Box, Link as ExternalLink } from '@rebass/emotion'
import { CheckCircle } from 'react-feather'
import { FormGroup } from '@material-ui/core'

import { Theme, Mq } from '../styles/settings'
import PhoneNumber from '../atoms/phoneNumber'
import { Animation } from '../atoms/animation'
import { TextInput } from '../atoms/input'
import { Dropdown } from '../atoms/dropdown'
import { H5, H6, Paragraph } from '../atoms/text'
import { SubmitButton } from '../atoms/button'

import { timeZoneOptions } from '../constants'

const AccountDetailsFormContainer = styled.div`
  width: 100%;

  p {
    margin-bottom: 2rem;
  }
`

const FormContainer = styled.div`
  align-items: center;
  background: #fff;
  padding: 40px 32px;

  H6 {
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;
    font-weight: 600;

    :hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
`

const CustomHeader = styled.div`
  H5 {
    display: flex;
    align-items: center;
    margin: 0;
  }
`

const SubmitBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  svg {
    margin-right: 1.5rem;
    margin-top: 8px;
  }
  ${Mq.md} {
    margin-top: 2rem;
  }
`

const SuccessAlert = styled.div``

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const MemberForm = (props) => {
  const { user, handleSubmit } = props

  const [edited, setEdited] = useState(false)

  const handleFormChange = (e) => {
    setEdited(true)
    const { name, value } = e.target
    props.setUser({
      ...props.user,
      patient: {
        ...props.user.patient,
        [name]: value,
      },
    })
  }

  if (user.patient) {
    return (
      <AccountDetailsFormContainer>
        <CustomHeader>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
          >
            <H5>Message Subscriber Details</H5>
            <Paragraph>Update the details for the receiver of messages below.</Paragraph>
          </Animation>
        </CustomHeader>
        <FormContainer>
          <FormGroup>
            <Animation
              initial="fadeIn"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
            >
              <Flex width="auto" mx={-2}>
                <Box width={1} pl={2} pr={2}>
                  <H5 className="dark">Their first name:</H5>
                  <TextInput
                    value={user.patient.first_name}
                    onChange={(e) => {
                      handleFormChange(e)
                    }}
                    name="first_name"
                    dark={true}
                    placeholder=""
                    description="Member First Name"
                  />
                </Box>
                <Box width={1} pl={2} pr={2}>
                  <H5 className="dark">Their last name:</H5>
                  <TextInput
                    dark={true}
                    value={user.patient.last_name}
                    name="last_name"
                    onChange={(e) => {
                      handleFormChange(e)
                    }}
                    placeholder=""
                    description="Member Last Name"
                    type="phone"
                  />
                </Box>
              </Flex>
            </Animation>
            <Animation
              initial="fadeIn"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
            >
              <Flex width="auto" mx={-2}>
                <Box width={1} pl={2} pr={2}>
                  <H5 className="dark">Their email:</H5>
                  <TextInput
                    value={user.patient.email}
                    name="email"
                    onChange={(e) => {
                      handleFormChange(e)
                    }}
                    dark={true}
                    placeholder="For email notifications"
                    description="Email input"
                  />
                </Box>
                <Box width={1} pl={2} pr={2}>
                  <Flex justifyContent="space-between">
                    <H5 className="dark">Their phone number:</H5>
                    <ExternalLink href="mailto:support@myrxcall.com?subject=I need to reset my phone number.&body=Could somebody please assist me in updating the reminders phone number for my subscribed member?">
                      <H6>Reset phone</H6>
                    </ExternalLink>
                  </Flex>
                  {/* WIP  –– ICEBOX –– Make this actually update the number and reminders.*/}
                  <PhoneNumber
                    handleChange={(e) => {
                      this.handleChange(e)
                    }}
                    disabled="true"
                    name="phone_number"
                    value={user.patient.phone_number && user.patient.phone_number.substr(2)}
                  />
                </Box>
              </Flex>
            </Animation>
            <Animation
              initial="fadeIn"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
            >
              <Flex width="auto" mx={-2} alignItems="flex-end" mb={3}>
                <Box width={1} pl={2} pr={2}>
                  <H5 className="dark">Subscriber time zone:</H5>
                  <Dropdown
                    dark={true}
                    options={timeZoneOptions}
                    placeholder="Select timezone"
                    description="Subscriber time zone"
                    value={timeZoneOptions.find((tz) => tz.value === user.patient.timezone)}
                    onChange={(e) => {
                      handleFormChange({ target: { name: 'timezone', value: e.value } })
                    }}
                  />
                </Box>
              </Flex>
            </Animation>
            <Animation
              initial="fadeIn"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
            >
              <Flex width="100%" mx={-2} alignItems="flex-end">
                <SubmitBox
                  display={['flex', 'flex', 'inherit']}
                  justifyContent={['center', 'center', 'flex-end']}
                  alignItems="center"
                  width={1}
                >
                  <SuccessAlert className={edited}>
                    {/* WIP --- ICEBOX --- Give this a unique state separate from the account details form. */}
                    {props.dataStore.success ? (
                      <Animation
                        initial="fadeIn"
                        animate="visible"
                        exit={{ opacity: 0 }}
                        variants={motionVariants}
                        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
                      >
                        <CheckCircle color={Theme.colors.success} size={32} />
                      </Animation>
                    ) : null}
                  </SuccessAlert>
                  <SubmitButton
                    title="Update"
                    type="submit"
                    onClick={(e) => {
                      handleSubmit(e)
                    }}
                    variant={!edited && 'disabled'}
                    disabled={!edited}
                  />
                </SubmitBox>
              </Flex>
            </Animation>
          </FormGroup>
        </FormContainer>
      </AccountDetailsFormContainer>
    )
  } else {
    return <></>
  }
}

export default inject('dataStore')(observer(MemberForm))
