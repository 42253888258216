import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { X, Play } from 'react-feather'
import ReactModal from 'react-modal'
import { Hidden } from '@material-ui/core'

import { Theme, Mq } from '../styles/settings'
import { H3, H4, Paragraph } from '../atoms/text'
import Animation from '../atoms/animation'
import howItWorks from '../assets/images/howItWorks.png'
import { Container } from '../atoms/container'
import { Button } from '../atoms/button'
import Video from '../atoms/video'

const VideoContainer = styled.div`
  background-color: ${(props) => props.theme.colors.lightBlue};
  background-size: 80%;
  padding: 64px 0;
  text-align: center;
  margin-top: 80px;
`

const ImageContainer = styled.div`
  display: none;
  ${Mq.md} {
    display: flex;
    width: 100%;
    align-items: bottom;
    padding-right: 1rem;
    justify-content: flex-end;
    position: relative;

    img {
      z-index: 2;
      margin-bottom: -140px;
    }
  }
`
const StyledCta = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 1rem;
  :hover {
    color: ${(props) => props.theme.colors.brandPrimary};
  }

  H4 {
    margin: 0 0 -2px 1rem;
    color: ${(props) => props.theme.colors.brandSecondary};
    cursor: pointer;
  }
  ${Mq.md} {
    width: max-content;
    padding: 0;
  }
`

const StyledPlayButton = styled.div`
  display: flex;
  align-items: center;
  border-width: 3px;
  border-color: ${(props) => props.borderColor};
  border-radius: 40px;
  border-style: solid;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  position: relative;

  :hover {
    border-color: ${(props) => props.theme.colors.brandSecondary};
  }

  svg {
    fill: ${(props) => props.theme.colors.brandSecondary};
    stroke: ${(props) => props.theme.colors.brandSecondary};
    position: absolute;
    left: 9px;
    top: 8px;
  }
`

const ImageBackground = styled.div`
  width: 480px;
  height: 320px;
  border: 0px;
  position: absolute;
  border-radius: 0px;
  background: ${(props) => props.theme.colors.brandTertiary};
  background-size: cover;
  border-radius: 8px;
  margin-right: 40px;
  margin-top: -40px;
  margin-left: -48px;
  z-index: 1;

  @media screen and (max-width: 1200px) and (min-width: 961px) {
    margin-right: -120px;
    margin-top: -120px;
    width: 420;
  }
`

const CloseModal = styled.div`
  position: absolute;
  top: -40px;
  right: -4px;

  svg {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    stroke: ${(props) => props.theme.colors.brandPrimary};
  }
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Theme.colors.black,
    border: '0px',
    padding: '0px',
    borderRadius: '0px',
    width: '90%',
    maxWidth: '970px',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'RGBA(54,59,61,0.8)',
    backdropFilter: 'blur(4px)',
    width: '100vw',
    zIndex: '9999',
  },
}

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  fadeInStart: {
    opacity: 0,
  },
  fadeInFinish: {
    opacity: 1,
  },
}

class VideoSection extends React.Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
      hover: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleToggleHover = this.handleToggleHover.bind(this)
    this.handleToggleLeave = this.handleToggleLeave.bind(this)
  }

  handleOpenModal() {
    this.setState({ showModal: true })
  }

  handleCloseModal() {
    this.setState({ showModal: false })
  }

  handleToggleHover(e) {
    this.setState({ hover: true })
  }

  handleToggleLeave() {
    this.setState({ hover: false })
  }

  render(props) {
    return (
      <Container>
        <VideoContainer>
          <Flex alignItems="center">
            <Box width={1} textAlign="center">
              <StyledCta>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
                >
                  <H3>Never miss a dose again.</H3>
                </Animation>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
                >
                  <Paragraph>
                    Unlimited personalized reminders via phone, text and email.
                    <br />
                    Little to know technology know-how.
                    <br />
                    Safe, easy, and reliable.
                  </Paragraph>
                </Animation>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
                >
                  <Flex my={4} alignItems="center">
                    <StyledPlayButton
                      borderColor={
                        this.state.hover === false
                          ? Theme.colors.brandTertiary
                          : this.state.hover === true
                          ? Theme.colors.brandSecondary
                          : null
                      }
                      onClick={this.handleOpenModal}
                    >
                      <Play size={18} stroke="#ff0000" />
                    </StyledPlayButton>
                    <H4
                      onMouseEnter={(e) => this.handleToggleHover(e)}
                      onMouseLeave={(e) => this.handleToggleLeave(e)}
                      onClick={this.handleOpenModal}
                      name="how-it-works"
                      ariaLabel="How it works"
                      tabIndex={0}
                    >
                      Watch the video
                    </H4>
                  </Flex>
                </Animation>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 1 }}
                >
                  <Link to="/get-started">
                    <Button title="Try 7 days free" />
                  </Link>
                </Animation>
              </StyledCta>
            </Box>
            <Hidden smDown>
              <Box width={1}>
                <Animation
                  initial="fadeInStart"
                  animate="fadeInFinish"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 1, delay: 1 }}
                >
                  <ImageContainer>
                    <img src={howItWorks} alt="How it works" />
                    <ImageBackground />
                  </ImageContainer>
                </Animation>
              </Box>
            </Hidden>
          </Flex>
        </VideoContainer>
        <ReactModal
          style={modalStyles}
          isOpen={this.state.showModal}
          contentLabel="Edit Prescription Modal"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModal>
            <X onClick={this.handleCloseModal} color="white" />
          </CloseModal>
          <Video />
        </ReactModal>
      </Container>
    )
  }
}

export default VideoSection
