import React from 'react'
import styled from '@emotion/styled'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import MetaTags from 'react-meta-tags'
import { Link } from 'react-router-dom'

import { Mq } from '../styles/settings'
import { Animation } from '../atoms/animation'
import { SmallContainer } from '../atoms/container'
import SignInForm from '../molecules/signInForm'
import { H1, H4 } from '../atoms/text'

export const CustomContainer = styled.main`
  padding: 7rem 0;

  ${Mq.md} {
    padding: 10rem 0;
  }
`

export const CustomHeader = styled.main`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  H4 {
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: 0.2s ease-in-out;

    :hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`

export const CustomBox = styled.main`
  padding: 40px 1rem;
  margin-top: 20px;
  background-color: ${(props) => props.theme.colors.lightBlue};

  ${Mq.md} {
    padding: 80px;
  }
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const SignIn = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Sign in</title>
        <meta
          name="description"
          content="Introducing MyRxCall - the most reliable way to manage medications. Receieve personalized medication reminders via phone call, SMS, or e-mail."
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription reminders for you - Safe, easy, & low cost."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallContainer>
        <CustomContainer>
          <CustomHeader>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
            >
              <H1>Sign in</H1>
            </Animation>
            <Link to="/create-account">
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0 }}
              >
                <H4>Create an account</H4>
              </Animation>
            </Link>
          </CustomHeader>
          <CustomBox>
            <SignInForm />
          </CustomBox>
        </CustomContainer>
      </SmallContainer>
    </main>
  )
}

export default SignIn
