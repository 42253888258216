import React from 'react'
import styled from '@emotion/styled'
import ReactModal from 'react-modal'
import { Box, Flex } from '@rebass/emotion'
import { Play, X } from 'react-feather'
import { Hidden } from '@material-ui/core'

import { Animation } from '../atoms/animation'
import { Theme, Mq } from '../styles/settings'
import reviews from '../assets/images/reviews.png'
import { Container } from '../atoms/container'
import Video from '../atoms/video'
import { H4, H5, Paragraph } from '../atoms/text'

const LargeReviewContainer = styled.div`
  background: ${(props) => props.theme.colors.lightBlue};
  background-size: 90%;
  padding: 4rem 2rem;
  text-align: left;
  margin-top: 80px;

  p {
    color: ${(props) => props.theme.colors.brandSecondary};
    padding: 32px 0 8px;
    max-width: 400px;
  }

  H5 {
    color: ${(props) => props.theme.colors.brandSecondary};
    margin-top: 1rem;
  }

  button {
    text-align: left;
  }
  ${Mq.md} {
    padding: 80px 0;
  }
`
const ImageContainer = styled.div`
  display: none;

  ${Mq.md} {
    display: flex;
    width: 100%;
    align-items: bottom;
    justify-content: flex-end;
    position: relative;

    img {
      z-index: 2;
      margin-right: -64px;
      margin-top: 24px;
      margin-bottom: -124px;
    }
  }
`
const StyledCta = styled.div`
  display: flex;
  align-items: center;
  :hover {
    color: ${(props) => props.theme.colors.brandPrimary};
  }

  H4 {
    margin: 0 0 0 1rem;
    color: ${(props) => props.theme.colors.brandSecondary};
    cursor: pointer;
  }
`

const StyledPlayButton = styled.div`
  display: flex;
  align-items: center;
  border-width: 3px;
  border-color: ${(props) => props.borderColor};
  border-radius: 40px;
  border-style: solid;
  width: 40px;
  height: 40px;
  margin: 0;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  position: relative;

  :hover {
    border-color: ${(props) => props.theme.colors.brandSecondary};
  }

  svg {
    fill: ${(props) => props.theme.colors.brandSecondary};
    stroke: ${(props) => props.theme.colors.brandSecondary};
    position: absolute;
    left: 9px;
    top: 8px;
  }
`

const ImageBackground = styled.div`
  width: 614px;
  height: 276px;
  border: 0px;
  position: absolute;
  right: -60px;
  background: ${(props) => props.theme.colors.brandTertiary};
  background-size: cover;
  border-radius: 8px 8px 0px 0px;
  margin-right: ${(props) => props.margin};
  z-index: 1;
`

const CloseModal = styled.div`
  position: absolute;
  top: -40px;
  right: -4px;

  svg {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    stroke: ${(props) => props.theme.colors.brandPrimary};
  }
`

const StyledBox = styled(Box)`
  padding-left: 0px;
  ${Mq.md} {
    padding-left: 64px;
  }
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Theme.colors.black,
    border: '0px',
    padding: '0px',
    borderRadius: '0px',
    width: '90%',
    maxWidth: '970px',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'RGBA(54,59,61,0.8)',
    backdropFilter: 'blur(4px)',
    width: '100vw',
    zIndex: '9999',
  },
}

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  fadeInStart: {
    opacity: 0,
  },
  fadeInFinish: {
    opacity: 1,
  },
  slideInStart: {
    opacity: 0,
    x: '120px',
  },
  slideInFinish: {
    opacity: 1,
    x: '0',
  },
}

class LargeReview extends React.Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
      hover: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleToggleHover = this.handleToggleHover.bind(this)
    this.handleToggleLeave = this.handleToggleLeave.bind(this)
  }

  handleOpenModal() {
    this.setState({ showModal: true })
  }

  handleCloseModal() {
    this.setState({ showModal: false })
  }

  handleToggleHover(e) {
    this.setState({ hover: true })
  }

  handleToggleLeave() {
    this.setState({ hover: false })
  }

  render(props) {
    return (
      <Container>
        <LargeReviewContainer>
          {/* <Animation ref={ref} /> */}
          <Flex alignItems="center">
            <StyledBox width={[1]} pl={5}>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
              >
                <StyledCta>
                  <StyledPlayButton
                    borderColor={
                      this.state.hover === false
                        ? Theme.colors.brandTertiary
                        : this.state.hover === true
                        ? Theme.colors.brandSecondary
                        : null
                    }
                    onClick={this.handleOpenModal}
                  >
                    <Play size={18} stroke="#ff0000" />
                  </StyledPlayButton>
                  <H4
                    onMouseEnter={(e) => this.handleToggleHover(e)}
                    onMouseLeave={(e) => this.handleToggleLeave(e)}
                    onClick={this.handleOpenModal}
                    name="how-it-works"
                    ariaLabel="How it works"
                    tabIndex={0}
                  >
                    How it works
                  </H4>
                </StyledCta>
              </Animation>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
              >
                <Paragraph>
                  "I signed my mom up for MyRxCall, I love knowing she’s taken care of when it comes
                  to her medication reminders”
                </Paragraph>
              </Animation>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
              >
                <H5> –{'  '} Herman Stewart</H5>
              </Animation>
            </StyledBox>
            <Hidden smDown>
              <Box width={[1]}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
                >
                  <ImageContainer>
                    <img src={reviews} alt="How It Works" />
                    <ImageBackground />
                  </ImageContainer>
                </Animation>
              </Box>
            </Hidden>
          </Flex>
        </LargeReviewContainer>
        <ReactModal
          style={modalStyles}
          isOpen={this.state.showModal}
          contentLabel="Edit Prescription Modal"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModal>
            <X onClick={this.handleCloseModal} color="white" />
          </CloseModal>
          <Video />
        </ReactModal>
      </Container>
    )
  }
}

export default LargeReview
