import { css } from '@emotion/core'

import { Theme } from '../settings'

export const Base = css`
  html {
    overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  body {
    font-family: 'Campton';
    font-size: 1rem;
    font-weight: ${Theme.fontweight.light};
    line-height: 1.5;
    color: ${Theme.colors.black};
    text-align: left;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3 {
    font-weight: ${Theme.fontweight.light};
    font-family: 'Canela Web';
    margin-bottom: 0.5rem;
  }

  h5,
  h4 {
    margin-bottom: 0.5rem;
    font-weight: ${Theme.fontweight.light};
    margin-top: 0;
    font-family: 'Campton Semibold';
  }

  a {
    color: ${Theme.colors.brandPrimary};
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: ${Theme.colors.brandPrimary};
    }
  }

  ::selection {
    color: #fff;
    background: ${Theme.colors.brandPrimary};
  }
`
