import React from 'react'
import metaImage from '../assets/images/my-rx-call__meta-share.png'
import { CtaSection } from '../molecules/ctaSection'
import LargeHero from '../organisms/largeHero'
import ValuePropRow from '../organisms/valuePropRow'
import { HowItWorks } from '../organisms/howItWorks'
import MetaTags from 'react-meta-tags'

const Home = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>MyRxCall – Prescription reminders for you - Safe, easy, & low cost</title>
        <meta
          name="description"
          content="Introducing MyRxCall - the most reliable way to manage medications. Receive reliable medication reminders via text, e-mail, and phone call for only $9.95/month."
        />
        <meta
          property="og:title"
          content="MyRxCall – Prescription reminders for you - Safe, easy, & low cost."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <LargeHero />
      <ValuePropRow />
      <HowItWorks />
      <CtaSection headline="The most reliable way to manage your daily doses." cta="How much does it cost?" />
    </main>
  )
}

export default Home
