import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { Smile, CheckCircle, Shield } from 'react-feather'
import { Link } from 'react-router-dom'

import Animation from '../atoms/animation'
import { Theme, Mq } from '../styles/settings'
import { Container } from '../atoms/container'
import { H4, Paragraph } from '../atoms/text'

const CustomContainer = styled.main`
  padding: 30px 0 20px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightBlue};
  display: flex;
  z-index: 99;
  align-items: center;
  text-align: center;

  H4 {
    color: ${(props) => props.theme.colors.brandSecondary};
    margin: 0;
    line-height: 20px;
    transition: 0.2s ease-in-out;

    :hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  p {
    margin: 0;
  }
  ${Mq.md} {
    text-align: left;
  }
`

const ValuePropContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 10px 0;
  max-width: 400px;
  margin: auto;

  &.last {
    border-right: 0px;
  }

  svg {
    display: none;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    :hover {
      stroke: ${(props) => props.theme.colors.brandPrimary};
    }
  }
  ${Mq.md} {
    justify-content: flex-start;
    border-right: 4px solid ${(props) => props.theme.colors.white};
    margin-right: 40px;

    svg {
      display: block;
    }
  }
`

const IconContainer = styled.div`
  padding-right: 0px;

  ${Mq.md} {
    padding-right: 20px;
  }
`

const TextContainer = styled.div`
  align-items: left;
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '20px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

class ValuePropRow extends React.Component {
  constructor() {
    super()
    this.state = {
      hover: false,
    }

    this.handleToggleHover = this.handleToggleHover.bind(this)
    this.handleToggleLeave = this.handleToggleLeave.bind(this)
  }
  handleToggleHover(e) {
    this.setState({ hover: true })
  }

  handleToggleLeave() {
    this.setState({ hover: false })
  }

  render(props) {
    return (
      <CustomContainer>
        <Container>
          <Flex
            width={1}
            justifyContent="space-between"
            flexDirection={['column', 'column', 'row']}
          >
            <Box width={1}>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.4 }}
              >
                <ValuePropContainer>
                  <IconContainer>
                    <Link to="/get-started">
                      <Shield
                        size={50}
                        color={Theme.colors.brandTertiary}
                        title
                        borderColor={
                          this.state.hover === false
                            ? Theme.colors.brandPrimary
                            : this.state.hover === true
                            ? Theme.colors.brandSecondary
                            : null
                        }
                      />
                    </Link>
                  </IconContainer>
                  <TextContainer>
                    <Link to="/get-started">
                      <H4>Safe & secure</H4>
                    </Link>
                    <Paragraph>Your doses are personal. We keep them under lock and key. </Paragraph>
                  </TextContainer>
                </ValuePropContainer>
              </Animation>
            </Box>

            <Box width={1}>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.6 }}
              >
                <ValuePropContainer>
                  <IconContainer>
                    <Link to="/get-started">
                      <CheckCircle size={50} color={Theme.colors.brandTertiary} />
                    </Link>
                  </IconContainer>
                  <TextContainer>
                    <Link to="/get-started">
                      <H4>Easy to use</H4>
                    </Link>
                    <Paragraph>Enter what you take and when. We'll take care of the rest.</Paragraph>
                  </TextContainer>
                </ValuePropContainer>
              </Animation>
            </Box>

            <Box width={1}>
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.8 }}
              >
                <ValuePropContainer className="last">
                  <IconContainer>
                    <Link to="/get-started">
                      <Smile size={50} color={Theme.colors.brandTertiary} />
                    </Link>
                  </IconContainer>
                  <TextContainer>
                    <Link to="/get-started">
                      <H4>Low cost</H4>
                    </Link>
                    <Paragraph>One low monthly fee. No extra charges, no surprises.</Paragraph>
                  </TextContainer>
                </ValuePropContainer>
              </Animation>
            </Box>
          </Flex>
        </Container>
      </CustomContainer>
    )
  }
}

export default ValuePropRow
