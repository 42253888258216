import React from 'react'
import styled from '@emotion/styled'
import { X } from 'react-feather'
import ReactModal from 'react-modal'
import { inject, observer } from 'mobx-react'

import { Mq } from '../styles/settings'
import { Animation } from '../atoms/animation'
import PauseMembership from '../molecules/pauseMembership'
import CancelMembership from '../molecules/cancelMembership'
import { H2, H5, Paragraph } from '../atoms/text'
import { Button } from '../atoms/button'

const UpdateMembershipContainer = styled.div`
  margin-top: 4rem;
`
const FormContainer = styled.div`
  background: #fff;
  padding: 60px 0;
  text-align: center;

  p {
    max-width: 340px;
    margin: auto;
    padding: 8px 1rem 3rem;
    font-size: 20px;
    ${Mq.md} {
      padding: 8px 0px 3rem;
    }

    &.link {
      color: ${(props) => props.theme.colors.brandSecondary};
      transition: 0.2s ease-in-out;
      padding: 0;
      font-size: 1rem;
      cursor: pointer;

      :hover {
        color: ${(props) => props.theme.colors.brandPrimary};
      }
    }
  }

  H2 {
    text-align: center;
    font-size: 20px;
    ${Mq.md} {
      font-size: 1.6rem;
    }
  }

  Button {
    margin-bottom: 1rem;
  }
`

const CloseModal = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    stroke: ${(props) => props.theme.colors.brandPrimary};
  }
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#F3F6F9',
    padding: '80px 16px',
    border: '0px',
    overflow: 'auto',
    borderRadius: '0px',
    width: '90%',
    maxWidth: '970px',
    maxHeight: '100vh',
    transition: 'all 500ms ease-in-out',
  },
  overlay: {
    backgroundColor: 'RGBA(54,59,61,0.8)',
    backdropFilter: 'blur(4px)',
    width: '100vw',
    zIndex: '9999',
    transition: 'all 500ms ease-in-out',
  },
}

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  fadeIn: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

class UpdateMembership extends React.Component {
  constructor() {
    super()
    this.state = {
      showCancelModal: false,
    }

    this.handleOpenCancelModal = this.handleOpenCancelModal.bind(this)
    this.handleCloseCancelModal = this.handleCloseCancelModal.bind(this)
  }

  handleOpenCancelModal() {
    this.setState({ showCancelModal: true })
  }

  handleCloseCancelModal() {
    this.setState({ showCancelModal: false })
  }

  render(props) {
    const { uiStateStore } = this.props
    return (
      <>
        <UpdateMembershipContainer>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 1, delay: 0 }}
          >
            <H5>Update membership</H5>
          </Animation>

          <FormContainer>
            <Animation
              initial="fadeIn"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
            >
              <H2>Let us know how we can help</H2>
              <Paragraph>Need some time to re-evaluate things, or just taking a break?</Paragraph>

              <Button
                title="Pause membership"
                variant="dark"
                onClick={() => uiStateStore.setShowMembershipModal(true)}
              />
              <br />

              <Paragraph className="link" onClick={this.handleOpenCancelModal}>
                Cancel membership
              </Paragraph>
            </Animation>
          </FormContainer>
        </UpdateMembershipContainer>

        <ReactModal
          style={modalStyles}
          isOpen={uiStateStore.showMembershipModal || this.state.showCancelModal}
          contentLabel="Edit Prescription Modal"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModal>
            {uiStateStore.showMembershipModal ? (
              <X onClick={() => uiStateStore.setShowMembershipModal(false)} />
            ) : (
              <X onClick={this.handleCloseCancelModal} />
            )}
          </CloseModal>
          {uiStateStore.showMembershipModal ? (
            <PauseMembership closeModal={this.handleCloseModal} />
          ) : this.state.showCancelModal === true ? (
            <CancelMembership />
          ) : null}
        </ReactModal>
      </>
    )
  }
}

export default inject('uiStateStore')(observer(UpdateMembership))
